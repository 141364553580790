import { Component, Input, OnInit, input } from '@angular/core';
import { Order } from '../../../../_models/orders/order';
import { ArchiveOrderKindEnum } from 'app/_models/orders/archive.order.kind.enum';

@Component({
  selector: 'app-easytrade-order',
  templateUrl: './easytrade-order.component.html',
  styleUrls: ['./easytrade-order.component.scss']
})
export class EasytradeOrderComponent implements OnInit {
  @Input() order: Order;
  @Input() archiveOrderKind: ArchiveOrderKindEnum;

  constructor() { }

  ngOnInit(): void {
  }

}
