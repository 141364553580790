<ng-container *ngIf="!response; else responseExists">
  <div class="alert alert-warning" role="alert">
    Geen antwoord ontvangen!
  </div>
</ng-container>

<ng-template #responseExists>

  <div *ngIf="!response.IsOk; else responseOk" class="alert alert-danger" role="alert">
    Er is een fout opgetreden!
    {{response.ErrorMessage}}
  </div>

  <ng-template #responseOk>
    <table class="table table-striped table-sm small">
      <thead>
        <tr>
          <th></th>
          <th>Artikel</th>
          <th class="text-end">Prijs</th>
          <th>Depotnaam</th>
          <th>Depot ID</th>
          <th class="text-end">Voorraad</th>
          <th class="text-center">Extra</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of response.Items | keyvalue">
          <tr>
            <td>
              {{item.key}}<br />
              <span *ngIf="item.value.Properties['SupplierId']" class="text-low">SupplierId: {{item.value.Properties['SupplierId']}}</span>
            </td>
            <td>
              <span class="fw-bold pointer" (click)="searchAction.emit('$'+item.value.ItemNumber)">{{item.value.ItemNumber}}</span><span *ngIf="item.value.ItemGroup" class="ms-1 text-low">({{item.value.ItemGroup}})</span><br />
              <span *ngIf="item.value.Description">{{item.value.Description}}<br /></span>
              <span *ngFor="let property of getProperties(item.value)" class="text-low">{{property}}: {{item.value.Properties[property]}}<br /></span>
              <span *ngIf="item.value.Remark">{{item.value.Remark}}</span>
            </td>
            <td class="text-end">
              <span *ngIf="item.value.PriceInfo.GrossPrice">bruto {{item.value.PriceInfo.GrossPrice | price}}<br /></span>
              <span *ngIf="item.value.PriceInfo.NetPrice">netto {{item.value.PriceInfo.NetPrice | price}}<br /></span>
              <span *ngIf="item.value.PriceInfo.Deposit">statiegeld {{item.value.PriceInfo.Deposit | price}}<br /></span>
              <span *ngIf="item.value.PriceInfo.Discount">korting {{item.value.PriceInfo.Discount | price}}<br /></span>
              <span *ngIf="item.value.PriceInfo.VAT">BTW {{item.value.PriceInfo.VAT | price}}<br /></span>
              <span *ngIf="item.value.PriceInfo.Currency">munteenheid {{item.value.PriceInfo.Currency}}<br /></span>
            </td>
            <td>{{getFirstDepot(item.value).DepotName}}</td>
            <td>{{getFirstDepot(item.value).DepotID}}</td>
            <td class="text-end"><b>
              <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.NotInUse">{{getFirstDepot(item.value).Quantity}}</span>
              <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.InStock">op voorraad</span>
              <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.LowOnStock">weinig voorraad</span>
              <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.OutOfStock">geen voorraad</span>
              <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.SufficientlyInStock">voldoende voorraad</span>
            </b></td>
            <td class="text-end">
              <table class="table table-warning table-bordered table-striped table-sm w-100">
                <tbody>
                <tr *ngIf="isDateOk(getFirstDepot(item.value).LastUpdated)"><td class="sm-td-l">laatste update</td><td class="sm-td">{{getFirstDepot(item.value).LastUpdated | date : 'dd-MM-yyyy HH:mm'}}</td></tr>
                <tr *ngIf="isDateOk(getFirstDepot(item.value).OrderBeforeDateTime)"><td class="sm-td-l">bestel voor</td><td class="sm-td">{{getFirstDepot(item.value).OrderBeforeDateTime | date : 'dd-MM-yyyy HH:mm'}}</td></tr>
                <tr *ngIf="isDateOk(getFirstDepot(item.value).DeliveryDateTime)"><td class="sm-td-l">levertijd</td><td class="sm-td">{{getFirstDepot(item.value).DeliveryDateTime | date : 'dd-MM-yyyy HH:mm'}}</td></tr>
                <tr *ngIf="getFirstDepot(item.value).ShippingMethod"><td class="sm-td-l">verzendwijze</td><td class="sm-td">{{getFirstDepot(item.value).ShippingMethod}}</td></tr>
                <tr *ngIf="getFirstDepot(item.value).ShippingCosts"><td class="sm-td-l">verzendkosten</td><td class="sm-td">{{getFirstDepot(item.value).ShippingCosts | price}}</td></tr>
                <tr *ngIf="getFirstDepot(item.value).Remark"><td class="sm-td-l">opmerking</td><td class="sm-td">{{getFirstDepot(item.value).Remark}}</td></tr>
              </tbody>
              </table>
            </td>
          </tr>
          <tr *ngFor="let depot of getOtherDepots(item.value)">
            <td colspan="3"></td>
            <td>{{depot.DepotName}}</td>
            <td>{{depot.DepotID}}</td>
            <td class="text-end"><b>
              <span *ngIf="depot.Availability==availabilityCode.NotInUse">{{depot.Quantity}}</span>
              <span *ngIf="depot.Availability==availabilityCode.InStock">op voorraad</span>
              <span *ngIf="depot.Availability==availabilityCode.LowOnStock">weinig voorraad</span>
              <span *ngIf="depot.Availability==availabilityCode.OutOfStock">geen voorraad</span>
              <span *ngIf="depot.Availability==availabilityCode.SufficientlyInStock">voldoende voorraad</span>
            </b></td>
            <td class="text-end">
              <table class="table table-warning table-bordered table-striped w-100">
                <tbody>
                <tr *ngIf="isDateOk(depot.LastUpdated)"><td class="sm-td-l">laatste update</td><td class="sm-td">{{depot.LastUpdated | date : 'dd-MM-yyyy HH:mm'}}</td></tr>
                <tr *ngIf="isDateOk(depot.OrderBeforeDateTime)"><td class="sm-td-l">bestel voor</td><td class="sm-td">{{depot.OrderBeforeDateTime | date : 'dd-MM-yyyy HH:mm'}}</td></tr>
                <tr *ngIf="isDateOk(depot.DeliveryDateTime)"><td class="sm-td-l">levertijd</td><td class="sm-td">{{depot.DeliveryDateTime | date : 'dd-MM-yyyy HH:mm'}}</td></tr>
                <tr *ngIf="depot.ShippingMethod"><td class="sm-td-l">verzendwijze</td><td class="sm-td">{{depot.ShippingMethod}}</td></tr>
                <tr *ngIf="depot.ShippingCosts"><td class="sm-td-l">verzendkosten</td><td class="sm-td">{{depot.ShippingCosts | price}}</td></tr>
                <tr *ngIf="depot.Remark"><td class="sm-td-l">opmerking</td><td class="sm-td">{{depot.Remark}}</td></tr>
              </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-template>

</ng-template>