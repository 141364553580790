import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ContextPublicRequest } from './app/_models/common/context.public.request';
import { AppConfig } from './app/_models/common/app.config';
import { ConfigData } from './app/_models/common/config.data';

Date.prototype.toJSON = function () {
  var timezoneOffsetInHours = -(this.getTimezoneOffset() / 60); //UTC minus local time
  var sign = timezoneOffsetInHours >= 0 ? '+' : '-';
  var leadingZero = (Math.abs(timezoneOffsetInHours) < 10) ? '0' : '';

  //It's a bit unfortunate that we need to construct a new Date instance 
  //(we don't want _this_ Date instance to be modified)
  var correctedDate = new Date(this.getFullYear(), this.getMonth(),
    this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds(),
    this.getMilliseconds());
  correctedDate.setHours(this.getHours() + timezoneOffsetInHours);
  var iso = correctedDate.toISOString().replace('Z', '');

  return iso + sign + leadingZero + Math.abs(timezoneOffsetInHours).toString() + ':00';
}

const JSONextended = JSON as any;
JSONextended.useDateParser();

if (environment.production) {
  enableProdMode();
}

fetchConfigData().then(config => {
  platformBrowserDynamic([{ provide: ConfigData, useValue: config }])
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
});

async function getAppConfig(): Promise<AppConfig> {
  if (!environment.production) {
    return new Promise((resolve, reject) => {
      const config = new AppConfig();
      config.path = "/";
      // config.backendApi = 'https://beta.shopportal.autotaal.biz/backend';
      config.backendApi = 'https://localhost:8585';
      resolve(config);
    });
  } else {
    const response = await fetch('app-config.json?t=' + new Date().getTime());
    const config = await response.json();
    return config;
  }
}

async function fetchConfigData(): Promise<ConfigData> {
  const appConfig = await getAppConfig();
  const config = new ConfigData();
  config.path = appConfig.path;
  config.backendApi = appConfig.backendApi;
  config.timeout = 5000;
  if (!environment.production) { config.timeout = 600000; }
  const request = new ContextPublicRequest(document.location.href, 0);
  const response = await fetch(`${config.backendApi}/api/Public/Context`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  const ctx = await response.json();
  config.basePath = ctx.Settings['BasePath'];
  config.ctxPublic = ctx;
  return config;
}
