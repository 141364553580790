import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerModel } from '../../../_models/common/customer.model';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-customer-input',
  templateUrl: './admin-customer-input.component.html',
  styleUrls: ['./admin-customer-input.component.scss']
})
export class AdminCustomerInputComponent implements OnInit {
  @Input() label: string;
  @Input() centerLabel: boolean;
  @Input() model: number;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() modelObject: CustomerModel;
  @Output() modelObjectChange: EventEmitter<CustomerModel> = new EventEmitter<CustomerModel>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() labelCols: number;
  @Input() dividerKind: number;
  @Input() focusInput: EventEmitter<boolean>;
  @Input() noneSelectionLabel: string;
  @Input() noneSelectionModelValue: number;
  @Input() stopSearchingOnBlur: boolean;
  @Input() noneSelectedText: string;
  searching: boolean;
  @Output() onFocusInput = new EventEmitter<boolean>();
  @Output() onBlurInput = new EventEmitter<boolean>();
  
  constructor(
    public adminService: AdminService
  ) { }

  ngOnInit(): void {
    if (!this.focusInput) { this.focusInput = new EventEmitter<boolean>(); }
    if (this.noneSelectionLabel && !this.noneSelectionModelValue) { this.noneSelectionModelValue = 0; }
    if (this.noneSelectionLabel && this.stopSearchingOnBlur === undefined) { this.stopSearchingOnBlur = true; }
    if (this.noneSelectionLabel) { this.searching = this.model !== this.noneSelectionModelValue; }
    if (this.model !== this.noneSelectionModelValue) {
      this.getObjectModel(this.model);
    }
    if (this.model === 0 && !this.noneSelectionLabel) { this.doSearch(); }
  }

  clickModel(event: MouseEvent) {
    this.inputClick.emit(event);
  }

  getObjectModel(model: number) {
    if (model) {
      this.adminService.getCustomer(this.adminService.currentUid.Wholesaler, model)
        .subscribe(result => {
          if (result) {
            if (this.model !== result.CustomerNumber) {
              this.model = result.CustomerNumber;
              this.modelChange.emit(this.model);
            }
            if (!this.modelObject || this.modelObject.CustomerNumber !== result.CustomerNumber) {
              this.modelObject = result;
              this.modelObjectChange.emit(this.modelObject);
            }
          }
          this.searching = false;
        });
    }
  }

  objectChange(value: CustomerModel) {
    if (value.CustomerNumber !== this.model) { this.getObjectModel(value.CustomerNumber); }
  }

  doSearch(): void {
    this.searching = true;
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  clear(event: MouseEvent) {
    if (!this.disabled) {
      this.model = this.noneSelectionModelValue;
      this.modelObject = null;
      this.modelChange.emit(this.model);
      this.modelObjectChange.emit(this.modelObject);
    }
    event.stopPropagation();
  }

  typeaheadBlur(event: any) {
    if (this.stopSearchingOnBlur) {
      setTimeout(() => this.searching = false, 50);
    }
  }

  

}
