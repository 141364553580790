<td>
  <div class="row">
    <div class="col-xl-3">
      <span class="text-body-tertiary pointer"
        (click)="searchAction.emit('#'+item.InternalItemNumber)">{{item.InternalItemNumber}}</span><br />
      <b>{{item.ItemNumber}}</b><span class="ms-1 text-body-tertiary">({{item.ItemGroup}}
        {{item.ItemGroupDescription}})</span><br />
      <span *ngIf="item.Description">{{item.Description}}<br /></span>
      <span *ngFor="let description of item.DescriptionsAdditional" class="text-body-tertiary">{{description}}<br /></span>
      <span *ngFor="let search of item.SearchIndexes" class="text-body-tertiary">zoekindex:
        <i>{{search}}</i><br /></span>
      <span *ngIf="item.EANcode && +item.EANcode > 0" class="text-body-tertiary pointer"
        (click)="searchAction.emit('&'+item.EANcode)">EAN: {{item.EANcode}}<br /></span>
      <button *ngIf="response?.EtKortingLog && response.EtKortingLog[item.InternalItemNumber]" type="button"
        class="btn btn-info btn-xs w-100 mt-3"
        (click)="mainService.showObjectDialog($event, response.EtKortingLog[item.InternalItemNumber])"><i
          class="fas fa-info-circle"></i> Nettoprijs</button>
      <button *ngIf="item.Price.BulkPricing?.length" type="button" class="btn btn-success btn-xs w-100 mt-2"
        [popover]="popTemplateBulkPricing" popoverTitle="Staffels"><i class="fas fa-info-circle"></i>
        Staffelprijzen</button>
      <ng-template #popTemplateBulkPricing>
        <table class="table">
          <tbody>
            <tr *ngFor="let bulkPrice of item.Price.BulkPricing">
              <td class="sm-td-l">{{bulkPrice.FromItemCount}}</td>
              <td class="sm-td">{{bulkPrice.NetPrice | price}}</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
      <button *ngIf="item.Availability" type="button" class="btn btn-warning btn-xs w-100 mt-2"
        (click)="showAvailabilityLog(item)"><i class="fas fa-info-circle"></i> Beschikbaarheid</button>
      <button *ngIf="item.Availability" type="button" class="btn btn-success btn-xs w-100 mt-2 mb-3"
        (click)="mainService.showObjectDialog($event, item)"><i class="far fa-brackets-curly"></i>
        json</button>
      <app-item-info-template [itemInfo]="item" [nettoprijs]="true" [noDebug]="true"></app-item-info-template>
      <button *ngFor="let fee of item.Price?.ExtraFees" type="button"
        class="btn btn-secondary btn-sm w-100 text-start mt-2"
        (click)="searchAction.emit('#'+fee.InternalItemNumber)">
        <div class="row">
          <div class="col-12"><i>{{getFeeKind(fee.FeeKind)}}</i></div>
          <div class="col-12"><span class="text-dark">{{fee.InternalItemNumber}}</span></div>
          <div class="col-7"><b>{{fee.ItemNumber}}</b></div>
          <div class="col-5 text-end">{{fee.FeeAmount | price}}</div>
          <div class="col-12" *ngIf="fee.Description">{{fee.Description}}</div>
        </div>
      </button>

    </div>
    <div class="col-xl-4">
      <div *ngIf="getWebGroup(item)==99" class="alert alert-danger" role="alert">
        <i class="fas fa-exclamation-triangle"></i> <span class="m-1"><strong>Let op... Webgroep =
            99!</strong></span><br />
        <div class="mt-1">Dit artikel is niet zichtbaar op het internet!</div>
      </div>
      <div *ngIf="getWebGroup(item)==98" class="alert alert-danger" role="alert">
        <i class="fas fa-exclamation-triangle"></i> <span class="m-1"><strong>Let op... Webgroep =
            98!</strong></span><br />
        <div class="mt-1">Dit artikel geen prijzen op het internet!</div>
      </div>
      <div *ngIf="mainService.getStripped(item.ItemNumber)!=item.ItemNumberStripped" class="alert alert-danger"
        role="alert">
        <i class="fas fa-exclamation-triangle"></i> <span class="m-1"><strong>Let op artikelnummer
            verkort!</strong></span><br />
        <div class="mt-1">{{item.ItemNumberStripped}} &ne; {{mainService.getStripped(item.ItemNumber)}}</div>
      </div>
      <table class="table table-bordered table-striped">
        <tbody>
        <ng-container *ngFor="let property of item.Properties | keyvalue">
          <tr *ngIf="property.key !== 'Image' && property.key !== 'ProfileImage'">
            <td class="p-0">
              <div class="row">
                <div class="col-6 sm-td-l">{{property.key}}</div>
                <div class="col-6 sm-td">
                  <ng-container [ngSwitch]="property.key">
                    <ng-container *ngSwitchCase="'RaiUpdateDate'">{{property.value | date : 'dd-MM-yyyy HH:mm'}}</ng-container>
                    <ng-container *ngSwitchCase="'PriceUpdateDate'">{{property.value | date : 'dd-MM-yyyy HH:mm'}}</ng-container>
                    <ng-container *ngSwitchCase="'VrmArticleId'"><span class="pointer" (click)="searchAction.emit('^'+property.value)">{{property.value}}</span></ng-container>
                    <ng-container *ngSwitchDefault>{{property.value}}</ng-container>
                  </ng-container>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
      </table>
      <div class="image-thumbs-container">
        <div class="image-container" *ngFor="let url of getImages(item)">
          <img [src]="url" class="img-fluid image-thumb" />
        </div>
      </div>
    </div>
    <div class="col-xl-5">
      <table class="table table-primary table-striped">
        <tbody>
        <ng-container *ngFor="let branchInfo of getBranchInfos(item)">
          <tr>
            <td>
              <app-item-info-branch-availability [branchInfo]="branchInfo"
                [branches]="response.Branches"></app-item-info-branch-availability>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let supplierInfo of getSupplierInfos(item)">
          <tr>
            <td>
              <app-item-info-supplier-availability [supplierInfo]="supplierInfo" [suppliers]="response.Suppliers"
                [isMainSupplier]="supplierInfo.SupplierNumber===item.MainSupplier"
                [netPrice]="getSupplierNetPrice(item, supplierInfo)"></app-item-info-supplier-availability>
            </td>
          </tr>
        </ng-container>
      </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="item.Alternatives.length" class="row">
    <div class="col-xl-3">
      <button type="button" class="btn btn-primary btn-xs w-100" (click)="alternativesOpen=!alternativesOpen">
        Alternatieven <span class="badge text-bg-light ms-1">{{item.Alternatives.length}}</span>
      <div class="float-end collapse-button" [ngClass]="{'is-collapsed': !alternativesOpen}"><i class="fas fa-chevron-up"></i></div>
    </button>
    </div>
    <div class="col-12 mt-2">
      <div *ngIf="alternativesOpen" [@slideInOut] class="collapsable">
        <table class="table table-striped small">
          <tbody>
            <tr *ngFor="let alt of item.Alternatives" app-item-info-data [item]="alt" [response]="response"
              (searchAction)="searchAction"></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</td>