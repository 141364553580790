<ng-container *ngIf="!response || !response?.ItemResults?.length; else responseExists">
  <div class="alert alert-warning" role="alert">
    Geen antwoord ontvangen!
  </div>
</ng-container>

<ng-template #responseExists>
  <ng-container *ngIf="filterEnabled">
    <div *ngIf="isFilterPresent(); else noFilter" class="row">
      <div *ngFor="let filterDeclaration of getActiveFilterDeclarations() | keyvalue" class="col-6">
        <div class="input-group input-group-sm mb-2">
          <label class="input-group-text"
            [for]="'inputGroupSelect'+filterDeclaration.key">{{filterDeclaration.key}}</label>
          <select class="custom-select" [id]="'inputGroupSelect'+filterDeclaration.key"
            (change)="changeFilter(filterDeclaration.key, $event)">
            <option *ngFor="let value of filterDeclaration.value" [value]="value"
              [attr.selected]="response.Filter[filterDeclaration.key]===value?true:null">{{value}}</option>
          </select>
          <button class="btn btn-danger" type="button" (click)="clearFilter(filterDeclaration.key)"><i
              class="fas fa-times"></i></button>
          <button class="btn btn-success" type="button" (click)="addFilter()"><i class="fas fa-plus"></i></button>
        </div>
      </div>
    </div>
    <ng-template #noFilter>
      <button class="btn btn-sm btn-success mb-2" type="button" (click)="addFilter()"><i
          class="fas fa-plus"></i></button>
    </ng-template>
  </ng-container>

  <table class="table table-striped small">
    <tbody>
      <ng-container *ngFor="let item of filteredItems">
        <tr app-item-info-data [item]="item" [response]="response" (searchAction)="searchAction.emit($event)"></tr>
      </ng-container>
    </tbody>
  </table>

</ng-template>