<div class="pro-body">
  <div class="pro-products clearfix grid">
    <div class="four-columns-50-50">
      <div *ngFor="let button of ctx.Buttons" class="four-columns-25-25-25-25">
        <ng-container [ngSwitch]="+button.CommandValue">
          <a *ngSwitchDefault (click)="shopService.gotoShopModule(mainService.ctxMain, +button.CommandValue)" class="pro-icon" [ngClass]="'pro-shop' + button.CommandValue">{{button.Text}}</a>
          <!-- <a *ngSwitchCase="shopSoort.Banden" class="pro-icon" [ngClass]="'pro-shop' + button.CommandValue">{{button.Text}}</a> -->
        </ng-container>
        
      </div>
    </div>
    <div class="four-columns-50-50 last">
      <div class="kenteken-form" id="kenteken-form">
        <input type="text" [appAutofocus]="true" appLicensePlate class="registration-input" [ngModel]="licensePlate"
          (ngModelChange)="licensePlate=$event" (click)="licensePlate = ''"
          (keyup.enter)="searchLicensePlate(licensePlate)" placeholder="01-pro-01">
        <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-mmt" (click)="searchBrandModelType()">
          <i class="fas fa-car" data-fa-transform="grow-10" tooltip="Klik hier om via merk, model, type te kiezen."
            placement="left" container="body"></i>
        </div>
        <div *ngIf="ctxCarTypeSelection" class="text-primary pointer icon-history" (click)="searchHistory()">
          <i class="fas fa-history" data-fa-transform="grow-8" tooltip="Klik hier om een eerder gekozen auto te kiezen."
            placement="left" container="body"></i>
        </div>
      </div>
      <p *ngIf="ctx.CarType" class="pro-kenteken-label">{{ctx.CarType?.Model.Brand.BrandName}} {{ctx.CarType.TypeName}}
      </p>
      <p *ngIf="!ctx.CarType" class="pro-kenteken-label">Vul uw kenteken in</p>
      <div *ngIf="ctxCarTypeSelection && ctxCarTypeSelection.MededelingOnderAutokeuze"
        [innerHTML]="mainService.getSafeHtml(ctxCarTypeSelection.MededelingOnderAutokeuzeHtml)"></div>
      <div class="promotions-container">
        <div *ngIf="shopService.containsShopModule(mainService.ctxMain, shopSoort.Aanbiedingen)" class="in-spot">
          <app-promotions-carousel-view [ctx]="ctxPromotions" [shopKind]="22"></app-promotions-carousel-view>
        </div>
      </div>
    </div>

  </div>

  <div class="pro-sub-footer">
    <img src="/assets/img/identity/proparts/pro-footerbg.png" alt="">
  </div>

  <footer>
    <div class="footer-inner clearfix">
      <div class="footer-container">
        <div class="two-columns-50-50 clearfix grid">
          <div class="two-columns-50-50-inner">
            <div class="column">
              <div class="column-inner ">
                <img src="/assets/img/identity/proparts/footer-logo.png" alt="Pro Parts Embleem" class="pro-embleem"
                  width="45" height="40">
                <span class="pro-copy-text"> © 2018-{{year}} Pro Parts Utrecht B.V. Alle rechten voorbehouden</span>
              </div>
            </div>
            <div class="column">
              <div class="column-inner">
                <div id="nav_menu-3" class="widget footer-bottom-left widget_nav_menu">
                  <div class="menu-footer-container">
                    <ul class="menu">
                      <li>
                        <a href="http://www.proparts.nl/vacatures/" target="_blank">Vacatures</a>
                      </li>
                      <li>
                        <a href="http://www.proparts.nl/disclaimer/" target="_blank">Disclaimer</a>
                      </li>
                      <li>
                        <a href="http://www.proparts.nl/privacy-policy/" target="_blank">Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <a class="social-icon-widget-holder" data-hover-color="#ed253c"
                  style="color: #858585;font-size: 16px;margin: 0 20px 0 0"
                  href="https://www.facebook.com/proparts.utrecht" target="_blank">
                  <span class="social-icon-widget icomoon-icon-facebook"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>