import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, merge } from 'rxjs';
import { mergeMap, filter, catchError, timeout } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import { ContextPublicRequest } from '../_models/common/context.public.request';
import { ContextPublic } from '../_models/common/context.public';
import { ContextMain } from '../_models/common/context.main';
import { TyreRequest } from '../_models/tyres/tyre.request';
import { TyreResult } from '../_models/tyres/tyre.result';
import { ShoppingCartItem } from '../_models/cart/shopping.cart.item';
import { ContextCart } from '../_models/cart/context.cart';
import { ResponseItem } from '../_models/item-info/response.item';
import { ItemInfoRequest } from '../_models/item-info/item.info.request';
import { ShopSoort } from '../_models/common/shop.soort';
import { ContextCarTypeSelection } from '../_models/car-types/context.car.type.selection';
import { UniversalCarType } from '../_models/car-types/universal.car.type';
import { ContextCatalog } from '../_models/catalog/context.catalog';
import { UniversalCarBrand } from '../_models/car-types/universal.car.brand';
import { UniversalCarModel } from '../_models/car-types/universal.car.model';
import { ContextNhsShop } from '../_models/nhs-shop/context.nhs.shop';
import { CarTypeSelectionInfo } from '../_models/car-types/car.type-selection.info';
import { ContextHomeScreen } from '../_models/common/context.home.screen';
import { CarTypeResult } from '../_models/car-types/car.type.result';
import { OrderRequestParams } from '../_models/cart/order.request.params';
import { ShopOrderResponse } from '../_models/cart/shop.order.response';
import { Settings } from '../_models/common/settings';
import { CatalogDataRequest } from '../_models/catalog/catalog.data.request';
import { UniversalPartsCategory } from '../_models/catalog/universal.parts.category';
import { Part } from '../_models/catalog/part';
import { ContextPromotions } from '../_models/promotions/context.promotions';
import { ContextGraphicParts } from '../_models/catalog/context.graphic.parts';
import { ContextRimsAndTyres } from '../_models/rims-and-tyres/context.rims.and.tyres';
import { WheelTyreSet } from '../_models/rims-and-tyres/wheel.tyre.set';
import { WheelCarPictureRequest } from '../_models/rims-and-tyres/wheel.car.picture.request';
import { Wheel } from '../_models/rims-and-tyres/wheel';
import { ContextTyres } from '../_models/tyres/context.tyres';
import { ContextLoyaltyShop } from '../_models/loyalty-shop/context.loyalty.shop';
import { ContextCustomerInformation } from '../_models/customer-information/context.customer.information';
import { CartService } from './cart.service';
import { ContextAldocSys } from '../_models/aldoc-sys/context.aldoc.sys';
import { ContextPortalSettings } from '../_models/portal-settings/context-portal-settings';
import { PortalSettingGroup } from '../_models/portal-settings/portal-setting-group';
import { ContextExternalFrame } from '../_models/external-frame/context.external.frame';
import { ContextAdmin } from '../_models/admin/context.admin';
import { TimingObject } from '../_models/common/timing.object';
import { SettingsCatalogLines } from '../_models/admin/settings/settings.catalog.lines';
import { PortalStatisticsToday } from '../_models/admin/statistics/portal.statistics.today';
import { BarChart } from '../_models/admin/statistics/bar.chart';
import { DatePeriod } from '../_models/admin/statistics/date.period';
import { SettingsCarType } from '../_models/admin/settings/settings.car.type';
import { SettingsCatalogLayout } from '../_models/admin/settings/settings.catalog.layout';
import { SettingsTyres } from '../_models/admin/settings/settings.tyres';
import { SettingsRimsAndTyres } from '../_models/admin/settings/settings.rims.and.tyres';
import { SettingsShoppingCart } from '../_models/admin/settings/settings.shopping.cart';


import { LicensePlateTypeRequest } from '../_models/license-plates/license.plate.type.request';
import { LicensePlateType } from '../_models/license-plates/license.plate.type';
import { LicensePlateRequest } from '../_models/license-plates/license.plate.request';
import { LicensePlateResponse } from '../_models/license-plates/license.plate.response';
import { LicensePlateCustomerSettings } from '../_models/license-plates/license.plate.customer.settings';
import { TypeaheadItem } from '../_models/typeahead/typeahead.item';
import { Order } from '../_models/orders/order';
import { DeliveryNoteHeader } from '../_models/delivery-notes/delivery.note.header';
import { InvoiceHeader } from '../_models/invoices/invoice.header';
import { OutstandingBill } from '../_models/bills/outstanding.bill';
import { ShopOrder } from '../_models/shoporders/shop.order';
import { ReturnHeader } from '../_models/returns/return.header';
import { Return } from '../_models/returns/return';
import { ReturnDetail } from '../_models/returns/return.detail';
import { PromotionsAdmin } from '../_models/admin/promotions/promotions.admin';
import { ReturnOrder } from '../_models/returns/return.order';
import { DeliveryNoteItem } from '../_models/delivery-notes/delivery.note.item';
import { Aanbieding } from '../_models/promotions/aanbieding';
import { SortOrderUpdate } from '../_models/admin/sort.order.update';
import { UploadFile } from '../_common/upload/upload-file.model';
import { FileSystemFileEntry } from '../_common/upload/dom.types';
import { SettingsModules } from '../_models/admin/settings/settings.modules';
import { BranchModel } from '../_models/common/branch.model';
import { CustomerModel } from '../_models/common/customer.model';
import { LogService } from './log.service';
import { HeartbeatInfo } from '../_models/common/heart.beat.info';
import { OnlineSessions } from '../_models/admin/statistics/online.sessions';
import { RequestContextNhsShop } from '../_models/nhs-shop/request.context.nhs.shop';
import { PaintRequest } from '../_models/paint/paint.request';
import { SupplierModel } from '../_models/common/supplier.model';
import { SettingsGeneral } from '../_models/admin/settings/settings.general';
import { SettingsLoyaltyShop } from '../_models/admin/settings/settings.loyalty.shop';
import { LoyaltyShopCategory } from '../_models/loyalty-shop/loyalty.shop.category';
import { LoyaltyShopData } from '../_models/loyalty-shop/loyalty.shop.data';
import { LoyaltyShopItem } from '../_models/loyalty-shop/loyalty.shop.item';
import { SettingsCatalogPartsTypes } from '../_models/admin/settings/settings.catalog.parts.types';
import { RimRequest } from '../_models/rims-and-tyres/rim.request';
import { ConfigData } from '../_models/common/config.data';
import { SettingsExceptionsAvailabilityAndPrice } from '../_models/admin/settings/settings.exceptions.availability.and.price';
import { CatalogPropertyData } from '../_models/catalog/catalog.property.data';
import { SettingsBase } from '../_models/admin/settings/settings.base';
import { UserIdentificationModel } from '../_models/user.identification.model';
import { ContextThirdPartyFrame as ContextThirdPartyFrame } from '../_models/thirdparty-shop/context.third.party.frame';
import { ShopPortalOrderRequest } from '../_models/cart/shop.portal.order.request';
import { ShopPortalOrderResponse } from '../_models/cart/shop.portal.order.response';
import { SettingsAvailabilitySuppliers } from '../_models/admin/settings/settings.availability.suppliers';
import { ContextMpm } from '../_models/mpm/context.mpm';
import { CartItemsResponse } from '../_models/cart/cart.items.response';
import { CreditsInfo } from '../_models/loyalty-shop/credits.info';
import { ItemGroup } from '../_models/common/item.group';
import { AdjustLoyaltyShopCreditsRequest } from '../_models/loyalty-shop/adjust.loyalty.shop.credits.request';
import { AdjustLoyaltyShopCreditsResponse } from '../_models/loyalty-shop/adjust.loyalty.shop.credits.response';
import { SupplierInterfaceResponse } from '../_models/admin/supplier-interface/supplier.interface.response';
import { PickupRequest } from '../_models/pickup-request/pickup.request';
import { ItemImagesItemNumberData } from '../_models/admin/item-images/item.images.item.number.data';
import { ItemImageItemNumber } from '../_models/admin/item-images/item.image.item.number';
import { ItemImageLink } from '../_models/admin/item-images/item.image.link';
import { ItemImagesItemGroupData } from '../_models/admin/item-images/item.images.item.group.data';
import { ItemImageItemGroup } from '../_models/admin/item-images/item.image.item.group';
import { SupplierInterfaceData } from '../_models/admin/supplier-interface/supplier.interface.data';
import { ItemInfoResponse } from '../_models/item-info/item.info.response';
import { UpdateSupplierInterfaceDataRequest } from '../_models/admin/supplier-interface/update.supplier.interface.data.request';
import { ContextMainRequest } from '../_models/common/context.main.request';
import { ArticleSourceSearchRequest } from '../_models/common/article-source/article.source.search.request';
import { ArticleSourceRecord } from '../_models/common/article-source/article.source.record';
import { SettingModel } from '../_models/common/setting.model';
import { FavoriteItem } from '../_models/favorites/favorite.item';
import { GisRequest } from '../_models/customer-information/gis.request';
import { QueueMessage } from '../_models/common/queue.message';
import { Router } from '@angular/router';
import { AccountRequest } from '../_models/logon/account.request';
import { RouteInfo } from '../_models/item-info/route.info';
import { OrderInfoResponse } from '../_models/orders/order.info.response';
import { ArchiveOrdersResponse } from '../_models/orders/archive.orders.response';
import { ArchiveOrdersRequest } from '../_models/orders/archive.orders.request';
import { ContextPaint } from '../_models/paint/context.paint';
import { SettingsMenu } from '../_models/admin/settings/settings.menu';
import { VoorraadTemplateRendered } from '../_models/item-info/voorraad.template.rendered';
import { SupplierDepot } from 'app/_models/item-info/supplier.depot';
import { RouteInfoObject } from 'app/_models/item-info/route.info.object';
import { ItemInfoWarnings } from 'app/_models/item-info/item.info.warnings';
import { CreditWorthyResponse } from 'app/_models/common/credit.worthy.response';

@Injectable()
export class ApiService {
  private saveSettingTimeoutHandles: { [key: string]: number } = {};
  private ctxPublic: ContextPublic;
  private ctxMain: ContextMain;

  constructor(
    private http: HttpClient,
    private logService: LogService,
    private config: ConfigData,
    private router: Router
  ) {
  }

  encodeURI(str) {
    return encodeURIComponent(str).replace('%2F', '%252F');
  }

  /** POST to UpdateHeartbeat */
  public updateHeartbeat(shopKind: ShopSoort, currentLocation = ''): Observable<boolean> {
    const hbi = new HeartbeatInfo();
    hbi.Timestamp = new Date();
    hbi.CurrentShop = shopKind;
    hbi.CurrentLocation = currentLocation;
    hbi.LogEntries = [];
    while (this.logService.logBuffer.length) {
      const entry = this.logService.logBuffer.shift();
      if (entry.Level <= this.logService.logLevel) { hbi.LogEntries.push(entry); }
    }
    return this.http.post<QueueMessage[]>(`${this.config.backendApi}/api/Heartbeat/Tick`, hbi)
      .pipe(mergeMap(messages => {
        if (messages?.length) {
          console.info(`Got queue messages ${messages}`);
          messages.forEach(msg => {
            if (msg.Message.toUpperCase() === 'RELOAD') {
              this.router.navigate(['/reload']);
            }
            this.http.post<number>(`${this.config.backendApi}/api/Heartbeat/Tock`, msg)
              .subscribe(processed => {
                console.info(`Marked ${msg.Message} => ${processed}`);
              });
          });
        }
        return of(true);
      }));
  }

  /** GET to logout */
  public doLogout(): Observable<boolean> {
    return this.http.get<boolean>(this.config.backendApi + `/api/Main/Logout`);
  }

  /** POST ContextPublicRequest to get ContextPublic */
  public getContextPublic(contextRequest: ContextPublicRequest): Observable<ContextPublic> {
    return this.http.post<ContextPublic>(`${this.config.backendApi}/api/Public/Context`, contextRequest)
      .pipe(mergeMap((ctx: ContextPublic) => {
        if (ctx && ctx.Settings && ctx.Settings['redirect']) {
          window.location.href = ctx.Settings['redirect'];
        }
        this.ctxPublic = ctx;
        if (ctx && ctx.Settings && ctx.Settings['BackendUrl'] && ctx.Settings['BackendUrl'] !== this.config.backendApi) {
          ctx.Settings['BackendUrl'] = this.config.backendApi;
        }
        return of(ctx);
      }));
  }

  /** GET to send forgot password email */
  public sendForgotPassword(company: string, email: string): Observable<boolean> {
    return this.http.get<boolean>(this.config.backendApi + `/auth/ForgotPassword?wholesaler=${this.config.ctxPublic.Wholesaler}&company=${company}&email=${email}`);
  }

  /** POST to send account requesty */
  public requestAccount(request: AccountRequest): Observable<boolean> {
    return this.http.post<boolean>(this.config.backendApi + `/auth/RequestAccount?wholesaler=${this.config.ctxPublic.Wholesaler}`, request);
  }

  /** POST ShoppingCartItem and get ContextCart to update */
  public changeShoppingCartItem(cartService: CartService, sci: ShoppingCartItem): Observable<ContextCart> {
    console.info('changeShoppingCartItem -> ', sci);
    const clone = ShoppingCartItem.getCloneWithoutItemInfo(sci);
    return this.http.post<ContextCart>(`${this.config.backendApi}/api/Cart`, clone)
      .pipe(mergeMap((result: ContextCart) => {
        if (sci.Aantal !== 0) { cartService.updateContextCart(result); }
        return of(result);
      }));
  }

  /** POST alternative ResponseItem and get ContextCart to update */
  public chooseAlternative(cartService: CartService, sci: ShoppingCartItem, alternative: ResponseItem) {
    this.http.post<ContextCart>(this.config.backendApi + `/api/Cart/Items/${sci.ID}/Alternative`, alternative)
      .subscribe((result: ContextCart) => cartService.updateContextCart(result));
  }

  /** DELETE ShoppingCart items and get ContextCart to update */
  public emptyShoppingcart(): Observable<ContextCart> {
    return this.http.delete<ContextCart>(`${this.config.backendApi}/api/Cart`);
  }

  /** POST OrderRequestParams to order cart and get ShopOrderResponse */
  public orderShoppingCart(order: OrderRequestParams): Observable<ShopPortalOrderResponse> {
    const shopOrder = new ShopPortalOrderRequest();
    shopOrder.Reference = order.Referentie;
    shopOrder.Remark = order.Opmerking;
    shopOrder.DeliveryMode = order.Levermode;
    shopOrder.Route = order.Route;
    shopOrder.DeliveryMoment = order.DeliveryMoment;
    shopOrder.DeliveryChoice = order.AfleverKeuze;
    shopOrder.Urgency = order.Spoed;
    shopOrder.TimeStamp = order.TimeStamp;
    console.info('orderShoppingCart', shopOrder);
    return this.http.post<ShopPortalOrderResponse>(`${this.config.backendApi}/api/Cart/Order`, shopOrder)
      .pipe(
        timeout(this.config.timeout),
        mergeMap(response => {
          return of(response);
        }),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            console.error(`Error: ${error.error.message}`);
          } else {
            console.error(`Error: ${error.message}`);
          }
          return of(new ShopPortalOrderResponse());
        })
      );
  }

  /** GET CheckOrder by timestamp */
  public checkOrder(timestamp: Date): Observable<ShopPortalOrderResponse> {
    return this.http.get<ShopPortalOrderResponse>(`${this.config.backendApi}/api/Cart/Order/Check/${timestamp.getTime()}`)
      .pipe(
        timeout(this.config.timeout),
        mergeMap(response => {
          return of(response);
        }),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            console.error(`Error: ${error.error.message}`);
          } else {
            console.error(`Error: ${error.message}`);
          }
          return of(new ShopPortalOrderResponse());
        })
      );
  }

  /** POST get ContextMain */
  public getContextMain(request: ContextMainRequest): Observable<ContextMain> {
    console.time('getContextMain');
    return this.http.post<ContextMain>(`${this.config.backendApi}/api/Main/Context`, request)
      .pipe(mergeMap((ctx: ContextMain) => {
        this.ctxMain = ctx;
        // if (ctx && ctx.AppInfo) {
        //   ctx.AppInfo['BackendApiUrl'] = this.config.backendApi;
        //   ctx.AppInfo['BackendApiVersion'] = this.config.backendApiVersion;
        // }
        console.timeEnd('getContextMain');
        return of(ctx);
      }));
  }

  /** GET Admin get settings */
  public adminGetSettings(): Observable<{ [key: string]: { [key: number]: { [key: number]: { [key: number]: { [key: number]: { [key: number]: { [key: string]: SettingModel } } } } } } }> {
    return this.http.get<{ [key: string]: { [key: number]: { [key: number]: { [key: number]: { [key: number]: { [key: number]: { [key: string]: SettingModel } } } } } } }>(`${this.config.backendApi}/api/Admin/Settings`);
  }

  /** GET get ContextAdmin */
  public adminGetContextAdmin(uid: UserIdentificationModel): Observable<ContextAdmin> {
    return this.http.get<ContextAdmin>(this.addUid(uid, `${this.config.backendApi}/api/Admin/Context`));
  }

  public adminGetBadges(wholesaler: number): Observable<{ [key: string]: number }> {
    return this.http.get<{ [key: string]: number }>(`${this.config.backendApi}/api/Admin/Badges?w=${wholesaler}`);
  }

  /** POST Admin post internalItemNumbers array to get ResponseItem models for wholesaler */
  public adminGetItemNumbers(wholesaler: number, internalItemNumbers: number[]): Observable<ResponseItem[]> {
    return this.http.post<ResponseItem[]>(this.config.backendApi + `/api/Admin/${wholesaler}/ItemNumbers`, internalItemNumbers);
  }

  /** POST Admin post itemgroupnumbers array to get itemgroup models for wholesaler */
  public adminGetItemGroups(wholesaler: number, itemGroups: number[]): Observable<ItemGroup[]> {
    if (itemGroups?.length)
      return this.http.post<ItemGroup[]>(this.config.backendApi + `/api/Admin/${wholesaler}/ItemGroups`, itemGroups);
    else
      return this.http.get<ItemGroup[]>(this.config.backendApi + `/api/Admin/${wholesaler}/ItemGroups`);
  }

  /** GET Admin get branches for wholesaler */
  public adminGetBranches(wholesaler: number): Observable<BranchModel[]> {
    return this.http.get<BranchModel[]>(this.config.backendApi + `/api/Admin/${wholesaler}/Branches`);
  }

  /** GET Admin get customer for wholesaler and customernumber */
  public adminGetCustomer(wholesaler: number, customerNumber: number, withUsers: boolean = false): Observable<CustomerModel> {
    return this.http.get<CustomerModel>(this.config.backendApi + `/api/Admin/${wholesaler}/Customer/${customerNumber}${withUsers ? '?users=true' : ''}`);
  }

  /** GET Admin get supplier for wholesaler and suppliernumber */
  public adminGetSupplier(wholesaler: number, supplierNumber: number): Observable<SupplierModel> {
    return this.http.get<SupplierModel>(this.config.backendApi + `/api/Admin/${wholesaler}/Supplier/${supplierNumber}`);
  }

  /** GET Admin last x orders for wholesaler by customerNumber */
  public adminGetLastShopOrders(wholesaler: number, customerNumber: number, orderCount: number, onlyLoyaltyShopOrders: boolean = false): Observable<ShopOrder[]> {
    return this.http.get<ShopOrder[]>(this.config.backendApi + `/api/Admin/${wholesaler}/ShopOrder/${customerNumber}/Last/${orderCount}${onlyLoyaltyShopOrders ? '?onlyLoyaltyShopOrders=true' : ''}`);
  }

  /** GET Admin get shoporder for wholesaler and orderNumber */
  public adminGetShopOrder(wholesaler: number, orderId: number): Observable<ShopOrder> {
    return this.http.get<ShopOrder>(this.config.backendApi + `/api/Admin/${wholesaler}/ShopOrder/${orderId}`);
  }

  /** GET Admin reload settings */
  public adminReloadSettings(backendApis: string[]): Observable<boolean> {
    return this.http.get<boolean>(`${backendApis[0]}/api/Admin/ReloadSettings`);
  }

  /** GET Admin reload CatalogHelper */
  public adminReloadCatalogHelper(backendApis: string[]): Observable<TimingObject> {
    return this.http.get<TimingObject>(`${backendApis[0]}/api/Admin/ReloadCatalogUtils`);
  }

  //
  /** GET Admin convert ShopModule Settings */
  public adminConvertShopModuleSettings(): Observable<TimingObject> {
    return this.http.get<TimingObject>(`${this.config.backendApi}/api/Admin/Processes/ShopModuleSettings`);
  }

  /** GET Admin get session count online*/
  public adminGetOnlineSessionCount(): Observable<number> {
    return this.http.get<number>(`${this.config.backendApi}/api/Admin/Statistics/Online/Count`);
  }

  /** GET Admin get sessions online*/
  public adminGetOnlineSessions(): Observable<OnlineSessions> {
    return this.http.get<OnlineSessions>(`${this.config.backendApi}/api/Admin/Statistics/Online`);
  }

  /** GET Admin statistics today */
  public adminGetStatisticsToday(): Observable<PortalStatisticsToday> {
    return this.http.get<PortalStatisticsToday>(`${this.config.backendApi}/api/Admin/Statistics/Today`);
  }

  /** POST Admin statistics for date period per period */
  public adminGetStatistics(datePeriod: DatePeriod, period: string): Observable<BarChart> {
    return this.http.post<BarChart>(this.config.backendApi + `/api/Admin/Statistics/${period}`, datePeriod);
  }

  addUid(uid: UserIdentificationModel, url: string): string {
    if (uid) {
      let koppel = '?';
      if (url.indexOf(koppel) >= 0) { koppel = '&'; }
      return `${url}${koppel}w=${uid.Wholesaler}&b=${uid.Branch}&c=${uid.Customer}&u=${uid.UserID}`;
    }
    return url;
  }

  /** GET Admin get SettingsCatalogPartsTypes */
  public adminGetSettingsCatalogPartsTypes(uid: UserIdentificationModel): Observable<SettingsCatalogPartsTypes> {
    return this.http.get<SettingsCatalogPartsTypes>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/PartsTypes`));
  }

  /** POST Admin save SettingsCatalogPartsTypes */
  public adminSaveSettingsCatalogPartsTypes(settings: SettingsCatalogPartsTypes): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/Catalog/PartsTypes`, settings);
  }

  /** GET Admin get SettingsLicensePlateKinds */
  public adminGetSettingsLicensePlateKinds(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/LicensePlateKinds`));
  }

  /** GET Admin get SettingsWholesalerOverview */
  public adminGetSettingsWholesalerOverview(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/WholesalerOverview`));
  }

  /** GET Admin get SupplierInterfaceData */
  public adminGetSupplierInterfaceData(uid: UserIdentificationModel): Observable<SupplierInterfaceData> {
    return this.http.get<SupplierInterfaceData>(this.addUid(uid, `${this.config.backendApi}/api/AdminSupplierInterface`));
  }

  /** post Admin post UpdateSupplierInterfaceDataRequest to update and get new SupplierInterfaceData*/
  public adminUpdateInterfaceData(uid: UserIdentificationModel, updateRequest: UpdateSupplierInterfaceDataRequest): Observable<SupplierInterfaceData> {
    return this.http.post<SupplierInterfaceData>(this.addUid(uid, `${this.config.backendApi}/api/AdminSupplierInterface/Update`), updateRequest);
  }

  /** GET Admin get SettingsGeneral */
  public adminGetSettingsGeneral(uid: UserIdentificationModel): Observable<SettingsGeneral> {
    return this.http.get<SettingsGeneral>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/General`));
  }

  /** POST Admin save SettingsGeneral */
  public adminSaveSettingsGeneral(settings: SettingsGeneral): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/General`, settings);
  }

  /** GET Admin get SettingsBase */
  public adminGetSettingsLayout(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Layout`));
  }

  /** GET Admin get SettingsBase */
  public adminGetSettingsNavigation(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Navigation`));
  }

  /** GET Admin get SettingsModules */
  public adminGetSettingsModules(uid: UserIdentificationModel): Observable<SettingsModules> {
    return this.http.get<SettingsModules>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Modules`));
  }

  /** POST Admin save SettingsModules */
  public adminSaveSettingsModules(settings: SettingsModules): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/Modules`, settings);
  }

  /** GET Admin get SettingsMenu */
  public adminGetSettingsMenu(uid: UserIdentificationModel): Observable<SettingsMenu> {
    return this.http.get<SettingsMenu>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Menu`));
  }

  /** POST Admin save SettingsMenu */
  public adminSaveSettingsMenu(settings: SettingsMenu): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/Menu`, settings);
  }

  /** GET Admin get SettingsAvailabilitySuppliers */
  public adminGetSettingsAvailabilityTemplates(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>
      (this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/AvailabilityTemplates`));
  }
  
  /** POST Admin save SettingsAvailabilitySuppliers */
  public adminGetRenderedAvailabilityTemplateParts(uid: UserIdentificationModel, branch: number, customer: number, internalItemNumber: number, itemCount: number, templateParts: number[]): Observable<{ [key: number]: VoorraadTemplateRendered }> {
    return this.http.post<{ [key: number]: VoorraadTemplateRendered }>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/AvailabilityTemplates/${branch}/${customer}/${internalItemNumber}/${itemCount}`), templateParts);
  }

  /** GET Admin get SettingsAvailabilitySuppliers */
  public adminGetSettingsAvailabilitySuppliers(uid: UserIdentificationModel): Observable<SettingsAvailabilitySuppliers> {
    return this.http.get<SettingsAvailabilitySuppliers>
      (this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/AvailabilitySuppliers`));
  }

  /** POST Admin save SettingsAvailabilitySuppliers */
  public adminSaveSettingsAvailabilitySuppliers(settings: SettingsAvailabilitySuppliers): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/AvailabilitySuppliers`, settings);
  }

  /** GET Admin get SettingsExtraMessages */
  public adminGetSettingsExtraMessages(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>
      (this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/ExtraMessages`));
  }

  /** POST Admin save SettingsExtraMessages */
  public adminSaveSettingsExtraMessages(settings: SettingsBase): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/ExtraMessages`, settings);
  }

  /** GET Admin get SettingsBase */
  public adminGetSettingsTemplatePart(uid: UserIdentificationModel, templatePartName: string): Observable<SettingsBase> {
    return this.http.get<SettingsBase>
      (this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/TemplatePart/${templatePartName}`));
  }

  /** GET Admin get SettingsExceptionsAvailabilityAndPrice */
  public adminGetSettingsExceptionsAvailabilityAndPrice(uid: UserIdentificationModel): Observable<SettingsExceptionsAvailabilityAndPrice> {
    // 2023-11-13 Maurice: Was iets voor Twimva en wordt uiteindelijk niet gebruikt. Wordt op dit moment niet in huidige backend ondersteund, maar oude code staat er wel gecommentarieerd. 
    return this.http.get<SettingsExceptionsAvailabilityAndPrice>
      (this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/Exceptions`));
  }

  /** POST Admin save SettingsExceptionsAvailabilityAndPrice */
  public adminSaveSettingsExceptionsAvailabilityAndPrice(settings: SettingsExceptionsAvailabilityAndPrice): Observable<boolean> {
    // 2023-11-13 Maurice: Was iets voor Twimva en wordt uiteindelijk niet gebruikt. Wordt op dit moment niet in huidige backend ondersteund, maar oude code staat er wel gecommentarieerd. 
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/AvailabilityAndPrice/Exceptions`, settings);
  }

  /** GET Admin get SettingsBright */
  public adminGetSettingsBright(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Bright`));
  }

  /** GET Admin get SettingsVrooam */
  public adminGetSettingsVrooam(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Vrooam`));
  }

  /** GET Admin get SettingsAldoc */
  public adminGetSettingsAldoc(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Aldoc`));
  }

  /** GET Admin get SettingsCarType */
  public adminGetSettingsCarType(uid: UserIdentificationModel): Observable<SettingsCarType> {
    return this.http.get<SettingsCarType>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/CarType`));
  }

  /** POST Admin save SettingsCarType */
  public adminSaveSettingsCarType(settings: SettingsCarType): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/CarType`, settings);
  }

  /** GET Admin get SettingsCatalog */
  public adminGetSettingsCatalog(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog`));
  }

  /** GET Admin get SettingsCatalogAlcar */
  public adminGetSettingsCatalogAlcar(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Alcar`));
  }

  /** GET Admin get SettingsCatalogAldoc*/
  public adminGetSettingsCatalogAldoc(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Aldoc`));
  }

  /** GET Admin get SettingsCatalogHbase */
  public adminGetSettingsCatalogHbase(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Hbase`));
  }

  /** GET Admin get SettingsCatalogIntertyre */
  public adminGetSettingsCatalogIntertyre(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/InterTyre`));
  }

  /** GET Admin get SettingsCatalogMpm */
  public adminGetSettingsCatalogMpm(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Mpm`));
  }

  /** GET Admin get SettingsCatalogSinatec */
  public adminGetSettingsCatalogSinatec(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(
      this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Sinatec`));
  }

  /** GET Admin get SettingsCatalogStahlie */
  public adminGetSettingsCatalogStahlie(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(
      this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Stahlie`));
  }

  /** GET Admin get SettingsCatalogTecdoc */
  public adminGetSettingsCatalogTecdoc(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Tecdoc`));
  }

  /** GET Admin get SettingsCatalogSetup */
  public adminGetSettingsCatalogSetup(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Setup`));
  }

  /** GET Admin get SettingsCatalogLayout */
  public adminGetSettingsCatalogLayout(uid: UserIdentificationModel): Observable<SettingsCatalogLayout> {
    return this.http.get<SettingsCatalogLayout>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Layout`));
  }

  /** POST Admin save SettingsCatalogLayout */
  public adminSaveSettingsCatalogLayout(settings: SettingsCatalogLayout): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/Catalog/Layout`, settings);
  }

  /** GET Admin get SettingsCatalogLines */
  public adminGetSettingsCatalogLines(uid: UserIdentificationModel): Observable<SettingsCatalogLines> {
    return this.http.get<SettingsCatalogLines>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Catalog/Lines`));
  }

  /** POST Admin save SettingsCatalogLines */
  public adminSaveSettingsCatalogLines(settings: SettingsCatalogLines): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/Catalog/Lines`, settings);
  }

  /** GET Admin get SettingsTyres */
  public adminGetSettingsTyres(uid: UserIdentificationModel): Observable<SettingsTyres> {
    return this.http.get<SettingsTyres>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Tyres`));
  }

  /** GET Admin get SettingsRimsAndTyres */
  public adminGetSettingsRimsAndTyres(uid: UserIdentificationModel): Observable<SettingsRimsAndTyres> {
    return this.http.get<SettingsRimsAndTyres>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/RimsAndTyres`));
  }

  /** POST Admin save SettingsRimsAndTyres */
  public adminSaveSettingsRimsAndTyres(settings: SettingsRimsAndTyres): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/RimsAndTyres`, settings);
  }

  /** GET Admin get SettingsLicensePlates */
  public adminGetSettingsLicensePlates(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/LicensePlates`));
  }

  /** GET Admin get SettingsPaint */
  public adminGetSettingsPaint(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Paint`));
  }

  /** GET Admin get SettingsCustomerInformation */
  public adminGetSettingsCustomerInformation(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/CustomerInformation`));
  }

  /** GET Admin get SettingsShoppingCart */
  public adminGetSettingsShoppingCart(uid: UserIdentificationModel): Observable<SettingsShoppingCart> {
    return this.http.get<SettingsShoppingCart>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Cart`));
  }

  /** POST Admin save SettingsShoppingCart */
  public adminSaveSettingsShoppingCart(settings: SettingsShoppingCart): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/Cart`, settings);
  }

  /** POST Admin save SettingsBase */
  public adminSaveSettingsBase(settings: SettingsBase): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/Save`, settings);
  }

  /** POST Admin delete SettingsBase */
  public adminDeleteSettingsBase(settings: SettingsBase): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/Delete`, settings);
  }

  /** GET Admin get SettingsPromotions */
  public adminGetSettingsPromotions(uid: UserIdentificationModel): Observable<SettingsBase> {
    return this.http.get<SettingsBase>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/Promotions`));
  }

  /** GET Admin get PromotionsAdmin */
  public adminGetPromotionsAdmin(uid: UserIdentificationModel): Observable<PromotionsAdmin> {
    return this.http.get<PromotionsAdmin>(this.addUid(uid, `${this.config.backendApi}/api/AdminPromotions`));
  }

  /** POST Admin save Promotion */
  public adminSavePromotion(uid: UserIdentificationModel, promotion: Aanbieding): Observable<Aanbieding> {
    return this.http.post<Aanbieding>(this.addUid(uid, `${this.config.backendApi}/api/AdminPromotions`), promotion);
  }

  /** DELETE Admin delete Promotion */
  public adminDeletePromotion(uid: UserIdentificationModel, promotionID: string): Observable<boolean> {
    return this.http.delete<boolean>(this.addUid(uid, `${this.config.backendApi}/api/AdminPromotions/${promotionID}`));
  }

  /** POST Admin upload Promotion Image and get progress and updated Promotion*/
  public adminAddPromotionImages(uid: UserIdentificationModel, promotionID: string, fileList: UploadFile[]): Observable<HttpEvent<Aanbieding>> {
    const formData = new FormData();
    fileList.forEach(uploadFile => {
      const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        formData.append(file.name, file, uploadFile.relativePath);
      });
    });
    const uploadReq = new HttpRequest('POST'
      , this.addUid(uid, `${this.config.backendApi}/api/AdminPromotions/${promotionID}/Image`), formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  /** DELETE Admin delete Promotion Image */
  public adminDeletePromotionImage(uid: UserIdentificationModel, promotionID: string, imageID: number): Observable<boolean> {
    return this.http.delete<boolean>(this.addUid(uid, `${this.config.backendApi}/api/AdminPromotions/${promotionID}/Image/${imageID}`));
  }

  /** POST Admin update Promotions SortOrder */
  public adminUpdatePromotionsSortOrder(uid: UserIdentificationModel, update: SortOrderUpdate): Observable<number> {
    return this.http.post<number>(this.addUid(uid, `${this.config.backendApi}/api/AdminPromotions/UpdateSortOrder`), update.Sort);
  }

  /** GET Admin get SettingsLoyaltyShop */
  public adminGetSettingsLoyaltyShop(uid: UserIdentificationModel): Observable<SettingsLoyaltyShop> {
    return this.http.get<SettingsLoyaltyShop>(this.addUid(uid, `${this.config.backendApi}/api/AdminSettings/LoyaltyShop`));
  }

  /** POST Admin save SettingsLoyaltyShop */
  public adminSaveSettingsLoyaltyShop(settings: SettingsLoyaltyShop): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/AdminSettings/LoyaltyShop`, settings);
  }

  /** GET Admin get LoyaltyShop CreditsInfo */
  public adminGetLoyaltyShopCreditsInfo(uid: UserIdentificationModel): Observable<CreditsInfo[]> {
    return this.http.get<CreditsInfo[]>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Credits`));
  }

  /** GET Admin get LoyaltyShop CreditsInfo for customer */
  public adminGetLoyaltyShopCreditsInfoCustomer(uid: UserIdentificationModel, customer: number): Observable<CreditsInfo> {
    return this.http.get<CreditsInfo>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Credits/${customer}`));
  }

  /** POST Admin adjustLoyaltyShopCreditsRequest to adjust credits */
  public adminAdjustLoyaltyShopCredits(uid: UserIdentificationModel, request: AdjustLoyaltyShopCreditsRequest): Observable<AdjustLoyaltyShopCreditsResponse> {
    return this.http.post<AdjustLoyaltyShopCreditsResponse>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Credits`), request);
  }

  /** GET Admin get LoyaltyShop Data */
  public adminGetLoyaltyShopData(uid: UserIdentificationModel): Observable<LoyaltyShopData> {
    return this.http.get<LoyaltyShopData>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop`));
  }

  // /** POST Admin save LoyaltyShopCategory */
  public adminSaveLoyaltyShopCategory(uid: UserIdentificationModel, category: LoyaltyShopCategory): Observable<LoyaltyShopCategory> {
    return this.http.post<LoyaltyShopCategory>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Category`), category);
  }

  /** DELETE Admin delete LoyaltyShopCategory */
  public adminDeleteLoyaltyShopCategory(uid: UserIdentificationModel, categoryID: number): Observable<boolean> {
    return this.http.delete<boolean>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Category/${categoryID}`));
  }

  /** POST Admin update LoyaltyShopCategories SortOrder */
  public adminUpdateLoyaltyShopCategoriesSortOrder(uid: UserIdentificationModel, update: SortOrderUpdate): Observable<number> {
    return this.http.post<number>(this.addUid(uid, `${this.config.backendApi}/api/Admin/LoyaltyShop/Categories/UpdateSortOrder`), update.Sort);
  }

  // /** POST Admin save LoyaltyShopItem */
  public adminSaveLoyaltyShopItem(uid: UserIdentificationModel, item: LoyaltyShopItem): Observable<LoyaltyShopItem> {
    return this.http.post<LoyaltyShopItem>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Item`), item);
  }

  /** DELETE Admin delete LoyaltyShopItem */
  public adminDeleteLoyaltyShopItem(uid: UserIdentificationModel, itemID: number): Observable<number> {
    return this.http.delete<number>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Item/${itemID}`));
  }

  // /** POST Admin save LoyaltyShopItem Categories and get new Cross */
  public adminSaveLoyaltyShopItemCategories(uid: UserIdentificationModel, item: LoyaltyShopItem, categories: number[]): Observable<LoyaltyShopData> {
    return this.http.post<LoyaltyShopData>
      (this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Item/${item.ID}/Categories`), categories);
  }

  /** POST Admin update LoyaltyShopItems SortOrder */
  public adminUpdateLoyaltyShopItemsSortOrder(uid: UserIdentificationModel, update: SortOrderUpdate, forCategoryID: number): Observable<number> {
    return this.http.post<number>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Category/${forCategoryID}/Items/UpdateSortOrder`), update.Sort);
  }

  /** POST Admin upload LoyaltyShopItem Image and get progress and updated LoyaltyShopItem*/
  public adminAddLoyaltyShopItemImages(uid: UserIdentificationModel, itemID: number, fileList: UploadFile[]): Observable<HttpEvent<LoyaltyShopItem>> {
    const formData = new FormData();
    fileList.forEach(uploadFile => {
      const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        formData.append(file.name, file, uploadFile.relativePath);
      });
    });
    const uploadReq = new HttpRequest('POST'
      , this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Item/${itemID}/Image`), formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  /** DELETE Admin delete LoyaltyShopItemImage Image */
  public adminDeleteLoyaltyShopItemImage(uid: UserIdentificationModel, imageID: number): Observable<boolean> {
    return this.http.delete<boolean>(this.addUid(uid, `${this.config.backendApi}/api/AdminLoyaltyShop/Item/Image/${imageID}`));
  }


  /** GET Admin get ItemImagesData */
  public adminGetItemImageItemNumbersData(uid: UserIdentificationModel, filter: string = '', pageNumber: number = 1, pageSize: number = 5): Observable<ItemImagesItemNumberData> {
    return this.http.get<ItemImagesItemNumberData>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemNumbers?filter=${filter}&pageNumber=${pageNumber}&pageSize=${pageSize}`));
  }

  // /** GET Admin get ItemImageItemNumber */
  public adminGetItemImageItemNumber(uid: UserIdentificationModel, internalItemNumber: number): Observable<ItemImageItemNumber> {
    return this.http.get<ItemImageItemNumber>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemNumber/${internalItemNumber}`));
  }

  // /** POST Admin save ItemImageItemNumber */
  public adminSaveItemImageItemNumber(uid: UserIdentificationModel, item: ItemImageItemNumber): Observable<ItemImageItemNumber> {
    return this.http.post<ItemImageItemNumber>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemNumber`), item);
  }

  /** POST Admin upload ItemImage and get progress and updated ItemImageItemNumber*/
  public adminUploadItemImageItemNumbers(uid: UserIdentificationModel, internalItemNumber: number, fileList: UploadFile[]): Observable<HttpEvent<ItemImageItemNumber>> {
    const formData = new FormData();
    fileList.forEach(uploadFile => {
      const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        formData.append(file.name, file, uploadFile.relativePath);
      });
    });
    const uploadReq = new HttpRequest('POST'
      , this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemNumber/${internalItemNumber}`), formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  /** POST Admin post url for ItemImage and get updated ItemImageItemNumber*/
  public adminAddItemImageItemNumber(uid: UserIdentificationModel, image: ItemImageLink): Observable<ItemImageItemNumber> {
    return this.http.post<ItemImageItemNumber>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemNumber/Url`), image);
  }

  //** DELETE Admin delete ItemImageItemNumber */
  public adminDeleteItemImagesItemNumber(uid: UserIdentificationModel, internalItemNumber: number): Observable<boolean> {
    return this.http.delete<boolean>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemNumber/${internalItemNumber}`));
  }

  //** DELETE Admin delete ItemImage */
  public adminDeleteItemImageItemNumber(uid: UserIdentificationModel, internalItemNumber: number, imageId: string): Observable<boolean> {
    return this.http.delete<boolean>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemNumber/${internalItemNumber}/${imageId}`));
  }



  /** GET Admin get ItemImagesItemGroupData */
  public adminGetItemImageItemGroupData(uid: UserIdentificationModel, filter: string = '', pageNumber: number = 1, pageSize: number = 5): Observable<ItemImagesItemGroupData> {
    return this.http.get<ItemImagesItemGroupData>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemGroups?filter=${filter}&pageNumber=${pageNumber}&pageSize=${pageSize}`));
  }

  // /** GET Admin get ItemImageItemGroup */
  public adminGetItemImageItemGroup(uid: UserIdentificationModel, itemGroupNumber: number): Observable<ItemImageItemGroup> {
    return this.http.get<ItemImageItemGroup>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemGroup/${itemGroupNumber}`));
  }

  // /** POST Admin save ItemImageItemGroup */
  public adminSaveItemImageItemGroup(uid: UserIdentificationModel, item: ItemImageItemGroup): Observable<ItemImageItemGroup> {
    return this.http.post<ItemImageItemGroup>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemGroup`), item);
  }

  /** POST Admin upload ItemImage and get progress and updated ItemImageItemGroup*/
  public adminUploadItemImageItemGroup(uid: UserIdentificationModel, itemGroupNumber: number, fileList: UploadFile[]): Observable<HttpEvent<ItemImageItemGroup>> {
    const formData = new FormData();
    fileList.forEach(uploadFile => {
      const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        formData.append(file.name, file, uploadFile.relativePath);
      });
    });
    const uploadReq = new HttpRequest('POST'
      , this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemGroup/${itemGroupNumber}`), formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  /** POST Admin post url for ItemImage and get updated ItemImageItemGroup*/
  public adminAddItemImageItemGroup(uid: UserIdentificationModel, image: ItemImageLink): Observable<ItemImageItemGroup> {
    return this.http.post<ItemImageItemGroup>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemGroup/Url`), image);
  }

  //** DELETE Admin delete ItemImageItemGroup */
  public adminDeleteItemImagesItemGroup(uid: UserIdentificationModel, itemGroupNumber: number): Observable<boolean> {
    return this.http.delete<boolean>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemGroup/${itemGroupNumber}`));
  }

  //** DELETE Admin delete ItemImage */
  public adminDeleteItemImageItemGroup(uid: UserIdentificationModel, itemGroupNumber: number, imageId: string): Observable<boolean> {
    return this.http.delete<boolean>(this.addUid(uid, `${this.config.backendApi}/api/AdminItemImages/ItemImageItemGroup/${itemGroupNumber}/${imageId}`));
  }



  /** GET Admin get ItemInfoWarnings */
  public adminGetItemInfoWarnings(uid: UserIdentificationModel): Observable<ItemInfoWarnings> {
    return this.http.get<ItemInfoWarnings>(this.addUid(uid, `${this.config.backendApi}/api/Admin/ItemInfo/Warnings`));
  }

  /** GET Admin get ItemInfoResponse for itemNumber and customer */
  public adminItemInfoDoInfo(uid: UserIdentificationModel, itemNumber: string, customer: number, useNewVersion: boolean): Observable<ItemInfoResponse> {
    return this.http.get<ItemInfoResponse>(this.addUid(uid, `${this.config.backendApi}/api/Admin/ItemInfo/${customer}/Info/${this.encodeURI(itemNumber.trim())}?nv=${useNewVersion}`));
  }

  /** POST Admin post ItemInfoResponse to update  */
  public adminUpdateSupplierAvailabilityItemInfo(uid: UserIdentificationModel, response: ItemInfoResponse, customer: number): Observable<ItemInfoResponse> {
    return this.http.post<ItemInfoResponse>(this.addUid(uid, `${this.config.backendApi}/api/Admin/ItemInfo/${customer}/Info`), response);
  }

  /** GET Admin get SupplierInterfaceResponse for interfaceKind and itemNumber */
  public adminSupplierInterfaceDoInfo(uid: UserIdentificationModel, interfaceKind: number, itemNumber: string): Observable<SupplierInterfaceResponse> {
    return this.http.get<SupplierInterfaceResponse>(this.addUid(uid, `${this.config.backendApi}/api/AdminSupplierInterface/${interfaceKind}/Info/${this.encodeURI(itemNumber.trim())}`));
  }

  /** GET Admin get ItemInfoResponse for itemNumber and customer */
  public adminArticleSourceSearch(uid: UserIdentificationModel, request: ArticleSourceSearchRequest): Observable<ArticleSourceRecord[]> {
    return this.http.post<ArticleSourceRecord[]>(this.addUid(uid, `${this.config.backendApi}/api/Admin/ArticleSource/Search`), request);
  }

  /** GET Admin get Customer Routes for branch and customer and datetime */
  public adminGetCustomerRouteInfo(uid: UserIdentificationModel, branch: number, customer: number, from: Date): Observable<RouteInfo[]> {
    return this.http.get<RouteInfo[]>(this.addUid(uid, `${this.config.backendApi}/api/Admin/RouteInfo/Branch/${branch}/Customer/${customer}?from=${from.toISOString()}`));
  }

  /** GET Admin get Branch Routes fromBranch toBranch and datetime */
  public adminGetBranchRouteInfo(uid: UserIdentificationModel, fromBranch: number, toBranch: number, from: Date): Observable<RouteInfo[]> {
    return this.http.get<RouteInfo[]>(this.addUid(uid, `${this.config.backendApi}/api/Admin/RouteInfo/FromBranch/${fromBranch}/ToBranch/${toBranch}?from=${from.toISOString()}`));
  }

  /** GET Admin get Depot Info for suppliers */
  public adminGetRouteInfoObject(uid: UserIdentificationModel): Observable<RouteInfoObject> {
    return this.http.get<RouteInfoObject>(this.addUid(uid, `${this.config.backendApi}/api/Admin/RouteInfo`));
  }

  /** GET Admin get Supplier Routes from supplier to branch and datetime */
  public adminGetSupplierRouteInfo(uid: UserIdentificationModel, supplier: number, depot: string, branch: number, from: Date): Observable<RouteInfo[]> {
    return this.http.get<RouteInfo[]>(this.addUid(uid, `${this.config.backendApi}/api/Admin/RouteInfo/FromSupplier/${supplier}/${depot}/ToBranch/${branch}?from=${from.toISOString()}`));
  }

  /** POST Admin search orders*/
  public adminSearchOrders(uid: UserIdentificationModel, request: ArchiveOrdersRequest): Observable<ArchiveOrdersResponse> {
    return this.http.post<ArchiveOrdersResponse>(this.addUid(uid, `${this.config.backendApi}/api/Admin/OrderInfo/Search`), request);
  }

  /** GET Admin get order information for ordernumber */
  public adminGetOrderInfo(uid: UserIdentificationModel, orderId: string): Observable<OrderInfoResponse> {
    return this.http.get<OrderInfoResponse>(this.addUid(uid, `${this.config.backendApi}/api/Admin/OrderInfo/${this.encodeURI(orderId.trim())}`));
  }

  /** GET Admin get customer warnings */
  public adminGetCustomerWarnings(uid: UserIdentificationModel, customer: number): Observable<CreditWorthyResponse> {
    return this.http.get<CreditWorthyResponse>(this.addUid(uid, `${this.config.backendApi}/api/Admin/CustomerWarnings/${customer}`));
  }

  /** GET get ContextPortalSettings */
  public getContextPortalSettings(): Observable<ContextPortalSettings> {
    return this.http.get<ContextPortalSettings>(`${this.config.backendApi}/api/Settings/Context`);
  }

  /** POST PortalSettingGroup to save settings */
  public savePortalSettingGroup(group: PortalSettingGroup): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.backendApi}/api/Settings/Group`, group);
  }

  /** GET get ContextHomeScreen */
  public getContextHomeScreen(): Observable<ContextHomeScreen> {
    return this.http.get<ContextHomeScreen>(`${this.config.backendApi}/api/HomeScreen/Context`);
  }

  /** GET get ContextPromotions */
  public getContextPromotions(): Observable<ContextPromotions> {
    return this.http.get<ContextPromotions>(`${this.config.backendApi}/api/Promotions/Context`);
  }

  /** GET get PromotionsCartItems */
  public getPromotionsCartItems(): Observable<{ [key: number]: ShoppingCartItem }> {
    return this.http.get<{ [key: number]: ShoppingCartItem }>(`${this.config.backendApi}/api/Promotions/CartItems`);
  }

  /** GET get ContextLoyaltyShop */
  public getContextLoyaltyShop(): Observable<ContextLoyaltyShop> {
    return this.http.get<ContextLoyaltyShop>(`${this.config.backendApi}/api/LoyaltyShop/Context`);
  }

  /** GET order LoyaltyShopItem and get ContextCart to update */
  public orderLoyaltyShopItem(cartService: CartService, item: LoyaltyShopItem) {
    this.http.get<ContextCart>(this.config.backendApi + `/api/LoyaltyShop/Order/${item.ID}`)
      .subscribe((result: ContextCart) => cartService.updateContextCart(result));
  }

  /** GET get Favorites */
  public getFavorites(): Observable<{ [key: number]: FavoriteItem }> {
    return this.http.get<{ [key: number]: FavoriteItem }>(`${this.config.backendApi}/api/Favorites`);
  }

  /** GET add Favorite with ShoppingCart*/
  public addFavorite(internalItemNumber: number): Observable<FavoriteItem> {
    return this.http.get<FavoriteItem>(`${this.config.backendApi}/api/Favorites/Add/${internalItemNumber}`);
  }

  /** POST add Favorite with Part*/
  public addFavoriteWithPart(internalItemNumber: number, part: Part): Observable<FavoriteItem> {
    return this.http.post<FavoriteItem>(`${this.config.backendApi}/api/Favorites/Add/${internalItemNumber}/Part`, part);
  }

  /** POST add Favorite with ShoppingCart*/
  public addFavoriteWithSci(internalItemNumber: number, sci: ShoppingCartItem): Observable<FavoriteItem> {
    return this.http.post<FavoriteItem>(`${this.config.backendApi}/api/Favorites/Add/${internalItemNumber}/Sci`, sci);
  }

  /** DELETE remove Favorite */
  public removeFavorite(internalItemNumber: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.backendApi}/api/Favorites/Remove/${internalItemNumber}`);
  }

  /** GET get ContextCart */
  public getContextCart(withExternalCarts: boolean): Observable<ContextCart> {
    if (withExternalCarts) {
      return this.http.get<ContextCart>(`${this.config.backendApi}/api/Cart/Context/Complete`);
    } else {
      return this.http.get<ContextCart>(`${this.config.backendApi}/api/Cart/Context`);
    }
  }

  /** GET get Clear external carts and ContextCart */
  clearExternalCarts(): Observable<ContextCart> {
    return this.http.delete<ContextCart>(`${this.config.backendApi}/api/Cart/ClearExternalCarts`);
  }

  /** GET get ContextAldocSys */
  public getContextAldocSys(): Observable<ContextAldocSys> {
    return this.http.get<ContextAldocSys>(this.config.backendApi + `/api/AldocSys/Context`);
  }

  /** GET get ContextThirdPartyShop */
  public getContextThirdPartyFrame(shopKind: ShopSoort, licensePlate: string, kType: number): Observable<ContextThirdPartyFrame> {
    const queryParams = [];
    var queryParamString = '';
    if (licensePlate) { queryParams.push(`licensePlate=${licensePlate}`); }
    if (kType) { queryParams.push(`kType=${kType}`); }
    if (queryParams.length > 0) { queryParamString = '?' + queryParams.join('&'); }
    return this.http.get<ContextThirdPartyFrame>(this.config.backendApi + `/api/ThirdPartyFrame/${shopKind}/Context${queryParamString}`)
  }

  /** POST RequestContextNhsShop to get ContextNhsShop */
  public getContextNhsShop(request: RequestContextNhsShop): Observable<ContextNhsShop> {
    return this.http.post<ContextNhsShop>(`${this.config.backendApi}/api/NhsShop/Context`, request);
  }

  /** POST UniversalCarType to get ContextMpm */
  public getContextMpm(carType: UniversalCarType): Observable<ContextMpm> {
    return this.http.post<ContextMpm>(`${this.config.backendApi}/api/Mpm/Context`, carType);
  }

  /** GET get ContextTyres */
  public getContextTyres(): Observable<ContextTyres> {
    return this.http.get<ContextTyres>(`${this.config.backendApi}/api/Tyres/Context`);
  }

  /** POST TyreRequest to get TyreResult */
  public getTyreSizes(request: TyreRequest): Observable<TyreResult> {
    return this.http.post<TyreResult>(`${this.config.backendApi}/api/Tyres`, request);
  }

  /** POST diameters to get rims for selected cartype*/
  public getWheels(request: RimRequest): Observable<ContextRimsAndTyres> {
    return this.http.post<ContextRimsAndTyres>(`${this.config.backendApi}/api/Rims`, request);
  }

  /** GET get ContextPaint */
  public getContextPaint(): Observable<ContextPaint> {
    return this.http.get<ContextPaint>(`${this.config.backendApi}/api/Paint/Context`);
  }

  /** POST GisRequest to get ContextCustomerInformation */
  public getContextCustomerInformation(request: GisRequest): Observable<ContextCustomerInformation> {
    return this.http.post<any>(`${this.config.backendApi}/api/CustomerInformation/Context`, request)
      .pipe(mergeMap((result: any) => {
        return of(result);
      }));
  }

  public getOpenOrdersV2(): Observable<Order[]> {
    return this.http.get<Order[]>(`${this.config.backendApi}/api/Orders/Open`);
  }

  public getOrder(orderNumber: number): Observable<Order> {
    return this.http.get<Order>(this.config.backendApi + `/api/Orders/${orderNumber}`);
  }

  public getDeliveryNotes(
    dateFrom: Date,
    dateTo: Date,
    itemNumber: string,
    deliveryNoteNumber: number,
    invoiceNumber: number,
    reference: string): Observable<DeliveryNoteHeader[]> {

    const dp = new DatePipe("nl");

    let params = new HttpParams();
    params = params.append('dateFrom', dp.transform(dateFrom, "yyyy-MM-dd"));
    params = params.append('dateTo', dp.transform(dateTo, "yyyy-MM-dd"));
    if (itemNumber) { params = params.append('itemNumber', itemNumber); }
    if (deliveryNoteNumber) { params = params.append('deliveryNoteNumber', deliveryNoteNumber.toString()); }
    if (invoiceNumber) { params = params.append('invoiceNumber', invoiceNumber.toString()); }
    if (reference) { params = params.append('reference', reference); }

    return this.http.get<DeliveryNoteHeader[]>(`${this.config.backendApi}/api/DeliveryNotes`, { params: params });
  }

  public getInvoices(
    dateFrom: Date,
    dateTo: Date,
    itemNumber: string,
    deliveryNoteNumber: number,
    invoiceNumber: number): Observable<InvoiceHeader[]> {

    const dp = new DatePipe("nl");

    let params = new HttpParams();
    params = params.append('dateFrom', dp.transform(dateFrom, "yyyy-MM-dd"));
    params = params.append('dateTo', dp.transform(dateTo, "yyyy-MM-dd"));
    if (itemNumber) { params = params.append('itemNumber', itemNumber); }
    if (deliveryNoteNumber) { params = params.append('deliveryNoteNumber', deliveryNoteNumber.toString()); }
    if (invoiceNumber) { params = params.append('invoiceNumber', invoiceNumber.toString()); }

    return this.http.get<InvoiceHeader[]>(`${this.config.backendApi}/api/Invoices`, { params: params });
  }

  public getShopOrders(
    dateFrom: Date,
    dateTo: Date,
    itemNumber: string,
    orderNumber: number,
    reference: string,
    description: string,
    licensePlateNumber: string
  ): Observable<ShopOrder[]> {

    const dp = new DatePipe("nl");

    let params = new HttpParams();
    params = params.append('dateFrom', dp.transform(dateFrom, "yyyy-MM-dd"));
    params = params.append('dateTo', dp.transform(dateTo, "yyyy-MM-dd"));
    if (itemNumber) { params = params.append('itemNumber', itemNumber); }
    if (orderNumber) { params = params.append('orderNumber', orderNumber.toString()); }
    if (reference) { params = params.append('reference', reference); }
    if (description) { params = params.append('description', description); }
    if (licensePlateNumber) { params = params.append('licensePlateNumber', licensePlateNumber); }

    return this.http.get<ShopOrder[]>(`${this.config.backendApi}/api/ShopOrders`, { params: params });
  }

  public getShopOrder(orderId: number): Observable<ShopOrder> {
    return this.http.get<ShopOrder>(`${this.config.backendApi}/api/ShopOrders/${orderId}`);
  }

  public getShopOrderPdf(orderId: number): Observable<string> {
    return this.http.get<string>(`${this.config.backendApi}/api/ShopOrders/Pdf/${orderId}`);
  }

  public getDeliveryNotePdf(deliveryNoteNumber: number): Observable<string> {
    return this.http.get<string>(`${this.config.backendApi}/api/DeliveryNotes/Pdf/${deliveryNoteNumber}`);
  }

  public getInvoicePdf(invoiceNumber: number): Observable<string> {
    return this.http.get<string>(`${this.config.backendApi}/api/Invoices/Pdf/${invoiceNumber}`);
  }

  public getOutstandingBills(billType: number): Observable<OutstandingBill[]> {
    const url = `${this.config.backendApi}/api/Bills?billType=${billType}`;
    return this.http.get<OutstandingBill[]>(url);
  }

  /** POST WheelCarPictureRequest to get html with car picture with rims*/
  public getWheelCarPictureHtml(carType: UniversalCarType, wheel: Wheel): Observable<string> {
    if (wheel['ImageOnCar'] && wheel['ImageOnCar'].startsWith('http')) {
      return of(`<img src="${wheel['ImageOnCar']}" style="width: 100%;" />`);
    } else {
      const request = new WheelCarPictureRequest();
      request.CarType = carType;
      request.Wheel = wheel;
      return this.http.post<string>(`${this.config.backendApi}/api/Rims/CarPicture`, request);
    }
  }

  /** POST WheelTyreSet to order and get ContextCart to update */
  public orderWheelTyreSet(cartService: CartService, wheelTyreSet: WheelTyreSet): Observable<boolean> {
    const clone = WheelTyreSet.getCloneWithoutItemInfo(wheelTyreSet);
    return this.http.post<ContextCart>(`${this.config.backendApi}/api/Rims/Order`, clone)
      .pipe(mergeMap((result: ContextCart) => {
        if (result) {
          cartService.updateContextCart(result);
          return of(true);
        }
        return of(false);
      }));
  }

  /** GET get ContextArtikelInfo */
  public getContextItemInfo(): Observable<ContextCatalog> {
    return this.http.get<ContextCatalog>(`${this.config.backendApi}/api/ItemInfo/Context`);
  }

  /** POST ArtikelInfoRequest to get ArtikelInfoResponse */
  public getItemInfoResponse(request: ItemInfoRequest): Observable<ContextCatalog> {
    if (request && !request.InternalItemNumber && !request.ItemGroup && request.ItemNumber && request.ItemNumber.startsWith('#') && !isNaN(Number(request.ItemNumber.substring(1)))) {
      request.InternalItemNumber = +request.ItemNumber.substring(1);
      request.ItemNumber = '';
    }
    return this.http.post<ContextCatalog>(`${this.config.backendApi}/api/ItemInfo`, request);
  }

  getContextFavorites(): Observable<ContextCatalog> {
    return this.http.get<ContextCatalog>(`${this.config.backendApi}/api/Favorites/Context`);
  }

  /** GET get ContextExternalFrame */
  public getContextExternalFrame(frameId: number): Observable<ContextExternalFrame> {
    return this.http.get<ContextExternalFrame>(this.config.backendApi + `/api/External/${frameId}/Context`);
  }

  /** POST carType and get CarTypeSelectionInfo for shopsoort. */
  public getCarTypeSelectionInfo(shopSoort: ShopSoort, carType: UniversalCarType): Observable<CarTypeSelectionInfo> {
    if (carType) {
      return this.http.post<CarTypeSelectionInfo>(this.config.backendApi + `/api/CarType/${shopSoort}/SelectionInfo`, carType);
    } else {
      return this.http.get<CarTypeSelectionInfo>(this.config.backendApi + `/api/CarType/${shopSoort}/SelectionInfo`);
    }
  }

  /** GET get ContextCarTypeSelection */
  public getContextCarTypeSelection(shopSoort: ShopSoort): Observable<ContextCarTypeSelection> {
    return this.http.get<ContextCarTypeSelection>(this.config.backendApi + `/api/CarType/${shopSoort}/Context`);
  }

  /** GET imageUrl for license plate */
  public getLicensePlateImageUrl(licensePlate: string): Observable<string> {
    return this.http.get<string>(this.config.backendApi + `/api/LicensePlates/ImageUrl/${licensePlate}`);
  }

  /** GET search by license plate */
  public searchByLicensePlate(shopSoort: ShopSoort, licensePlate: string): Observable<CarTypeResult> {
    return this.http.get<CarTypeResult>(this.config.backendApi + `/api/CarType/${shopSoort}/LicensePlate/${licensePlate}`);
  }

  /** GET search by vehicle identification number */
  public searchByVIN(shopSoort: ShopSoort, vin: string): Observable<CarTypeResult> {
    if (!vin) { return of(null); }
    return this.http.get<CarTypeResult>(this.config.backendApi + `/api/CarType/${shopSoort}/Vin/${this.encodeURI(vin.trim())}`);
  }

  /** POST carType to save UniversalCarType */
  public saveCarType(shopSoort: ShopSoort, carType: UniversalCarType): Observable<CarTypeResult> {
    return this.http.post<CarTypeResult>(this.config.backendApi + `/api/CarType/${shopSoort}`, carType);
  }

  /** POST universalCarBrand to get UniversalCarModels */
  public getCatalogCarModels(shopSoort: ShopSoort, carBrand: UniversalCarBrand): Observable<UniversalCarModel> {
    return this.http.post<UniversalCarModel>(this.config.backendApi + `/api/CarType/${shopSoort}/CarModels`, carBrand);
  }

  /** POST universalCarModel to get UniversalCarTypes */
  public getCatalogCarTypes(shopSoort: ShopSoort, carModel: UniversalCarModel): Observable<CarTypeResult> {
    return this.http.post<CarTypeResult>(this.config.backendApi + `/api/CarType/${shopSoort}/CarTypes`, carModel);
  }

  /** GET catalog property data */
  public getCatalogPropertyData(): Observable<CatalogPropertyData> {
    return this.http.get<CatalogPropertyData>(`${this.config.backendApi}/api/Catalog/PropertyData`);
  }

  /** GET catalog categories */
  public getCatalogCategories(carType: UniversalCarType): Observable<ContextCatalog> {
    return this.http.post<ContextCatalog>(`${this.config.backendApi}/api/Catalog/Categories`, carType)
      .pipe(mergeMap((result: ContextCatalog) => {
        if (result?.Categories && !result.MainCategories) {
          result.MainCategories = [];
          result.Categories.forEach(mainCat => {
            const mainCategory = new UniversalPartsCategory();
            mainCategory.Origin = mainCat.Origin;
            mainCategory.CategoryId = mainCat.CategoryId;
            mainCategory.CategoryName = mainCat.CategoryName;
            mainCategory.SortOrder = mainCat.SortOrder;
            mainCategory.SubPartsCategories = mainCat.PartsCategories;
            result.MainCategories.push(mainCategory);
          });
        }
        return of(result);
      }));
  }

  /** POST CatalogDataRequest with cartype and category and possibly descriptions to get catalog parts */
  public getCatalogParts(carType: UniversalCarType, category: UniversalPartsCategory, description: string): Observable<ContextCatalog> {
    const request = new CatalogDataRequest();
    request.ShopKind = ShopSoort.Catalogus;
    request.CarType = carType;
    request.Category = category;
    request.Description = description;
    return this.http.post<ContextCatalog>(this.config.backendApi + `/api/Catalog/Parts`, request);
  }

  /** POST CatalogDataRequest with cartype and partTypes to get catalog parts for maintenance */
  public getMaintenanceParts(carType: UniversalCarType, partsTypes: number[]): Observable<ContextCatalog> {
    const request = new CatalogDataRequest();
    request.ShopKind = ShopSoort.Maintenance;
    request.CarType = carType;
    request.PartsTypeIds = partsTypes;
    return this.http.post<ContextCatalog>(this.config.backendApi + `/api/Catalog/Maintenance/Parts`, request);
  }

  /** POST CatalogDataRequest with cartype and category get catalog parts and categories */
  public getCatalogPartsAndCategories(carType: UniversalCarType, category: UniversalPartsCategory): Observable<ContextCatalog> {
    const request = new CatalogDataRequest();
    request.ShopKind = ShopSoort.Sinatec;
    request.CarType = carType;
    request.Category = category;
    return this.http.post<ContextCatalog>(this.config.backendApi + `/api/Catalog/PartsAndCategories`, request);
  }

  /** POST CatalogDataRequest with cartype and category and possibly descriptions to get graphic catalog parts */
  public getCatalogGraphicParts(
    carType: UniversalCarType, category: UniversalPartsCategory, description: string): Observable<ContextGraphicParts> {
    const request = new CatalogDataRequest();
    request.ShopKind = ShopSoort.Catalogus;
    request.CarType = carType;
    request.Category = category;
    request.Description = description;
    return this.http.post<ContextGraphicParts>(this.config.backendApi + `/api/Catalog/GraphicParts`, request);
  }

  /** POST CatalogDataRequest with cartype and category and part to get catalog subparts */
  public getCatalogSubParts(carType: UniversalCarType, category: UniversalPartsCategory, part: Part): Observable<ContextCatalog> {
    const request = new CatalogDataRequest();
    request.ShopKind = ShopSoort.Catalogus;
    request.CarType = carType;
    request.Category = category;
    request.Part = part;
    return this.http.post<ContextCatalog>(`${this.config.backendApi}/api/Catalog/Parts/SubParts`, request);
  }

  /** POST CatalogDataRequest with cartype and category and part to get catalog subparts */
  public getCarTypesByPart(part: Part): Observable<CarTypeResult> {
    const request = new CatalogDataRequest();
    request.ShopKind = ShopSoort.Catalogus;
    request.Part = part;
    return this.http.post<CarTypeResult>(`${this.config.backendApi}/api/Catalog/CarTypes`, request);
  }

  /** POST CatalogDataRequest with cartype and parts to get parts cart items*/
  public getCatalogPartsCartItemsForPartsDict(request: CatalogDataRequest)
    : Observable<CartItemsResponse> {
    return this.http.post<CartItemsResponse>(`${this.config.backendApi}/api/Catalog/Parts/CartItems`, request);
  }

  /** POST CartItemsResponse to update Supplier Availability of cart items*/
  public updateSupplierAvailabilityCartItemsDictionary(request: CartItemsResponse)
    : Observable<CartItemsResponse> {
    return this.http.post<CartItemsResponse>(`${this.config.backendApi}/api/Cart/Items/Update`, request);
  }

  /** POST CatalogPartsCartItemsRequest with cartype and shopkind and parts to get parts cart items*/
  public getCatalogPartsCartItemsForPartsList(carType: UniversalCarType, shopKind: ShopSoort, parts: Part[], withoutSupplierAvailability: boolean, timing: TimingObject)
    : Observable<CartItemsResponse> {
    const request = new CatalogDataRequest();
    request.ShopKind = shopKind;
    request.WithoutSupplierAvailability = withoutSupplierAvailability;
    request.CarType = carType;
    request.PartsList = parts;
    request.Timing = timing;
    return this.http.post<CartItemsResponse>(`${this.config.backendApi}/api/Catalog/Parts/CartItems`, request);
  }

  /** GET typeahead items with search */
  public getTypeaheadItemNumber(search: string, maxItems = 10, searchInDescription = false, uid: UserIdentificationModel = null): Observable<TypeaheadItem[]> {
    if (search) {
      const searchString = btoa(search.trim());
      if (searchInDescription) {
        // Merge zonder en met zoeken in omschrijving, omdat zonder een stuk sneller is als met.
        // Zo zie je al we direct het antwoord voor de matchende artikelnummers en iets later de matchende omschrijving erbij.
        // Filter lege resultaten, zodat de typeahead tussentijds niet leeg wordt als er bijvoorbeeld niets op artikelnummer wordt gevonden.
        return merge(this.http.get<TypeaheadItem[]>(this.addUid(uid, `${this.config.backendApi}/api/TypeAhead/ItemNumber/${searchString}?maxItems=${maxItems}&searchInDescription=False`))
          .pipe(filter(x => x && x.length > 0)),
          this.http.get<TypeaheadItem[]>(this.addUid(uid, `${this.config.backendApi}/api/TypeAhead/ItemNumber/${searchString}?maxItems=${maxItems}&searchInDescription=True`))
            .pipe(filter(x => x && x.length > 0)));
      } else {
        return this.http.get<TypeaheadItem[]>(this.addUid(uid, `${this.config.backendApi}/api/TypeAhead/ItemNumber/${searchString}?maxItems=${maxItems}`));
      }
    }
    return of([]);
  }

  /** GET typeahead itemgroup with search */
  public getTypeaheadItemGroup(search: string, uid: UserIdentificationModel = null): Observable<ItemGroup[]> {
    if (!search) { return of([]); }
    return this.http.get<ItemGroup[]>(this.addUid(uid, `${this.config.backendApi}/api/TypeAhead/ItemGroup/${btoa(search.trim())}`));
  }

  /** GET typeahead customer with search */
  public getTypeaheadCustomer(search: string, uid: UserIdentificationModel = null): Observable<CustomerModel[]> {
    if (!search) { return of([]); }
    return this.http.get<CustomerModel[]>(this.addUid(uid, `${this.config.backendApi}/api/TypeAhead/Customer/${btoa(search.trim())}`));
  }

  /** GET typeahead supplier with search */
  public getTypeaheadSupplier(search: string, uid: UserIdentificationModel = null): Observable<SupplierModel[]> {
    if (!search) { return of([]); }
    return this.http.get<SupplierModel[]>(this.addUid(uid, `${this.config.backendApi}/api/TypeAhead/Supplier/${btoa(search.trim())}`));
  }

  /** GET typeahead shoporder with search and optional customer */
  public getTypeaheadShopOrder(searchString: string, onlyLoyaltyShopOrders: boolean = false, customer: number = 0, maxItems: number = 10, uid: UserIdentificationModel = null): Observable<ShopOrder[]> {
    const search = "/" + btoa(searchString.trim());
    return this.http.get<ShopOrder[]>(this.addUid(uid, `${this.config.backendApi}/api/TypeAhead/ShopOrder${search}?customer=${customer}&maxItems=${maxItems}${onlyLoyaltyShopOrders ? '&onlyLoyaltyShopOrders=true' : ''}`));
  }

  /** POST settings to save direct*/
  public saveSettingsDirect(settings: Settings) {
    const request = { 'Settings': settings, 'WriteToDatabase': true };
    this.http.post<{ [key: string]: string }>(`${this.config.backendApi}/api/Settings`, request)
      .subscribe((result: { [key: string]: string }) => console.info('saveSettings new backend ->', result));
  }

  /** POST settings to save with a timeout*/
  public saveSettings(settings: Settings) {
    const key = Object.keys(settings).join('_');
    if (this.saveSettingTimeoutHandles[key]) { window.clearTimeout(this.saveSettingTimeoutHandles[key]); }
    this.saveSettingTimeoutHandles[key] = window.setTimeout(
      (): void => {
        this.saveSettingTimeoutHandles[key] = null;
        this.saveSettingsDirect(settings);
      }
      , 2000);
  }

  public getLicensePlateTypes(request: LicensePlateTypeRequest): Observable<LicensePlateType[]> {
    let query = '';
    if (request.onlyTrailerPlates) query = '?onlyTrailerPlates=true';
    return this.http.get<LicensePlateType[]>(`${this.config.backendApi}/api/LicensePlates/Types${query}`);
  }

  public orderLicensePlate(request: LicensePlateRequest): Observable<LicensePlateResponse> {
    return this.http.post<LicensePlateResponse>(`${this.config.backendApi}/api/LicensePlates/Order`, request);
  }

  public getLicensePlateCustomerSettings(): Observable<LicensePlateCustomerSettings> {
    return this.http.get<LicensePlateCustomerSettings>(`${this.config.backendApi}/api/LicensePlates/Settings`);
  }

  public orderPaint(request: PaintRequest): Observable<ShoppingCartItem> {
    return this.http.put<ShoppingCartItem>(`${this.config.backendApi}/api/Paint`, request);
  }

  public sendPickupRequest(request: PickupRequest): Observable<ShopPortalOrderResponse> {
    return this.http.put<ShopPortalOrderResponse>(`${this.config.backendApi}/api/Pickup/Request`, request);
  }

  public getReturns(): Observable<ReturnHeader[]> {
    return this.http.get<ReturnHeader[]>(`${this.config.backendApi}/api/Returns`);
  }

  public getReturn(returnId: number): Observable<Return> {
    return this.http.get<Return>(this.config.backendApi + `/api/Returns/${returnId}`);
  }

  public getCurrentReturn(): Observable<Return> {
    return this.http.get<Return>(this.config.backendApi + `/api/Returns/Current`);
  }

  public postReturnDetail(returnId: number, detail: ReturnDetail): Observable<Object> {
    return this.http.post(this.config.backendApi + `/api/Returns/${returnId}/Details`, detail);
  }

  public deleteReturnDetail(returnId: number, detail: ReturnDetail): Observable<Object> {
    return this.http.delete(this.config.backendApi + `/api/Returns/${returnId}/Details/${detail.LineNumber}`);
  }

  public sendReturn(returnId: number): Observable<ReturnOrder> {
    return this.http.post<ReturnOrder>(this.config.backendApi + `/api/Returns/${returnId}/Send`, "");
  }

  public getDeliveryNoteItems(internalItemNumber: number, itemCount: number): Observable<DeliveryNoteItem[]> {
    return this.http.get<DeliveryNoteItem[]>(this.config.backendApi + `/api/DeliveryNotes/${internalItemNumber}/${itemCount}`);
  }

  public saveReturn(returnItem: ReturnHeader): Observable<Object> {
    return this.http.post(this.config.backendApi + `/api/Returns`, returnItem);
  }

}
