import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { SettingsBase } from '../../_models/admin/settings/settings.base';
import { UserIdentificationModel } from '../../_models/user.identification.model';
import { AdminSettingsService } from '../../_services/admin/admin-settings.service';
import { AdminService } from '../../_services/admin/admin.service';

@Injectable({
  providedIn: 'root'
})
export class AdminSettingsDataResolverService  {

  constructor(
    private adminSettingsService: AdminSettingsService,
    private adminService: AdminService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SettingsBase> | Observable<never> {
    const uid = new UserIdentificationModel();
    uid.Wholesaler = route.paramMap.get('wholesaler') !== null
      ? +route.paramMap.get('wholesaler')
      : this.adminService.currentUid.Wholesaler;
    uid.Branch = +route.paramMap.get('branch');
    uid.Customer = +route.paramMap.get('customer');
    uid.UserID = +route.paramMap.get('userid');

    return this.adminSettingsService.getSettingsForRoute(route.routeConfig.path, uid).pipe(
      take(1),
      mergeMap(settings => {
        if (settings) {
          return of(settings);
        } else { // no data?
          this.router.navigate(['/admin']);
          return EMPTY;
        }
      })
    );
  }


}
