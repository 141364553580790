import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvailabilityCode } from '../../../../../_models/admin/supplier-interface/availability.code';
import { SupplierResponse } from '../../../../../_models/admin/supplier-interface/supplier.response';
import { SupplierResponseItem } from '../../../../../_models/admin/supplier-interface/supplier.response.item';
import { SupplierResponseItemDepot } from '../../../../../_models/admin/supplier-interface/supplier.response.item.depot';

@Component({
  selector: 'app-supplier-interface-response',
  templateUrl: './supplier-interface-response.component.html',
  styleUrls: ['./supplier-interface-response.component.scss']
})
export class SupplierInterfaceResponseComponent implements OnInit {
  availabilityCode = AvailabilityCode;
  @Input() response: SupplierResponse
  @Output() searchAction: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  getProperties(item: SupplierResponseItem): string[] {
    if (item.Properties) {
      const properties = Object.keys(item.Properties);
      return properties.filter(p => p != 'SupplierId');
    }
    return null;
  }

  getFirstDepot(item: SupplierResponseItem): SupplierResponseItemDepot {
    if (item?.Depots?.length) return item.Depots[0];
    return new SupplierResponseItemDepot();
  }

  getOtherDepots(item: SupplierResponseItem): SupplierResponseItemDepot[] {
    if (item?.Depots?.length > 1) return item.Depots.slice(1);
    return null;
  }

  isDateOk(date: Date | null): boolean {
    if (date && date.getFullYear() < 2999) return true;
    return false;
  }

}
