<app-admin-custom-control *ngIf="!searching" [label]="label" [labelCols]="labelCols" [dividerKind]="dividerKind" [centerLabel]="centerLabel">
  <button type="button" class="btn btn-light border border-light-subtle text-start w-100" (click)="doSearch()" [disabled]="disabled">
    <span *ngIf="noneSelectionLabel && (model===noneSelectionModelValue || !modelObject)">{{noneSelectionLabel}}</span>
    <span *ngIf="modelObject"><b>{{modelObject.Name}}</b> <span class="ms-3 text-low">({{modelObject.CustomerNumber}})</span>
        <span *ngIf="noneSelectionLabel" (click)="clear($event)" class="ms-3"><i class="fas fa-trash-alt"></i></span>
        <br />{{modelObject.City}}</span>
  </button>
  <ng-content></ng-content>
</app-admin-custom-control>
<app-admin-typeahead-customer-search *ngIf="searching" [label]="label" [labelCols]="labelCols" [disabled]="disabled"
  [wholesaler]="adminService.currentUid.Wholesaler" [focusInput]="focusInput" (selected)="objectChange($event)"
  [placeholder]="placeholder" [dividerKind]="dividerKind" (blur)="typeaheadBlur($event)" [centerLabel]="centerLabel">
    <button *ngIf="noneSelectionLabel" class="btn btn-outline-secondary" type="button" (click)="typeaheadBlur($event)"><i
        class="fas fa-times"></i></button>
</app-admin-typeahead-customer-search>
