import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { filter, take, mergeMap } from 'rxjs/operators';
import { ItemImagesItemNumberData } from '../../_models/admin/item-images/item.images.item.number.data';
import { SupplierInterfaceData } from '../../_models/admin/supplier-interface/supplier.interface.data';
import { AdminItemImagesService } from '../../_services/admin/admin-item-images.service';
import { AdminSupplierInterfaceService } from '../../_services/admin/admin-supplier-interface.service';

@Injectable({
  providedIn: 'root'
})
export class AdminSupplierInterfaceResolverService   {

  constructor(
    private router: Router,
    private adminSupplierInterfaceService: AdminSupplierInterfaceService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SupplierInterfaceData> | Observable<never> {
    
    return this.adminSupplierInterfaceService.getSupplierInterfaceData().pipe(
      take(1),
      mergeMap(itemImagesData => {
        if (itemImagesData) {
          return of(itemImagesData);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
