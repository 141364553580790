import { Injectable } from '@angular/core';
import { Observable, of, Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { BsModalService } from 'ngx-bootstrap/modal';

import { MainService } from '../main.service';
import { ApiService } from '../api.service';
import { ContextAdmin } from '../../_models/admin/context.admin';
import { TimingObject } from '../../_models/common/timing.object';
import { PortalStatisticsToday } from '../../_models/admin/statistics/portal.statistics.today';
import { DatePeriod } from '../../_models/admin/statistics/date.period';
import { BarChart } from '../../_models/admin/statistics/bar.chart';
import { SelectionItem } from '../../_models/rims-and-tyres/selection.item';
import { EditSelectionItemComponent } from '../../admin/admin-settings/admin-settings-rims-and-tyres/edit-selection-item/edit-selection-item.component';
import { DeliveryChoice } from '../../_models/cart/delivery.choice';
import { EditDeliveryItemComponent } from '../../admin/admin-settings/admin-settings-cart/edit-delivery-item/edit-delivery-item.component';
import { SortOrderUpdate } from '../../_models/admin/sort.order.update';
import { BranchModel } from '../../_models/common/branch.model';
import { CustomerModel } from '../../_models/common/customer.model';
import { OnlineSessions } from '../../_models/admin/statistics/online.sessions';
import { ShopSoort } from '../../_models/common/shop.soort';
import { SupplierModel } from '../../_models/common/supplier.model';
import { WholesalerModel } from '../../_models/common/wholesaler.model';
import { ToasterService } from '../toaster.service';
import { TypeaheadItem } from '../../_models/typeahead/typeahead.item';
import { UserIdentificationModel } from '../../_models/user.identification.model';
import { AdminSettingsSelectorPopupComponent } from '../../_common/admin/admin-settings-selector/admin-settings-selector-popup/admin-settings-selector-popup.component';
import { AdminNameService } from './admin-name.service';
import { ShopOrder } from '../../_models/shoporders/shop.order';
import { CreditsInfo } from '../../_models/loyalty-shop/credits.info';
import { ItemGroup } from '../../_models/common/item.group';
import { AdjustLoyaltyShopCreditsRequest } from '../../_models/loyalty-shop/adjust.loyalty.shop.credits.request';
import { AdjustLoyaltyShopCreditsResponse } from '../../_models/loyalty-shop/adjust.loyalty.shop.credits.response';
import { AdminSupplierInterfaceInfoComponent } from '../../admin/super-admin/admin-supplier-interfaces/admin-supplier-interface-info/admin-supplier-interface-info.component';
import { SupplierInterfaceResponse } from '../../_models/admin/supplier-interface/supplier.interface.response';
import { ResponseItem } from '../../_models/item-info/response.item';
import { ItemInfoResponse } from '../../_models/item-info/item.info.response';
import { ArticleSourceRecord } from '../../_models/common/article-source/article.source.record';
import { ArticleSourceSearchRequest } from '../../_models/common/article-source/article.source.search.request';
import { SettingModel } from '../../_models/common/setting.model';
import { VoorraadTemplateRendered } from '../../_models/item-info/voorraad.template.rendered';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private heartbeatSubscription: Subscription;
  private heartbeatCount = 0;
  ctx: ContextAdmin;
  superAdmin: boolean;
  currentUid: UserIdentificationModel = new UserIdentificationModel();
  currentWholesalerName: string;
  badges: { [key: string]: number };
  sessionsCache: { data: OnlineSessions, timeStamp: number };
  statsTodayCache: { data: PortalStatisticsToday, timeStamp: number };
  statsCache: { data: BarChart, datePeriod: DatePeriod, period: string, timeStamp: number };

  constructor(
    private mainService: MainService,
    private apiService: ApiService,
    private toasterService: ToasterService,
    private adminNameService: AdminNameService,
    private router: Router,
    private modalService: BsModalService
  ) {
  }

  clear() {
    this.ctx = null;
    this.currentUid.Wholesaler = 0;
    this.superAdmin = false;
    this.badges = {};
    this.sessionsCache = null;
    this.statsTodayCache = null;
    this.statsCache = null;
    this.heartbeatCount = 0;
    this.stopHeartbeat();
  }

  getBadge(badge: string): number {
    switch (badge) {
      default:
        return this.badges[badge];

    }
  }

  setBadge(badge: string, value: number): void {
    if (this.badges) { this.badges[badge] = value; }
  }

  getContext(showProgress?: boolean): Observable<ContextAdmin> {
    if (this.ctx) {
      return of(this.ctx);
    } else {
      const cb = this.mainService.callbackInfoBox('Eén moment geduld...', 'Admin module wordt ingeladen', '', !showProgress);
      return this.apiService.adminGetContextAdmin(this.currentUid)
        .pipe(mergeMap(ctx => {
          this.ctx = ctx;
          this.currentUid.Wholesaler = ctx.Wholesaler;
          this.currentWholesalerName = ctx.CompanyName;
          this.superAdmin = (ctx && ctx.AdminAccesslevel > 15);
          this.badges = ctx.Badges;
          this.adminNameService.setWholesaler(ctx.Wholesaler, ctx.CompanyName);
          if (ctx.Wholesalers && ctx.Wholesalers.length > 0) {
            ctx.Wholesalers.forEach(wholesaler => {
              this.adminNameService.setWholesaler(wholesaler.ID, wholesaler.ShortName);
            });
          }
          this.startHeartbeat();
          cb.complete();
          return of(ctx);
        }));
    }
  }

  updateBadges() {
    this.apiService.adminGetBadges(this.currentUid.Wholesaler).subscribe(badges => this.badges = badges);
  }

  private startHeartbeat(): void {
    this.stopHeartbeat();
    console.info(`startHeartbeat Admin: beat every 60000 ms`);
    this.heartbeatSubscription = timer((5) * 1000, 60000)
      .subscribe(() => {
        this.doHeartbeat();
      });
  }

  private stopHeartbeat(): void {
    if (this.heartbeatSubscription) { this.heartbeatSubscription.unsubscribe(); }
  }

  private doHeartbeat(): void {
    this.apiService.updateHeartbeat(ShopSoort.ONBEKEND, 'SPAD')
      .subscribe(ok => {
        console.log(`Heartbeat Admin update: ${++this.heartbeatCount}`);
      });
  }

  settingsSelectorPopup(uid: UserIdentificationModel): Observable<UserIdentificationModel> {
    const initialState = {
      uid: uid
    };
    const modalRef = this.modalService.show(AdminSettingsSelectorPopupComponent, { initialState });
    return modalRef.content.onClose;
  }

  setCurrentWholesaler(wholesaler: WholesalerModel) {
    this.currentUid.Wholesaler = wholesaler.ID;
    this.currentWholesalerName = wholesaler.ShortName;
    this.updateBadges();
    this.router.navigate(['/admin']);
  }

  searchItemNumber(searchString: string, searchInDescription: boolean): Observable<TypeaheadItem[]> {
    return this.apiService.getTypeaheadItemNumber(searchString, 10, searchInDescription, this.currentUid);
  }

  searchItemGroup(searchString: string): Observable<ItemGroup[]> {
    return this.apiService.getTypeaheadItemGroup(searchString, this.currentUid);
  }

  getItemNumber(wholesaler: number, internalItemNumber: number): Observable<ResponseItem> {
    return this.apiService.adminGetItemNumbers(wholesaler, [internalItemNumber])
      .pipe(mergeMap(items => {
        if (items?.length) { return of(items[0]); }
        return of(null);
      }));
  }

  getRenderedAvailabilityTemplateParts(branch: number, customer: number, internalItemNumber: number, itemCount: number, templatePartIds: number[]): Observable<{ [key: number]: VoorraadTemplateRendered }> {
    return this.apiService.adminGetRenderedAvailabilityTemplateParts(this.currentUid, branch, customer, internalItemNumber, itemCount, templatePartIds);
  }

  getItemNumbers(wholesaler: number, internalItemNumbers: number[]): Observable<ResponseItem[]> {
    return this.apiService.adminGetItemNumbers(wholesaler, internalItemNumbers);
  }

  getItemGroup(wholesaler: number, itemGroup: number): Observable<ItemGroup> {
    return this.apiService.adminGetItemGroups(wholesaler, [itemGroup])
      .pipe(mergeMap(groups => of(groups[0])));
  }

  getItemGroups(wholesaler: number, itemGroups: number[] = null): Observable<ItemGroup[]> {
    return this.apiService.adminGetItemGroups(wholesaler, itemGroups);
  }

  getBranches(wholesaler: number): Observable<BranchModel[]> {
    return this.apiService.adminGetBranches(wholesaler)
      .pipe(mergeMap(branches => {
        if (branches && branches.length) {
          branches.forEach(branch => {
            this.adminNameService.setBranch(wholesaler, branch.BranchNumber, branch.BranchName);
          });
        }
        return of(branches);
      }));
  }

  searchCustomer(searchString: string, wholesaler: number = 0): Observable<CustomerModel[]> {
    const uid = new UserIdentificationModel();
    if (wholesaler !== 0) uid.Wholesaler = wholesaler;
    else uid.Wholesaler = this.currentUid.Wholesaler;
    return this.apiService.getTypeaheadCustomer(searchString, uid);
  }

  getCustomer(wholesaler: number, customerNumber: number, withUsers: boolean = false): Observable<CustomerModel> {
    return this.apiService.adminGetCustomer(wholesaler, customerNumber, withUsers)
      .pipe(mergeMap(customer => {
        if (customer) {
          this.adminNameService.setCustomer(wholesaler, customer.BranchNumber, customer.CustomerNumber, customer.Name);
          if (customer.Users && customer.Users.length) {
            customer.Users.forEach(user => {
              this.adminNameService.setUser(wholesaler, customer.BranchNumber, customer.CustomerNumber, user.UserID, user.Username);
            });
          }
        }
        return of(customer);
      }));
  }

  searchSupplier(searchString: string): Observable<SupplierModel[]> {
    return this.apiService.getTypeaheadSupplier(searchString, this.currentUid);
  }

  getSupplier(wholesaler: number, supplierNumber: number): Observable<SupplierModel> {
    return this.apiService.adminGetSupplier(wholesaler, supplierNumber);
  }

  searchShopOrder(searchString: string, customer: number = 0, onlyLoyaltyShopOrders: boolean = false): Observable<ShopOrder[]> {
    return this.apiService.getTypeaheadShopOrder(searchString, onlyLoyaltyShopOrders, customer, 10, this.currentUid);
  }

  getLastShopOrders(customer: number = 0, maxItems: number = 10, onlyLoyaltyShopOrders: boolean = false): Observable<ShopOrder[]> {
    return this.apiService.getTypeaheadShopOrder('', onlyLoyaltyShopOrders, customer, maxItems, this.currentUid);
  }

  adjustLoyaltyShopCredits(request: AdjustLoyaltyShopCreditsRequest): Observable<AdjustLoyaltyShopCreditsResponse> {
    request.User = this.ctx.Name;
    return this.apiService.adminAdjustLoyaltyShopCredits(this.currentUid, request);
  }

  getShopOrder(wholesaler: number, orderId: number): Observable<ShopOrder> {
    return this.apiService.adminGetShopOrder(wholesaler, orderId);
  }

  reloadSettings(backendUrls: string[]): void {
    this.apiService.adminReloadSettings(backendUrls)
      .subscribe((ok: boolean) => {
        this.mainService.msgBox('Instellingen herladen', `De instellingen zijn${!ok ? ' NIET ' : ' '}herladen!`);
      });
  }

  flushCatalogHelper(backendUrls: string[]): void {
    this.apiService.adminReloadCatalogHelper(backendUrls)
      .subscribe((timing: TimingObject) => {
        this.mainService.showTimingDialog(timing);
      });
  }

  convertShopModuleSettings(): Observable<TimingObject> {
    return this.apiService.adminConvertShopModuleSettings();
  }

  learnCatalog(): void {

  }

  getSettings(): Observable<{ [key: string]: { [key: number]: { [key: number]: { [key: number]: { [key: number]: { [key: number]: { [key: string]: SettingModel } } } } } } }> {
    return this.apiService.adminGetSettings();
  }

  updateOnlineSessionCount(): void {
    this.apiService.adminGetOnlineSessionCount()
      .subscribe(count => this.setBadge('onlineCount', count));
  }

  getOnlineSessions(maxSeconds: number): Observable<OnlineSessions> {
    if (this.sessionsCache && (Date.now() - this.sessionsCache.timeStamp) < 1000 * maxSeconds) {
      return of(this.sessionsCache.data);
    } else {
      return this.apiService.adminGetOnlineSessions()
        .pipe(mergeMap(sessions => {
          this.sessionsCache = { data: sessions, timeStamp: Date.now() };
          this.setBadge('onlineCount', sessions.Totals.Count);
          return of(sessions);
        }));
    }
  }

  getStatisticsToday(maxMinutes: number): Observable<PortalStatisticsToday> {
    if (this.statsTodayCache && (Date.now() - this.statsTodayCache.timeStamp) < 1000 * 60 * maxMinutes) {
      return of(this.statsTodayCache.data);
    } else {
      return this.apiService.adminGetStatisticsToday()
        .pipe(mergeMap(stats => {
          this.statsTodayCache = { data: stats, timeStamp: Date.now() };
          return of(stats);
        }));
    }
  }

  getStatistics(datePeriod: DatePeriod, period: string, maxHours: number): Observable<BarChart> {
    if (this.statsCache
      && datePeriod.equals(this.statsCache.datePeriod)
      && period === this.statsCache.period
      && (Date.now() - this.statsCache.timeStamp) < 1000 * 60 * 60 * maxHours) {
      return of(this.statsCache.data);
    } else {
      return this.apiService.adminGetStatistics(datePeriod, period)
        .pipe(mergeMap(stats => {
          this.statsCache = { data: stats, datePeriod: datePeriod, period: period, timeStamp: Date.now() };
          return of(stats);
        }));
    }
  }

  getLoyaltyShopCreditsInfo(): Observable<CreditsInfo[]> {
    return this.apiService.adminGetLoyaltyShopCreditsInfo(this.currentUid);
  }

  getLoyaltyShopCreditsInfoCustomer(customer: number): Observable<CreditsInfo> {
    return this.apiService.adminGetLoyaltyShopCreditsInfoCustomer(this.currentUid, customer);
  }

  handleResult(ok: boolean, route: string[], errorMessage = 'Er is iets mis gegaan bij het opslaan!'): boolean {
    if (ok) {
      this.toasterService.showToast('Succes', 'De gegevens zijn opgeslagen!');
      if (route) this.router.navigate(route);
    } else {
      this.mainService.msgBox('Let op!', errorMessage);
    }
    return ok;
  }

  handleObservableResult(obsResult: Observable<any>, route = ['/admin'], errorMessage = 'Er is iets mis gegaan bij het opslaan!')
    : Observable<boolean> {
    return obsResult.pipe(mergeMap(result => of(this.handleResult(result !== null, route, errorMessage))));
  }

  editSelectionItem(items: SelectionItem[], item: SelectionItem): void {
    if (!item) {
      const sorted = this.mainService.getArraySortedOnPropertyWithProperySet(items, 'Properties.SortOrder');
      item = new SelectionItem();
      if (sorted.length > 0) {
        item.Properties['SortOrder'] = (sorted[sorted.length - 1].Properties['SortOrder'] + 1) * -1;
      } else {
        item.Properties['SortOrder'] = -1;
      }
    }
    const initialState = {
      item: JSON.parse(JSON.stringify(item)),
      mainService: this.mainService
    };
    const modalRef = this.modalService.show(EditSelectionItemComponent, { initialState, class: 'modal-md' });
    modalRef.content.onClose
      .subscribe(editedItem => {
        if (editedItem.Properties['SortOrder'] < 0) {
          editedItem.Properties['SortOrder'] *= -1;
          items.push(editedItem);
        } else {
          Object.keys(editedItem).forEach(key => item[key] = editedItem[key]);
        }
      });
  }

  deleteSelectionItem(items: SelectionItem[], item: SelectionItem): void {
    const index = items.indexOf(item, 0);
    if (index > -1) {
      items.splice(index, 1);
    }
  }

  editDeliveryChoice(choices: DeliveryChoice[], choice: DeliveryChoice): void {
    if (!choice) {
      const sorted = this.mainService.getArraySortedOnPropertyWithProperySet(choices, 'SortOrder');
      choice = new DeliveryChoice();
      if (sorted.length > 0) {
        choice.SortOrder = (sorted[sorted.length - 1].SortOrder + 1) * -1;
      } else {
        choice.SortOrder = -1;
      }
    }
    const initialState = {
      choice: JSON.parse(JSON.stringify(choice)),
      mainService: this.mainService
    };
    const modalRef = this.modalService.show(EditDeliveryItemComponent, { initialState, class: 'modal-md' });
    modalRef.content.onClose
      .subscribe(editedChoice => {
        if (editedChoice.SortOrder < 0) {
          editedChoice.SortOrder *= -1;
          choices.push(editedChoice);
        } else {
          Object.keys(editedChoice).forEach(key => choice[key] = editedChoice[key]);
        }
      });
  }

  deleteDeliveryChoice(choices: DeliveryChoice[], choice: DeliveryChoice): void {
    const index = choices.indexOf(choice, 0);
    if (index > -1) {
      choices.splice(index, 1);
    }
  }

  getSortOrderUpdate(data: any[], idField: string, sortOrderField: string): SortOrderUpdate {
    const update = new SortOrderUpdate();
    data.forEach(item => {
      update.Sort[item[idField]] = item[sortOrderField];
    });
    return update;
  }

  supplierInterfaceInfoPopup(interfaceKind: number, interfaceKindName: string): Observable<boolean> {
    const initialState = {
      interfaceKind: interfaceKind,
      interfaceKindName: interfaceKindName
    };
    const modalRef = this.modalService.show(AdminSupplierInterfaceInfoComponent, { initialState, class: 'modal-xxl' });
    return modalRef.content.onClose;
  }

  supplierInterfaceDoInfo(interfaceKind: number, itemNumber: string): Observable<SupplierInterfaceResponse> {
    return this.apiService.adminSupplierInterfaceDoInfo(this.currentUid, interfaceKind, itemNumber);
  }

  // itemInfoDoInfo(itemNumber: string, customer: number): Observable<ItemInfoResponse> {
  //   return this.apiService.adminItemInfoDoInfo(this.currentUid, itemNumber, customer)
  //     .pipe(mergeMap(result => {
  //       if (result?.ItemResults) {
  //         this.apiService.adminUpdateSupplierAvailabilityItemInfo(this.currentUid, result, customer)
  //           .subscribe(updated => {
  //             if (updated) {

  //               // this.zone.run(() => {
  //                 result.ItemResults = updated.ItemResults;
  //                 result.Suppliers = updated.Suppliers;
  //                 result.Timing = updated.Timing;  
  //               // });
  //               this.ref.markForCheck();
  //             }
  //           });
  //       }
  //       return of(result);
  //     }));
  // }

  articleSourceSearch(request: ArticleSourceSearchRequest): Observable<ArticleSourceRecord[]> {
    return this.apiService.adminArticleSourceSearch(this.currentUid, request);
  }

}
