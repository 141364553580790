<app-admin-form [title]="'EasyCloud StockInfo leveranciers'" [object]="settings">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Koppeling</th>
        <th>Filialen</th>
        <th>Leverancier</th>
        <th class="text-end">Aktie</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let link of settings.StockInfoSuppliersLink | keyvalue">
        <td class="ps-3">{{link.key}}</td>
        <td>
          <app-admin-dropdown [dividerKind]="-1" [labelCols]="0" [(model)]="link.value.Branches" [modelValues]="getBranchNumbers()" [modelDisplayValues]="settings.Branches" [modelEmptyDisplayValue]="'Alle filialen'" [multiSelect]="true" [emptyIsAllSelected]="true" [multiSelectSeparator]="'<br />'"></app-admin-dropdown>
        </td>
        <td>
          <app-admin-supplier-input [labelCols]="0" [model]="link.value.Supplier" (modelChange)="changeLink(link.key, $event)" [dividerKind]="-1" [stopSearchingOnBlur]="true">
          </app-admin-supplier-input>
        </td>
        <td class="text-end">
          <button class="btn btn-xs btn-outline-secondary" (click)="removeLink(link.key)"><i class="fas fa-trash-alt"></i>
            Verwijder</button>
        </td>
      </tr>
      <tr>
        <td class="text-end" colspan="7">
          <div class="btn-group">
            <button class="btn-primary btn btn-xs" (click)="addLink()"><i class="fa fa-plus"></i> Toevoegen</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsAvailabilitySuppliers(settings)">
    <button type="button" class="btn btn-info ms-2" (click)="adminService.supplierInterfaceInfoPopup(22, 'EasyCloud StockInfo')">
      <i class="far fa-file-search"></i> Artikel info</button>
  </app-admin-cancel-save-button>
</app-admin-form>