<div class="modal-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col" (contextmenu)="mainService.showDebugInfo($event, response?.Timing, response?.Response)">
        <h4>Artikel Info {{interfaceKindName}}</h4>
      </div>
    </div>
  </div>
</div>
<div class="modal-body" [ngStyle]="{'max-height': (mainService.resizeInfo.clientHeight - 200) + 'px', 'overflow': 'auto'}">
  <app-admin-input [label]="'Artikelnummer'" [(model)]="itemNumber" [focusInput]="focusInput" [toUppercase]="true" (keyup.enter)="doInfo($event)">
    <button class="btn btn-outline-secondary" type="button" (click)="clear()"><i class="fas fa-times"></i></button>
  </app-admin-input>
  <app-supplier-interface-response *ngIf="response" [response]="response.Response"></app-supplier-interface-response>
  
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning ms-3 me-2" (click)="decline($event)"><i class="fas fa-undo"></i>
    Annuleren</button>
  <button type="button" class="btn btn-info" (click)="doInfo($event)">
    <span *ngIf="!busy" class="icon-width"><i class="far fa-file-search"></i></span>
    <span *ngIf="busy" class="icon-width"><i class="fas fa-sync fa-spin"></i></span>
    Artikel info</button>
</div>