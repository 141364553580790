import './_extensions/object-prototypes';
import './_extensions/array-prototypes';

import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';

import { CallbackInfoComponent } from './_common/callback-info/callback-info.component';
// tslint:disable-next-line:max-line-length
import { CatalogPartsDescriptionPartsComponent } from './_common/catalog/catalog-parts-description-parts/catalog-parts-description-parts.component';
import { ChooseResponseItemComponent } from './_common/choose-response-item/choose-response-item.component';
import { ConfirmPopupComponent } from './_common/confirm-popup/confirm-popup.component';
import { DisplayOptiesComponent } from './_common/header-panel/display-opties/display-opties.component';
import { HeaderPanelComponent } from './_common/header-panel/header-panel.component';
// tslint:disable-next-line:max-line-length
import { VoorraadPrijsDisplayOptiesComponent } from './_common/header-panel/voorraad-prijs-display-opties/voorraad-prijs-display-opties.component';
import { VoorraadPrijsOptiesComponent } from './_common/header-panel/voorraad-prijs-opties/voorraad-prijs-opties.component';
import { ItemCountSmallComponent } from './_common/item-count-small/item-count-small.component';
import { ItemCountComponent } from './_common/item-count/item-count.component';
import { ItemInfoTemplateComponent } from './_common/item-info-template/item-info-template.component';
import { MessagePopupComponent } from './_common/message-popup/message-popup.component';
import { MessageComponent } from './_common/message/message.component';
import { ShowObjectComponent } from './_common/show-object/show-object.component';
import { ShowTimingsComponent } from './_common/show-timings/show-timings.component';
import { AutofocusDirective } from './_directives/autofocus.directive';
import { BandenMaatDirective } from './_directives/banden-maat.directive';
import { LicensePlateDirective } from './_directives/license-plate.directive';
import { AuthGuard } from './_guards/auth.guard';
import { CarTypeGuard } from './_guards/car-type.guard';
import { AppBootstrapModule } from './_helpers/app-bootstrap.module';
import { CustomHttpInterceptor } from './_helpers/custom.http.interceptor';
import { FilterCountPipe } from './_pipes/filter-count.pipe';
import { PricePipe } from './_pipes/price.pipe';
import { StockFilterPipe } from './_pipes/stock-filter.pipe';
import { ApiService } from './_services/api.service';
import { AuthService } from './_services/auth.service';
import { CarTypeService } from './_services/car-type.service';
import { CartService } from './_services/cart.service';
import { CatalogService } from './_services/catalog.service';
import { MainService } from './_services/main.service';
import { MessageService } from './_services/message.service';
import { ShopService } from './_services/shop.service';
import { AppRoutingModule, appRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarBrandListComponent } from './car-type-selection/car-brand-list/car-brand-list.component';
import { CarHistoryListComponent } from './car-type-selection/car-history-list/car-history-list.component';
import { CarModelListComponent } from './car-type-selection/car-model-list/car-model-list.component';
import { CarTypeListComponent } from './car-type-selection/car-type-list/car-type-list.component';
import { CarTypeSelectionComponent } from './car-type-selection/car-type-selection.component';
import { CartComponent } from './cart/cart.component';
import { SideCartComponent } from './cart/side-cart/side-cart.component';
import { HeaderDefaultComponent } from './header/header-default/header-default.component';
import { HeaderDoetamComponent } from './header/header-doetam/header-doetam.component';
import { HeaderProPartsComponent } from './header/header-pro-parts/header-pro-parts.component';
import { HeaderComponent } from './header/header.component';
import { ChooseCookieComponent } from './login/choose-cookie/choose-cookie.component';
import { ChooseVersionComponent } from './login/choose-version/choose-version.component';
import { LoginDefaultComponent } from './login/login-default/login-default.component';
import { LoginDoetamComponent } from './login/login-doetam/login-doetam.component';
import { LoginHoflandComponent } from './login/login-hofland/login-hofland.component';
import { LoginComponent } from './login/login.component';
import { CatalogGroupsComponent } from './portal/catalog/catalog-groups/catalog-groups.component';
import { PartsCategoryFilterPipe } from './_pipes/catalog/parts-category-filter.pipe';
import { CatalogPartDetailedComponent } from './_common/catalog/catalog-part-detailed/catalog-part-detailed.component';
import { CatalogPartsCardViewComponent } from './_common/catalog/catalog-parts-card-view/catalog-parts-card-view.component';
import { CatalogPartsCompactListComponent } from './_common/catalog/catalog-parts-compact-list/catalog-parts-compact-list.component';
import { CatalogPartsFilterPipe } from './_pipes/catalog/catalog-parts-filter.pipe';
import { CatalogPartsFilterComponent } from './_common/catalog/catalog-parts-filter/catalog-parts-filter.component';
import { CatalogPartsComponent } from './portal/catalog/catalog-parts/catalog-parts.component';
import { CatalogComponent } from './portal/catalog/catalog.component';
import { CustomerInformationComponent } from './portal/customer-information/customer-information.component';
import { HomeDefaultComponent } from './portal/home-screen/home-default/home-default.component';
import { HomeProPartsComponent } from './portal/home-screen/home-pro-parts/home-pro-parts.component';
import { HomeScreenComponent } from './portal/home-screen/home-screen.component';
import { ItemInfoComponent } from './portal/item-info/item-info.component';
import { LicensePlatesComponent } from './portal/license-plates/license-plates.component';
import { LoyaltyShopComponent } from './portal/loyalty-shop/loyalty-shop.component';
import { MpmComponent } from './portal/mpm/mpm.component';
import { NhsShopComponent } from './portal/nhs-shop/nhs-shop.component';
import { PortalComponent } from './portal/portal.component';
import { PromotionsComponent } from './portal/promotions/promotions.component';
import { PromotionsCardViewComponent } from './portal/promotions/promotions-overview/promotions-card-view/promotions-card-view.component';
// tslint:disable-next-line:max-line-length
import { PromotionsCarouselViewComponent } from './portal/promotions/promotions-overview/promotions-carousel-view/promotions-carousel-view.component';
import { RimsAndTyresComponent } from './portal/rims-and-tyres/rims-and-tyres.component';
import { TestShopComponent } from './portal/test-shop/test-shop.component';
import { ThirdpartyShopComponent } from './portal/thirdparty-shop/thirdparty-shop.component';
import { TyreStorageComponent } from './portal/tyre-storage/tyre-storage.component';
import { TyreFilterPipe } from './_pipes/tyres/tyre-filter.pipe';
import { TyreInfoComponent } from './portal/tyres/tyre-info/tyre-info.component';
import { TyresComponent } from './portal/tyres/tyres.component';
import { UniversalCatalogComponent } from './portal/universal-catalog/universal-catalog.component';
import { WorkshopComponent } from './portal/workshop/workshop.component';
import { SettingsComponent } from './settings/settings.component';
import { SideMenuDefaultButtonComponent } from './side-menu/side-menu-default/side-menu-default-button/side-menu-default-button.component';
import { SideMenuDefaultComponent } from './side-menu/side-menu-default/side-menu-default.component';
import { SideMenuDoetamButtonComponent } from './side-menu/side-menu-doetam/side-menu-doetam-button/side-menu-doetam-button.component';
import { SideMenuDoetamComponent } from './side-menu/side-menu-doetam/side-menu-doetam.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { PromotionsOverviewComponent } from './portal/promotions/promotions-overview/promotions-overview.component';
import { PromotionDetailComponent } from './portal/promotions/promotion-detail/promotion-detail.component';
import { PromotionService } from './_services/promotion.service';
import { CatalogSubPartsComponent } from './_common/catalog/catalog-sub-parts/catalog-sub-parts.component';
import { CatalogGraphicPartsComponent } from './portal/catalog/catalog-graphic-parts/catalog-graphic-parts.component';
import { HeaderTwimvaComponent } from './header/header-twimva/header-twimva.component';
import { RimsOverviewComponent } from './portal/rims-and-tyres/rims-overview/rims-overview.component';
// tslint:disable-next-line:max-line-length
import { RimsCardViewComponent } from './portal/rims-and-tyres/rims-overview/rims-overview-boxed-layout/rims-card-view/rims-card-view.component';
// tslint:disable-next-line:max-line-length
import { RimsListViewComponent } from './portal/rims-and-tyres/rims-overview/rims-overview-boxed-layout/rims-list-view/rims-list-view.component';
import { SortOptionsPipe } from './_pipes/sort-options.pipe';
import { RimFilterPipe } from './_pipes/rims-and-tyres/rim-filter.pipe';
import { WheelCarPictureComponent } from './_common/catalog/wheel-car-picture/wheel-car-picture.component';
import { WheelInfoPopupComponent } from './_common/catalog/wheel-info-popup/wheel-info-popup.component';
import { RimsAndTyresOrderPopupComponent } from './portal/rims-and-tyres/rims-and-tyres-order-popup/rims-and-tyres-order-popup.component';
import { RimAndTyreService } from './_services/rim-and-tyre.service';
import { DatePipe, APP_BASE_HREF } from '@angular/common';
import { HomeHoflandComponent } from './portal/home-screen/home-hofland/home-hofland.component';
import { HeaderHoflandComponent } from './header/header-hofland/header-hofland.component';
import { LoginInfoComponent } from './login/login-info/login-info.component';
import { LoyaltyShopService } from './_services/loyalty-shop.service';
import { HomeScreenService } from './_services/home-screen.service';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { HomeStarComponent } from './portal/home-screen/home-star/home-star.component';
import { HeaderStarComponent } from './header/header-star/header-star.component';
// tslint:disable-next-line:max-line-length
import { CustomerInformationMenuComponent } from './portal/customer-information/customer-information-menu/customer-information-menu.component';
import { OrderHistoryComponent } from './portal/customer-information/order-history/order-history.component';
import { OpenOrdersComponent } from './portal/customer-information/open-orders/open-orders.component';
import { DeliveryNotesComponent } from './portal/customer-information/delivery-notes/delivery-notes.component';
import { InvoicesComponent } from './portal/customer-information/invoices/invoices.component';
import { OutstandingItemsComponent } from './portal/customer-information/outstanding-items/outstanding-items.component';
import { ProductReturnsComponent } from './portal/customer-information/product-returns/product-returns.component';
import { ProductWarrantyComponent } from './portal/customer-information/product-warranty/product-warranty.component';
import { CustomerInformationService } from './_services/customer-information.service';
import { LeadZerosPipe } from './_pipes/lead-zeros.pipe';
import { GridComponent } from './_common/grid/grid.component';
import { InputPopupComponent } from './_common/input-popup/input-popup.component';
import { CatalogPartsSortPipe } from './_pipes/catalog/catalog-parts-sort.pipe';
import { SortOptionsComponent } from './_common/header-panel/sort-options/sort-options.component';
import { CatalogPartDetailedPopupComponent } from './_common/catalog/catalog-part-detailed-popup/catalog-part-detailed-popup.component';
import { CatalogPartsPropertiesComponent } from './_common/catalog/catalog-parts-properties/catalog-parts-properties.component';
import { FocusDirective } from './_directives/focus.directive';
import { LegacyTabStripComponent } from './header/legacy-tab-strip/legacy-tab-strip.component';
import { TabStripComponent } from './header/tab-strip/tab-strip.component';
import { ItemInfoService } from './_services/item-info.service';
import { CatalogPartsPropertyComponent } from './_common/catalog/catalog-parts-property/catalog-parts-property.component';
import { TicketLoginComponent } from './login/ticket-login/ticket-login.component';
import { CatalogPartsLinkPropertyComponent } from './_common/catalog/catalog-parts-link-property/catalog-parts-link-property.component';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';
import { CarBrandFilterPipe } from './car-type-selection/car-brand-list/car-brand-filter.pipe';
import { CarModelFilterPipe } from './car-type-selection/car-model-list/car-model-filter.pipe';
import { CarTypeFilterPipe } from './car-type-selection/car-type-list/car-type-filter.pipe';
import { HeaderPanelInnerComponent } from './_common/header-panel/header-panel-inner/header-panel-inner.component';
import { ThirdpartyShopService } from './_services/thirdparty-shop.service';
import { CatalogPartInfoComponent } from './_common/catalog/catalog-part-info/catalog-part-info.component';
import { CatalogPartApplicationComponent } from './_common/catalog/catalog-part-application/catalog-part-application.component';
import { CatalogPartSpecialToolsComponent } from './_common/catalog/catalog-part-special-tools/catalog-part-special-tools.component';
import { SortFieldComponent } from './_common/sort-field/sort-field.component';
import { SortService } from './_services/sort.service';
import { CatalogPartsFilterRowComponent } from './_common/catalog/catalog-parts-filter-row/catalog-parts-filter-row.component';
import { AmountPipe } from './_pipes/amount.pipe';
import { SettingsListComponent } from './settings/settings-list/settings-list.component';
import { SettingsGroupComponent } from './settings/settings-group/settings-group.component';
import { PortalSettingComponent } from './settings/portal-setting/portal-setting.component';
import { NumericOnlyDirective } from './_directives/numeric-only.directive';
import { DecimalOnlyDirective } from './_directives/decimal-only.directive';
import { ExternalFrameComponent } from './portal/external-frame/external-frame.component';
import { SafeUrlPipe } from './_pipes/safe-url.pipe';
import { HeaderWebshopComponent } from './header/header-webshop/header-webshop.component';
import { FooterComponent } from './footer/footer.component';
import { FooterDefaultComponent } from './footer/footer-default/footer-default.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationDefaultComponent } from './navigation/navigation-default/navigation-default.component';
import { ScrollToTopButtonComponent } from './_common/scroll-to-top-button/scroll-to-top-button.component';
import { TyreSearchInputComponent } from './_common/rims-and-tyres/tyre-search-input/tyre-search-input.component';
import { ItemSearchInputComponent } from './_common/item-search-input/item-search-input.component';
import { CarTypeSearchComponent } from './_common/car-type-search/car-type-search.component';
import { BrandModelTypeSearchComponent } from './_common/car-type-search/brand-model-type-search/brand-model-type-search.component';
import { CarBrandDisplayComponent } from './_common/car-type-search/brand-model-type-search/car-brand-display/car-brand-display.component';
import { HistorySearchComponent } from './_common/car-type-search/history-search/history-search.component';
import { UniversalCarTypeFilterPipe } from './_pipes/car-type/universal-car-type-filter.pipe';
// tslint:disable-next-line:max-line-length
import { CatalogGroupsBoxedLayoutComponent } from './portal/catalog/catalog-groups/catalog-groups-boxed-layout/catalog-groups-boxed-layout.component';
// tslint:disable-next-line:max-line-length
import { CatalogGroupsScrollLayoutComponent } from './portal/catalog/catalog-groups/catalog-groups-scroll-layout/catalog-groups-scroll-layout.component';
import { CarTypeInfoHeaderComponent } from './_common/car-type-info-header/car-type-info-header.component';
// tslint:disable-next-line:max-line-length
import { CatalogPartsBoxedLayoutComponent } from './portal/catalog/catalog-parts/catalog-parts-boxed-layout/catalog-parts-boxed-layout.component';
// tslint:disable-next-line:max-line-length
import { CatalogPartsScrollLayoutComponent } from './portal/catalog/catalog-parts/catalog-parts-scroll-layout/catalog-parts-scroll-layout.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuardAdmin } from './_guards/auth.guard.admin';
import { CatalogPartListComponent } from './_common/catalog/catalog-part-list/catalog-part-list.component';
import { CatalogPartListItemComponent } from './_common/catalog/catalog-part-list-item/catalog-part-list-item.component';
import { ItemCountWideComponent } from './_common/item-count-wide/item-count-wide.component';
// tslint:disable-next-line:max-line-length
import { PromotionsOverviewBoxedLayoutComponentComponent } from './portal/promotions/promotions-overview/promotions-overview-boxed-layout-component/promotions-overview-boxed-layout-component.component';
// tslint:disable-next-line:max-line-length
import { PromotionsOverviewScrollLayoutComponentComponent } from './portal/promotions/promotions-overview/promotions-overview-scroll-layout-component/promotions-overview-scroll-layout-component.component';
// tslint:disable-next-line:max-line-length
import { PromotionDetailBoxedLayoutComponentComponent } from './portal/promotions/promotion-detail/promotion-detail-boxed-layout-component/promotion-detail-boxed-layout-component.component';
// tslint:disable-next-line:max-line-length
import { PromotionDetailScrollLayoutComponentComponent } from './portal/promotions/promotion-detail/promotion-detail-scroll-layout-component/promotion-detail-scroll-layout-component.component';
import { HomeWebshopComponent } from './portal/home-screen/home-webshop/home-webshop.component';
import { PromotionCarouselComponent } from './portal/promotions/promotion-carousel/promotion-carousel.component';
// tslint:disable-next-line:max-line-length
import { CatalogPartsFilterAccordionComponent } from './_common/catalog/catalog-parts-filter-accordion/catalog-parts-filter-accordion.component';
// tslint:disable-next-line:max-line-length
import { TyresCardViewBoxedComponent } from './portal/tyres/tyres-boxed-layout/tyres-card-view-boxed/tyres-card-view-boxed.component';
// tslint:disable-next-line:max-line-length
import { TyresListViewBoxedComponent } from './portal/tyres/tyres-boxed-layout/tyres-list-view-boxed/tyres-list-view-boxed.component';
import { TyreListComponent } from './_common/rims-and-tyres/tyre-list/tyre-list.component';
import { TyreListItemComponent } from './_common/rims-and-tyres/tyre-list-item/tyre-list-item.component';
import { AdminSettingsComponent } from './admin/admin-settings/admin-settings.component';
import { AdminSettingsGeneralComponent } from './admin/admin-settings/admin-settings-general/admin-settings-general.component';
import { AdminStartComponent } from './admin/admin-start/admin-start.component';
import { AdminMenuItemComponent } from './admin/admin-menu-item/admin-menu-item.component';
// tslint:disable-next-line:max-line-length
import { AdminSettingsCatalogLayoutComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-settings-catalog-layout.component';
// tslint:disable-next-line:max-line-length
import { AdminSettingsCatalogLinesComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-lines/admin-settings-catalog-lines.component';
// tslint:disable-next-line:max-line-length
import { AdminSettingsCatalogGeneralComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-general/admin-settings-catalog-general.component';
import { AdminUsageComponent } from './admin/admin-usage/admin-usage.component';
import { AdminPromotionsComponent } from './admin/admin-promotions/admin-promotions.component';
import { AdminLoyaltyShopComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop.component';
import { AdminUsageOnlineComponent } from './admin/admin-usage/admin-usage-online/admin-usage-online.component';
import { AdminUsageTodayComponent } from './admin/admin-usage/admin-usage-today/admin-usage-today.component';
import { AdminUsageLastYearComponent } from './admin/admin-usage/admin-usage-last-year/admin-usage-last-year.component';
import { CenteredSpinnerComponent } from './_common/centered-spinner/centered-spinner.component';
import { SortSessionsPipe } from './admin/admin-usage/admin-usage-online/sort-sessions.pipe';
import { FilterSessionsPipe } from './admin/admin-usage/admin-usage-online/filter-sessions.pipe';
import { AdminSettingsCarTypeComponent } from './admin/admin-settings/admin-settings-car-type/admin-settings-car-type.component';
import { AdminSettingsTyresComponent } from './admin/admin-settings/admin-settings-tyres/admin-settings-tyres.component';
// tslint:disable-next-line:max-line-length
import { AdminSettingsRimsAndTyresComponent } from './admin/admin-settings/admin-settings-rims-and-tyres/admin-settings-rims-and-tyres.component';
import { AdminSettingsCartComponent } from './admin/admin-settings/admin-settings-cart/admin-settings-cart.component';
import { AdminCheckboxComponent } from './_common/admin/admin-checkbox/admin-checkbox.component';
import { AdminDividerComponent } from './_common/admin/admin-divider/admin-divider.component';
import { AdminDropdownComponent } from './_common/admin/admin-dropdown/admin-dropdown.component';
import { AdminCustomControlComponent } from './_common/admin/admin-custom-control/admin-custom-control.component';
import { AdminCancelSaveButtonComponent } from './_common/admin/admin-cancel-save-button/admin-cancel-save-button.component';
import { AdminFormComponent } from './_common/admin/admin-form/admin-form.component';
import { AdminEditorComponent } from './_common/admin/admin-editor/admin-editor.component';
import { AdminCheckboxInputComponent } from './_common/admin/admin-checkbox-input/admin-checkbox-input.component';
import { AdminActionButtonComponent } from './_common/admin/admin-action-button/admin-action-button.component';
import { LicensePlatesWebshopComponent } from './portal/license-plates/license-plates-webshop/license-plates-webshop.component';
// tslint:disable-next-line:max-line-length
import { EditSelectionItemComponent } from './admin/admin-settings/admin-settings-rims-and-tyres/edit-selection-item/edit-selection-item.component';
import { AdminInputComponent } from './_common/admin/admin-input/admin-input.component';
import { AdminTypeaheadItemSearchComponent } from './_common/admin/admin-typeahead-item-search/admin-typeahead-item-search.component';
import { SortByPropertyPipe } from './_pipes/sort-by-property.pipe';
import { EditDeliveryItemComponent } from './admin/admin-settings/admin-settings-cart/edit-delivery-item/edit-delivery-item.component';
import { AdminPromotionEditComponent } from './admin/admin-promotions/admin-promotion-edit/admin-promotion-edit.component';
import { ShowAvailabilityLogComponent } from './_common/show-availability-log/show-availability-log.component';
import { TimingObjectDisplayComponent } from './_common/show-timings/timing-object-display/timing-object-display.component';
import { TimingItemDisplayComponent } from './_common/show-timings/timing-item-display/timing-item-display.component';
import { OpenOrdersWebshopComponent } from './portal/customer-information/open-orders-webshop/open-orders-webshop.component';
// tslint:disable-next-line: max-line-length
import { OpenOrdersDetailWebshopComponent } from './portal/customer-information/open-orders-detail-webshop/open-orders-detail-webshop.component';
import { GridWebshopComponent } from './_common/grid-webshop/grid-webshop.component';
import { ExtraPasswordWebshopComponent } from './portal/customer-information/extra-password-webshop/extra-password-webshop.component';
import { DeliveryNotesWebshopComponent } from './portal/customer-information/delivery-notes-webshop/delivery-notes-webshop.component';
import { InvoicesWebshopComponent } from './portal/customer-information/invoices-webshop/invoices-webshop.component';
// tslint:disable-next-line: max-line-length
import { OutstandingItemsWebshopComponent } from './portal/customer-information/outstanding-items-webshop/outstanding-items-webshop.component';
import { OrderHistoryWebshopComponent } from './portal/customer-information/order-history-webshop/order-history-webshop.component';
import { ProductReturnsWebshopComponent } from './portal/customer-information/product-returns-webshop/product-returns-webshop.component';
import { OrderHistoryDetailsComponent } from './portal/customer-information/order-history-details/order-history-details.component';
import { ProductReturnsDetailComponent } from './portal/customer-information/product-returns-detail/product-returns-detail.component';
import { ProductReturnsRequestComponent } from './portal/customer-information/product-returns-request/product-returns-request.component';
import { ItemTypeaheadSearchComponent } from './_common/item-typeahead-search/item-typeahead-search.component';
import { AdminSettingsPromotionsComponent } from './admin/admin-settings/admin-settings-promotions/admin-settings-promotions.component';
// tslint:disable-next-line: max-line-length
import { ProductReturnsRequestItemComponent } from './portal/customer-information/product-returns-request-item/product-returns-request-item.component';
import { AdminDateInputComponent } from './_common/admin/admin-date-input/admin-date-input.component';
// tslint:disable-next-line: max-line-length
import { AdminPromotionEditItemComponent } from './admin/admin-promotions/admin-promotion-edit/admin-promotion-edit-item/admin-promotion-edit-item.component';
import { AdminNumberInputComponent } from './_common/admin/admin-number-input/admin-number-input.component';
import { BreadcrumbHeaderComponent } from './_common/breadcrumb-header/breadcrumb-header.component';
import { BreadcrumbHeaderItemComponent } from './_common/breadcrumb-header/breadcrumb-header-item/breadcrumb-header-item.component';
import { MaxItemsFilterPipe } from './_pipes/max-items-filter.pipe';
import { SidePanelComponent } from './_common/side-panel-and-content/side-panel/side-panel.component';
import { SidePanelHeaderComponent } from './_common/side-panel-and-content/side-panel/side-panel-header/side-panel-header.component';
// tslint:disable-next-line: max-line-length
import { SidePanelAccordionFilterComponent } from './_common/side-panel-and-content/side-panel/side-panel-accordion-filter/side-panel-accordion-filter.component';
import { SidePanelBodyComponent } from './_common/side-panel-and-content/side-panel/side-panel-body/side-panel-body.component';
import { ItemInfoBoxedLayoutComponent } from './portal/item-info/item-info-boxed-layout/item-info-boxed-layout.component';
import { ItemInfoScrollLayoutComponent } from './portal/item-info/item-info-scroll-layout/item-info-scroll-layout.component';
import { SidePanelCatalogComponent } from './_common/side-panel-and-content/side-panel/side-panel-catalog/side-panel-catalog.component';
// tslint:disable-next-line: max-line-length
import { CatalogGraphicPartsBoxedLayoutComponent } from './portal/catalog/catalog-graphic-parts/catalog-graphic-parts-boxed-layout/catalog-graphic-parts-boxed-layout.component';
// tslint:disable-next-line: max-line-length
import { CatalogGraphicPartsScrollLayoutComponent } from './portal/catalog/catalog-graphic-parts/catalog-graphic-parts-scroll-layout/catalog-graphic-parts-scroll-layout.component';
import { CatalogGraphicStripComponent } from './_common/catalog/catalog-graphic-strip/catalog-graphic-strip.component';
import { SidePanelSelectComponent } from './_common/side-panel-and-content/side-panel/side-panel-select/side-panel-select.component';
// tslint:disable-next-line: max-line-length
import { RimsOverviewBoxedLayoutComponent } from './portal/rims-and-tyres/rims-overview/rims-overview-boxed-layout/rims-overview-boxed-layout.component';
// tslint:disable-next-line: max-line-length
import { RimsOverviewScrollLayoutComponent } from './portal/rims-and-tyres/rims-overview/rims-overview-scroll-layout/rims-overview-scroll-layout.component';
import { WheelListComponent } from './_common/rims-and-tyres/wheel-list/wheel-list.component';
import { WheelListItemComponent } from './_common/rims-and-tyres/wheel-list-item/wheel-list-item.component';
import { SidePanelAndContentComponent } from './_common/side-panel-and-content/side-panel-and-content.component';
import { ContentListItemComponent } from './_common/side-panel-and-content/content-list-item/content-list-item.component';
// tslint:disable-next-line: max-line-length
import { PartListItemInfoComponent } from './_common/side-panel-and-content/content-list-item/part-list-item-info/part-list-item-info.component';
// tslint:disable-next-line: max-line-length
import { AdminCatalogLayoutCategoryComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-catalog-layout-category/admin-catalog-layout-category.component';
// tslint:disable-next-line: max-line-length
import { AdminCatalogLayoutCategorySortPipe } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-catalog-layout-category-sort.pipe';
// tslint:disable-next-line: max-line-length
import { AdminCatalogLayoutCategoryInfoComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-catalog-layout-category-info/admin-catalog-layout-category-info.component';
import { FilteredTextComponent } from './_common/filtered-text/filtered-text.component';
// tslint:disable-next-line: max-line-length
import { AdminCatalogLayoutPartsDescriptionsSortPipe } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-catalog-layout-category-info/admin-catalog-layout-parts-descriptions-sort.pipe';
// tslint:disable-next-line: max-line-length
import { AdminCatalogLayoutStatusFilterPipe } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-catalog-layout-status-filter.pipe';
// tslint:disable-next-line: max-line-length
import { AdminCatalogLayoutChooseCatalogComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-catalog-layout-choose-catalog/admin-catalog-layout-choose-catalog.component';
import { UploadComponent } from './_common/upload/upload.component';
import { DropDownFilterComponent } from './_common/drop-down-filter/drop-down-filter.component';
// tslint:disable-next-line: max-line-length
import { CatalogLineCheckboxComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-lines/catalog-line-checkbox/catalog-line-checkbox.component';
// tslint:disable-next-line: max-line-length
import { AdminCatalogLinesFilterPipe } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-lines/admin-catalog-lines-filter.pipe';
import { AdminSettingsLayoutComponent } from './admin/admin-settings/admin-settings-layout/admin-settings-layout.component';
import { TyresBoxedLayoutComponent } from './portal/tyres/tyres-boxed-layout/tyres-boxed-layout.component';
import { TyresScrollLayoutComponent } from './portal/tyres/tyres-scroll-layout/tyres-scroll-layout.component';
// tslint:disable-next-line:max-line-length
import { ThirdpartyShopBoxedLayoutComponent } from './portal/thirdparty-shop/thirdparty-shop-boxed-layout/thirdparty-shop-boxed-layout.component';
// tslint:disable-next-line:max-line-length
import { ThirdpartyShopScrollLayoutComponent } from './portal/thirdparty-shop/thirdparty-shop-scroll-layout/thirdparty-shop-scroll-layout.component';
import { AdminSettingsModulesComponent } from './admin/admin-settings/admin-settings-modules/admin-settings-modules.component';
// tslint:disable-next-line:max-line-length
import { AdminShopModuleSettingsComponent } from './admin/admin-settings/admin-settings-modules/admin-shop-module-settings/admin-shop-module-settings.component';
import { ModuleFilterPipe } from './admin/admin-settings/admin-settings-modules/module-filter.pipe';
// tslint:disable-next-line:max-line-length
import { AdminTypeaheadCustomerSearchComponent } from './_common/admin/admin-typeahead-customer-search/admin-typeahead-customer-search.component';
import { AdminSettingsSelectorComponent } from './_common/admin/admin-settings-selector/admin-settings-selector.component';
import { HeaderStarOldComponent } from './header/header-star-old/header-star-old.component';
import { HomeStarOldComponent } from './portal/home-screen/home-star-old/home-star-old.component';
import { StarModuleComponent } from './portal/home-screen/home-star/star-module/star-module.component';
import { StarPromotionComponent } from './portal/home-screen/home-star/star-promotion/star-promotion.component';
import { StarLicenseplateComponent } from './portal/home-screen/home-star/star-licenseplate/star-licenseplate.component';
import { LicensePlatesBoxedComponent } from './portal/license-plates/license-plates-boxed/license-plates-boxed.component';
import { LogService } from './_services/log.service';
import { BeforeUnloadComponent } from './_common/before-unload/before-unload.component';
import { UnloadComponent } from './_common/unload/unload.component';
import { SuperAdminComponent } from './admin/super-admin/super-admin.component';
import { FooterXtrapartsComponent } from './footer/footer-xtraparts/footer-xtraparts.component';
import { FooterRoskampComponent } from './footer/footer-roskamp/footer-roskamp.component';
import { ScrollToTopButtonDefaultComponent } from './_common/scroll-to-top-button/scroll-to-top-button-default/scroll-to-top-button-default.component';
import { ScrollToTopButtonRoskampComponent } from './_common/scroll-to-top-button/scroll-to-top-button-roskamp/scroll-to-top-button-roskamp.component';
import { PaintComponent } from './portal/paint/paint.component';
import { AdminSettingsCatalogAldocComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-aldoc/admin-settings-catalog-aldoc.component';
import { AdminSettingsCatalogHbaseComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-hbase/admin-settings-catalog-hbase.component';
import { AdminSettingsCatalogTecdocComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-tecdoc/admin-settings-catalog-tecdoc.component';
import { AdminSettingsCatalogAlcarComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-alcar/admin-settings-catalog-alcar.component';
import { AdminSettingsCatalogIntertyreComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-intertyre/admin-settings-catalog-intertyre.component';
import { AdminSettingsCatalogMpmComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-mpm/admin-settings-catalog-mpm.component';
import { AdminSettingsCatalogSinatecComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-sinatec/admin-settings-catalog-sinatec.component';
import { AdminSettingsCatalogStahlieComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-stahlie/admin-settings-catalog-stahlie.component';
import { AdminTypeaheadSupplierSearchComponent } from './_common/admin/admin-typeahead-supplier-search/admin-typeahead-supplier-search.component';
import { AdminSupplierInputComponent } from './_common/admin/admin-supplier-input/admin-supplier-input.component';
import { AdminRadiobuttonComponent } from './_common/admin/admin-radiobutton/admin-radiobutton.component';
import { AdminSettingsCatalogLinesReportComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-lines/admin-settings-catalog-lines-report/admin-settings-catalog-lines-report.component';
import { CatalogLinesTotalsReportComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-lines/admin-settings-catalog-lines-report/catalog-lines-totals-report/catalog-lines-totals-report.component';
import { WebshopMenuStaticComponent } from './header/header-webshop/webshop-menu-static/webshop-menu-static.component';
import { WebshopMenuDynamicComponent } from './header/header-webshop/webshop-menu-dynamic/webshop-menu-dynamic.component';
import { WebshopMenuItemComponent } from './header/header-webshop/webshop-menu-dynamic/webshop-menu-item/webshop-menu-item.component';
import { AdminLoyaltyShopSettingsComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-settings/admin-loyalty-shop-settings.component';
import { AdminLoyaltyShopCategoriesComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-categories/admin-loyalty-shop-categories.component';
import { AdminLoyaltyShopItemsComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-items/admin-loyalty-shop-items.component';
import { AdminLoyaltyShopCategoryEditComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-categories/admin-loyalty-shop-category-edit/admin-loyalty-shop-category-edit.component';
import { AdminLoyaltyShopItemEditComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-items/admin-loyalty-shop-item-edit/admin-loyalty-shop-item-edit.component';
import { AdminTypeaheadItemComponent } from './_common/admin/admin-typeahead-item/admin-typeahead-item.component';
import { ListDisplayOptionsComponent } from './_common/list-display-options/list-display-options.component';
import { TyreListItemCompactComponent } from './_common/rims-and-tyres/tyre-list-item-compact/tyre-list-item-compact.component';
import { AdminSettingsAvailabilityAndPriceComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-availability-and-price.component';
import { AdminSubtitleComponent } from './_common/admin/admin-subtitle/admin-subtitle.component';
import { AdminSettingsCatalogSetupComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-setup/admin-settings-catalog-setup.component';
import { MaintenancePartsComponent } from './portal/maintenance/maintenance-parts/maintenance-parts.component';
import { MaintenanceComponent } from './portal/maintenance/maintenance.component';
import { AdminCatalogPartsTypesComponent } from './admin/super-admin/admin-catalog-parts-types/admin-catalog-parts-types.component';
import { AdminPartsTypeBinderComponent } from './admin/super-admin/admin-catalog-parts-types/admin-parts-type-binder/admin-parts-type-binder.component';
import { FilterPartsTypesPipe } from './admin/super-admin/admin-catalog-parts-types/filter-parts-types.pipe';
import { FilterCrossedPartsTypesPipe } from './admin/super-admin/admin-catalog-parts-types/filter-crossed-parts-types.pipe';
import { LoyaltyShopItemComponent } from './portal/loyalty-shop/loyalty-shop-item/loyalty-shop-item.component';
import { FilterStringPipe } from './_pipes/filter-string.pipe';
import { MaintenancePartsBoxedLayoutComponent } from './portal/maintenance/maintenance-parts/maintenance-parts-boxed-layout/maintenance-parts-boxed-layout.component';
import { MaintenancePartsScrollLayoutComponent } from './portal/maintenance/maintenance-parts/maintenance-parts-scroll-layout/maintenance-parts-scroll-layout.component';
import { CatalogPartsSelectionPopupComponent } from './_common/catalog/catalog-parts-selection-popup/catalog-parts-selection-popup.component';
import { ContextPublic } from './_models/common/context.public';
import { ConfigData } from './_models/common/config.data';
import { AdminSettingsExceptionsAvailabilityAndPriceComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-exceptions-availability-and-price/admin-settings-exceptions-availability-and-price.component';
import { AdminPartsTypeInputComponent } from './admin/super-admin/admin-catalog-parts-types/admin-parts-type-binder/admin-parts-type-input/admin-parts-type-input.component';
import { HomeExclupartsComponent } from './portal/home-screen/home-excluparts/home-excluparts.component';
import { FooterExclupartsComponent } from './footer/footer-excluparts/footer-excluparts.component';
import { CarTypeInfoBlockComponent } from './_common/car-type-info-header/car-type-info-block/car-type-info-block.component';
import { CarTypeInfoPropertyComponent } from './_common/car-type-info-header/car-type-info-block/car-type-info-property/car-type-info-property.component';
import { ToastsComponent } from './_common/toasts/toasts.component';
import { ToastComponent } from './_common/toasts/toast/toast.component';
import { PartItemNumberLinkComponent } from './_common/catalog/part-item-number-link/part-item-number-link.component';
import { RouteInfoComponent } from './_common/route-info/route-info.component';
import { ShowMoreButtonComponent } from './_common/show-more-button/show-more-button.component';
import { AdminSettingsCustomerInformationComponent } from './admin/admin-settings/admin-settings-customer-information/admin-settings-customer-information.component';
import { AdminCheckboxListComponent } from './_common/admin/admin-checkbox-list/admin-checkbox-list.component';
import { AdminSettingsSelectorPopupComponent } from './_common/admin/admin-settings-selector/admin-settings-selector-popup/admin-settings-selector-popup.component';
import { AdminSettingsSelectorInfoLinkComponent } from './_common/admin/admin-settings-selector/admin-settings-selector-info-link/admin-settings-selector-info-link.component';
import { ImageViewerComponent } from './_common/image-viewer/image-viewer.component';
import { NgVarDirective } from './_directives/ng-var.directive';
import { AdminSaveButtonComponent } from './_common/admin/admin-cancel-save-button/admin-save-button/admin-save-button.component';
import { CatalogPartDocumentButtonsComponent } from './_common/catalog/catalog-part-document-buttons/catalog-part-document-buttons.component';
import { ContainerComponent } from './_common/container/container.component';
import { TyreLabelComponent } from './_common/rims-and-tyres/tyre-label/tyre-label.component';
import { ThirdPartyFrameComponent } from './portal/third-party-frame/third-party-frame.component';
import { HomeRijpmaComponent } from './portal/home-screen/home-rijpma/home-rijpma.component';
import { TyreCalculatorComponent } from './portal/tyre-calculator/tyre-calculator.component';
import { LoginRijpmaComponent } from './login/login-rijpma/login-rijpma.component';
import { SelectOnclickDirective } from './_directives/select-onclick.directive';
import { AdminSettingsAvailabilitySuppliersComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-availability-suppliers/admin-settings-availability-suppliers.component';
import { SelectionPopupComponent } from './_common/selection-popup/selection-popup.component';
import { DebugSortOptionsComponent } from './_common/debug/debug-sort-options/debug-sort-options.component';
import { DebugTypeScriptVarComponent } from './_common/debug/debug-type-script-var/debug-type-script-var.component';
import { MpmListItemComponent } from './portal/mpm/mpm-list-item/mpm-list-item.component';
import { MpmHasCartItemPipe } from './portal/mpm/mpm-has-cart-item.pipe';
import { SideSelectListComponent } from './_common/side-panel-and-content/side-select-list/side-select-list.component';
import { SideSelectListItemComponent } from './_common/side-panel-and-content/side-select-list/side-select-list-item/side-select-list-item.component';
import { MpmPartComponent } from './portal/mpm/mpm-part/mpm-part.component';
import { AdminLoyaltyShopAdminComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-admin/admin-loyalty-shop-admin.component';
import { AdminTypeaheadOrderSearchComponent } from './_common/admin/admin-typeahead-order-search/admin-typeahead-order-search.component';
import { AdminLoyaltyShopSummaryComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-summary/admin-loyalty-shop-summary.component';
import { FilterCreditsInfosPipe } from './admin/admin-loyalty-shop/admin-loyalty-shop-summary/filter-credits-infos.pipe';
import { AdminTextareaComponent } from './_common/admin/admin-textarea/admin-textarea.component';
import { AdminSettingsBrightComponent } from './admin/admin-settings/admin-settings-bright/admin-settings-bright.component';
import { AdminItemGroupInputComponent } from './_common/admin/admin-item-group-input/admin-item-group-input.component';
import { AdminTypeaheadItemGroupSearchComponent } from './_common/admin/admin-typeahead-item-group-search/admin-typeahead-item-group-search.component';
import { AdminSupplierInterfaceInfoComponent } from './admin/super-admin/admin-supplier-interfaces/admin-supplier-interface-info/admin-supplier-interface-info.component';
import { UppercaseInputDirective } from './_directives/uppercase-input.directive';
import { LowercaseInputDirective } from './_directives/lowercase-input.directive';
import { SupplierInterfaceResponseComponent } from './admin/super-admin/admin-supplier-interfaces/admin-supplier-interface-info/supplier-interface-response/supplier-interface-response.component';
import { AdminItemGroupsInputComponent } from './_common/admin/admin-item-groups-input/admin-item-groups-input.component';
import { AdminSettingsNavigationComponent } from './admin/admin-settings/admin-settings-layout/admin-settings-navigation/admin-settings-navigation.component';
import { AdminWholesalerOverviewComponent } from './admin/super-admin/admin-wholesaler-overview/admin-wholesaler-overview.component';
import { FilterByPropertyPipe } from './_pipes/filter-by-property.pipe';
import { AdminBackendInfoComponent } from './admin/super-admin/admin-wholesaler-overview/admin-backend-info/admin-backend-info.component';
import { FilterByWholesalerInfoPipe } from './_pipes/filter-by-wholesaler-info.pipe';
import { PickupRequestComponent } from './portal/pickup-request/pickup-request.component';
import { AdminItemImagesComponent } from './admin/admin-management/admin-item-images/admin-item-images.component';
import { AdminItemImagesEditComponent } from './admin/admin-management/admin-item-images/admin-item-images-edit/admin-item-images-edit.component';
import { AdminItemGroupImagesComponent } from './admin/admin-management/admin-item-group-images/admin-item-group-images.component';
import { AdminItemGroupImagesEditComponent } from './admin/admin-management/admin-item-group-images/admin-item-group-images-edit/admin-item-group-images-edit.component';
import { AdminSupplierInterfacesComponent } from './admin/super-admin/admin-supplier-interfaces/admin-supplier-interfaces.component';
import { AdminItemInfoComponent } from './admin/super-admin/admin-item-info/admin-item-info.component';
import { ItemInfoResponseComponent } from './admin/super-admin/admin-item-info/item-info-response/item-info-response.component';
import { DefaultSupplierInterfaceSettingsComponent } from './admin/super-admin/admin-supplier-interfaces/default-supplier-interface-settings/default-supplier-interface-settings.component';
import { DefaultInterfaceDataSettingsComponent } from './admin/super-admin/admin-supplier-interfaces/default-supplier-interface-settings/default-interface-data-settings/default-interface-data-settings.component';
import { ItemInfoSupplierAvailabilityComponent } from './admin/super-admin/admin-item-info/item-info-response/item-info-supplier-availability/item-info-supplier-availability.component';
import { ItemInfoBranchAvailabilityComponent } from './admin/super-admin/admin-item-info/item-info-response/item-info-branch-availability/item-info-branch-availability.component';
import { FilterSupplierInterfacePipe } from './admin/super-admin/admin-supplier-interfaces/filter-supplier-interface.pipe';
import { DefaultInterfaceDataEditComponent } from './admin/super-admin/admin-supplier-interfaces/default-supplier-interface-settings/default-interface-data-settings/default-interface-data-edit/default-interface-data-edit.component';
import { SwitchComponent } from './_common/switch/switch.component';
import { ProxySupplierInterfaceSettingsComponent } from './admin/super-admin/admin-supplier-interfaces/proxy-supplier-interface-settings/proxy-supplier-interface-settings.component';
import { ProxyInterfaceDataSettingsComponent } from './admin/super-admin/admin-supplier-interfaces/proxy-supplier-interface-settings/proxy-interface-data-settings/proxy-interface-data-settings.component';
import { ProxyInterfaceDataEditComponent } from './admin/super-admin/admin-supplier-interfaces/proxy-supplier-interface-settings/proxy-interface-data-settings/proxy-interface-data-edit/proxy-interface-data-edit.component';
import { EasycloudStockinfoSupplierInterfaceSettingsComponent } from './admin/super-admin/admin-supplier-interfaces/easycloud-stockinfo-supplier-interface-settings/easycloud-stockinfo-supplier-interface-settings.component';
import { ArticleSourceResponseComponent } from './admin/super-admin/admin-item-info/article-source-response/article-source-response.component';
import { ItemInfoFilterPipe } from './admin/super-admin/admin-item-info/item-info-response/item-info-filter.pipe';
import { DefaultInterfaceDataSettingsSingleComponent } from './admin/super-admin/admin-supplier-interfaces/default-supplier-interface-settings/default-interface-data-settings/default-interface-data-settings-single/default-interface-data-settings-single.component';
import { IntercarsSupplierInterfaceSettingsComponent } from './admin/super-admin/admin-supplier-interfaces/intercars-supplier-interface-settings/intercars-supplier-interface-settings.component';
import { IntercarsInterfaceDataSettingsComponent } from './admin/super-admin/admin-supplier-interfaces/intercars-supplier-interface-settings/intercars-interface-data-settings/intercars-interface-data-settings.component';
import { IntercarsInterfaceDataEditComponent } from './admin/super-admin/admin-supplier-interfaces/intercars-supplier-interface-settings/intercars-interface-data-settings/intercars-interface-data-edit/intercars-interface-data-edit.component';
import { FavoritesControlComponent } from './_common/item-info-template/template-parts/favorites-control/favorites-control.component';
import { PromotionSortOrderPipe } from './admin/admin-promotions/promotion-sort-order.pipe';
import { FavoritesComponent } from './portal/favorites/favorites.component';
import { PasswordForgottenPopupComponent } from './_common/password-forgotten-popup/password-forgotten-popup.component';
import { RequestAccountPopupComponent } from './_common/request-account-popup/request-account-popup.component';
import { ManageAccountComponent } from './login/manage-account/manage-account.component';
import { ItemInfoDataComponent } from './admin/super-admin/admin-item-info/item-info-response/item-info-data/item-info-data.component';
import { AdminRouteInfoComponent } from './admin/super-admin/admin-route-info/admin-route-info.component';
import { AdminCustomerInputComponent } from './_common/admin/admin-customer-input/admin-customer-input.component';
import { AdminRouteInfoItemComponent } from './admin/super-admin/admin-route-info/admin-route-info-item/admin-route-info-item.component';
import { AdminOrderInfoComponent } from './admin/super-admin/admin-order-info/admin-order-info.component';
import { ArchiveOrderComponent } from './admin/super-admin/admin-order-info/archive-order/archive-order.component';
import { EasytradeOrderComponent } from './admin/super-admin/admin-order-info/easytrade-order/easytrade-order.component';
import { EasytradeOrderItemComponent } from './admin/super-admin/admin-order-info/easytrade-order/easytrade-order-item/easytrade-order-item.component';
import { ArchiveJsonPanelComponent } from './admin/super-admin/admin-order-info/archive-order/archive-json-panel/archive-json-panel.component';
import { EasytradeOrderTextItemComponent } from './admin/super-admin/admin-order-info/easytrade-order/easytrade-order-text-item/easytrade-order-text-item.component';
import { AdminOrderSearchComponent } from './admin/super-admin/admin-order-search/admin-order-search.component';
import { AdminItemInputComponent } from './_common/admin/admin-item-input/admin-item-input.component';
import { ArchiveOrderKindBadgeComponent } from './admin/super-admin/admin-order-info/archive-order/archive-order-kind-badge/archive-order-kind-badge.component';
import { AdminSettingsAvailabilityTemplatesComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-availability-templates/admin-settings-availability-templates.component';
import { AdminAdminSettingComponent } from './_common/admin/admin-admin-setting/admin-admin-setting.component';
import { AdminSettingsPaintComponent } from './admin/admin-settings/admin-settings-paint/admin-settings-paint.component';
import { AdminItemsInputComponent } from './_common/admin/admin-items-input/admin-items-input.component';
import { ImageViewerModalComponent } from './_common/image-viewer-modal/image-viewer-modal.component';
import { ShowMoreLessButtonComponent } from './_common/show-more-less-button/show-more-less-button.component';
import { CartTextLinesComponent } from './cart/cart-text-lines/cart-text-lines.component';
import { WebshopSubMenuComponent } from './header/header-webshop/webshop-menu-dynamic/webshop-menu-item/webshop-sub-menu/webshop-sub-menu.component';
import { AdminSettingsExtraMessagesComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-extra-messages/admin-settings-extra-messages.component';
import { ExtraMessageMainSupplierComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-extra-messages/extra-message-main-supplier/extra-message-main-supplier.component';
import { ExtraMessageItemGroupComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-extra-messages/extra-message-item-group/extra-message-item-group.component';
import { AdminCheckboxEditorComponent } from './_common/admin/admin-checkbox-editor/admin-checkbox-editor.component';
import { AdminSettingsMenuComponent } from './admin/admin-settings/admin-settings-menu/admin-settings-menu.component';
import { AdminShopModuleMenuComponent } from './admin/admin-settings/admin-settings-menu/admin-shop-module-menu/admin-shop-module-menu.component';
import { AdminShopModuleMenuItemComponent } from './admin/admin-settings/admin-settings-menu/admin-shop-module-menu/admin-shop-module-menu-item/admin-shop-module-menu-item.component';
import { ItemInfoTemplatePartComponent } from './_common/item-info-template/item-info-template-part/item-info-template-part.component';
import { AvailabilityTemplatesOverviewComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-availability-templates/availability-templates-overview/availability-templates-overview.component';
import { AdminModalSettingsWrapperComponent } from './_common/admin/admin-modal-settings-wrapper/admin-modal-settings-wrapper.component';
import { AdminSettingsCommonSettingsGenericComponent } from './admin/admin-settings/admin-settings-common-settings-generic/admin-settings-common-settings-generic.component';
import { AdminSettingsLicensePlatesComponent } from './admin/admin-settings/admin-settings-license-plates/admin-settings-license-plates.component';
import { AdminLicensePlateKindsComponent } from './admin/super-admin/admin-license-plate-kinds/admin-license-plate-kinds.component';
import { HelperDisplayBreakpointComponent } from './_common/_helpers/helper-display-breakpoint/helper-display-breakpoint.component';
import { AdminBranchInputComponent } from './_common/admin/admin-branch-input/admin-branch-input.component';
import { AdminSettingsVrooamComponent } from './admin/admin-settings/admin-settings-vrooam/admin-settings-vrooam.component';
import { AdminSettingsAldocComponent } from './admin/admin-settings/admin-settings-aldoc/admin-settings-aldoc.component';
import { ItemInfoWarningsComponent } from './_common/item-info-template/item-info-warnings/item-info-warnings.component';
import { CustomerWarningsComponent } from './_common/admin/customer-warnings/customer-warnings.component';



registerLocaleData(localeNl, 'nl');

export function onInit(logService: LogService) {
  return () => logService.init();
}


@NgModule({
    declarations: [
        AppComponent,
        AutofocusDirective,
        BandenMaatDirective,
        LicensePlateDirective,
        PricePipe,
        LoginComponent,
        HeaderComponent,
        SettingsComponent,
        CartComponent,
        PortalComponent,
        LoginDefaultComponent,
        LoginHoflandComponent,
        HeaderDefaultComponent,
        ChooseVersionComponent,
        MessagePopupComponent,
        ConfirmPopupComponent,
        ChooseCookieComponent,
        TyresComponent,
        HeaderPanelComponent,
        ItemInfoTemplateComponent,
        ItemInfoWarningsComponent,
        CustomerWarningsComponent,
        VoorraadPrijsOptiesComponent,
        CallbackInfoComponent,
        ItemCountComponent,
        ShowObjectComponent,
        ShowTimingsComponent,
        VoorraadPrijsDisplayOptiesComponent,
        CarTypeSelectionComponent,
        CatalogComponent,
        CarTypeListComponent,
        CarModelListComponent,
        CarBrandListComponent,
        CarHistoryListComponent,
        NhsShopComponent,
        RimsAndTyresComponent,
        TyresComponent,
        ItemInfoComponent,
        ItemCountSmallComponent,
        TyreFilterPipe,
        StockFilterPipe,
        TyreInfoComponent,
        ChooseResponseItemComponent,
        CatalogGroupsComponent,
        FilterCountPipe,
        PartsCategoryFilterPipe,
        CatalogPartsComponent,
        LicensePlatesComponent,
        LoyaltyShopComponent,
        UniversalCatalogComponent,
        PromotionsComponent,
        PromotionsCardViewComponent,
        PromotionsCarouselViewComponent,
        MpmComponent,
        TyreStorageComponent,
        WorkshopComponent,
        CustomerInformationComponent,
        TestShopComponent,
        ThirdpartyShopComponent,
        HomeScreenComponent,
        MessageComponent,
        HomeProPartsComponent,
        HomeDefaultComponent,
        HeaderProPartsComponent,
        DisplayOptiesComponent,
        CatalogPartsDescriptionPartsComponent,
        CatalogPartDetailedComponent,
        CatalogPartsCompactListComponent,
        CatalogPartsFilterPipe,
        CatalogPartsFilterComponent,
        HeaderDoetamComponent,
        LoginDoetamComponent,
        SideCartComponent,
        SideMenuComponent,
        SideMenuDefaultComponent,
        SideMenuDoetamComponent,
        SideMenuDefaultButtonComponent,
        CatalogPartsCardViewComponent,
        SideMenuDoetamButtonComponent,
        PromotionsOverviewComponent,
        PromotionDetailComponent,
        CatalogSubPartsComponent,
        CatalogGraphicPartsComponent,
        HeaderTwimvaComponent,
        RimsOverviewComponent,
        RimsCardViewComponent,
        RimsListViewComponent,
        SortOptionsPipe,
        RimFilterPipe,
        WheelCarPictureComponent,
        WheelInfoPopupComponent,
        RimsAndTyresOrderPopupComponent,
        HomeHoflandComponent,
        HeaderHoflandComponent,
        LoginInfoComponent,
        HomeStarComponent,
        HeaderStarComponent,
        CustomerInformationMenuComponent,
        OrderHistoryComponent,
        OpenOrdersComponent,
        DeliveryNotesComponent,
        InvoicesComponent,
        OutstandingItemsComponent,
        ProductReturnsComponent,
        ProductWarrantyComponent,
        LeadZerosPipe,
        GridComponent,
        InputPopupComponent,
        CatalogPartsSortPipe,
        SortOptionsComponent,
        CatalogPartDetailedPopupComponent,
        CatalogPartsPropertiesComponent,
        FocusDirective,
        LegacyTabStripComponent,
        TabStripComponent,
        CatalogPartsPropertyComponent,
        TicketLoginComponent,
        CatalogPartsLinkPropertyComponent,
        SafeHtmlPipe,
        CarBrandFilterPipe,
        CarModelFilterPipe,
        CarTypeFilterPipe,
        HeaderPanelInnerComponent,
        CatalogPartInfoComponent,
        CatalogPartApplicationComponent,
        CatalogPartSpecialToolsComponent,
        SortFieldComponent,
        AmountPipe,
        CatalogPartsFilterRowComponent,
        SettingsListComponent,
        SettingsGroupComponent,
        PortalSettingComponent,
        NumericOnlyDirective,
        DecimalOnlyDirective,
        ExternalFrameComponent,
        SafeUrlPipe,
        HeaderWebshopComponent,
        FooterComponent,
        FooterDefaultComponent,
        NavigationComponent,
        NavigationDefaultComponent,
        ScrollToTopButtonComponent,
        TyreSearchInputComponent,
        ItemSearchInputComponent,
        CarTypeSearchComponent,
        BrandModelTypeSearchComponent,
        CarBrandDisplayComponent,
        HistorySearchComponent,
        UniversalCarTypeFilterPipe,
        CatalogGroupsBoxedLayoutComponent,
        CatalogGroupsScrollLayoutComponent,
        CarTypeInfoHeaderComponent,
        CatalogPartsBoxedLayoutComponent,
        CatalogPartsScrollLayoutComponent,
        AdminComponent,
        CatalogPartListComponent,
        CatalogPartListItemComponent,
        ItemCountWideComponent,
        PromotionsOverviewBoxedLayoutComponentComponent,
        PromotionsOverviewScrollLayoutComponentComponent,
        PromotionDetailBoxedLayoutComponentComponent,
        PromotionDetailScrollLayoutComponentComponent,
        HomeWebshopComponent,
        PromotionCarouselComponent,
        CatalogPartsFilterAccordionComponent,
        TyresCardViewBoxedComponent,
        TyresListViewBoxedComponent,
        TyreListComponent,
        TyreListItemComponent,
        AdminSettingsComponent,
        AdminSettingsGeneralComponent,
        AdminStartComponent,
        AdminMenuItemComponent,
        AdminSettingsCatalogLayoutComponent,
        AdminSettingsCatalogLinesComponent,
        AdminSettingsCatalogGeneralComponent,
        AdminUsageComponent,
        AdminPromotionsComponent,
        AdminLoyaltyShopComponent,
        AdminUsageOnlineComponent,
        AdminUsageTodayComponent,
        AdminUsageLastYearComponent,
        CenteredSpinnerComponent,
        SortSessionsPipe,
        FilterSessionsPipe,
        AdminSettingsCarTypeComponent,
        AdminSettingsTyresComponent,
        AdminSettingsRimsAndTyresComponent,
        AdminSettingsCartComponent,
        AdminCheckboxComponent,
        AdminDividerComponent,
        AdminDropdownComponent,
        AdminBranchInputComponent,
        AdminCustomControlComponent,
        AdminCancelSaveButtonComponent,
        AdminFormComponent,
        AdminEditorComponent,
        AdminCheckboxInputComponent,
        AdminActionButtonComponent,
        LicensePlatesWebshopComponent,
        EditSelectionItemComponent,
        AdminInputComponent,
        AdminTypeaheadItemSearchComponent,
        SortByPropertyPipe,
        EditDeliveryItemComponent,
        AdminPromotionEditComponent,
        ShowAvailabilityLogComponent,
        TimingObjectDisplayComponent,
        TimingItemDisplayComponent,
        LicensePlatesWebshopComponent,
        OpenOrdersWebshopComponent,
        OpenOrdersDetailWebshopComponent,
        GridWebshopComponent,
        ExtraPasswordWebshopComponent,
        DeliveryNotesWebshopComponent,
        InvoicesWebshopComponent,
        OutstandingItemsWebshopComponent,
        OrderHistoryWebshopComponent,
        ProductReturnsWebshopComponent,
        OrderHistoryDetailsComponent,
        ProductReturnsDetailComponent,
        ProductReturnsRequestComponent,
        ItemTypeaheadSearchComponent,
        AdminSettingsPromotionsComponent,
        ProductReturnsRequestItemComponent,
        AdminDateInputComponent,
        AdminPromotionEditItemComponent,
        AdminNumberInputComponent,
        BreadcrumbHeaderComponent,
        BreadcrumbHeaderItemComponent,
        MaxItemsFilterPipe,
        SidePanelComponent,
        SidePanelHeaderComponent,
        SidePanelAccordionFilterComponent,
        SidePanelBodyComponent,
        ItemInfoBoxedLayoutComponent,
        ItemInfoScrollLayoutComponent,
        SidePanelCatalogComponent,
        CatalogGraphicPartsBoxedLayoutComponent,
        CatalogGraphicPartsScrollLayoutComponent,
        CatalogGraphicStripComponent,
        SidePanelSelectComponent,
        RimsOverviewBoxedLayoutComponent,
        RimsOverviewScrollLayoutComponent,
        WheelListComponent,
        WheelListItemComponent,
        SidePanelAndContentComponent,
        ContentListItemComponent,
        PartListItemInfoComponent,
        AdminCatalogLayoutCategoryComponent,
        AdminCatalogLayoutCategorySortPipe,
        AdminCatalogLayoutCategoryInfoComponent,
        FilteredTextComponent,
        AdminCatalogLayoutPartsDescriptionsSortPipe,
        AdminCatalogLayoutStatusFilterPipe,
        AdminCatalogLayoutChooseCatalogComponent,
        UploadComponent,
        DropDownFilterComponent,
        CatalogLineCheckboxComponent,
        AdminCatalogLinesFilterPipe,
        AdminSettingsLayoutComponent,
        TyresBoxedLayoutComponent,
        TyresScrollLayoutComponent,
        ThirdpartyShopBoxedLayoutComponent,
        ThirdpartyShopScrollLayoutComponent,
        AdminSettingsModulesComponent,
        AdminShopModuleSettingsComponent,
        ModuleFilterPipe,
        AdminTypeaheadCustomerSearchComponent,
        AdminSettingsSelectorComponent,
        HeaderStarOldComponent,
        HomeStarOldComponent,
        StarModuleComponent,
        StarPromotionComponent,
        StarLicenseplateComponent,
        LicensePlatesBoxedComponent,
        BeforeUnloadComponent,
        UnloadComponent,
        SuperAdminComponent,
        FooterXtrapartsComponent,
        FooterRoskampComponent,
        ScrollToTopButtonDefaultComponent,
        ScrollToTopButtonRoskampComponent,
        PaintComponent,
        AdminSettingsCatalogAldocComponent,
        AdminSettingsCatalogHbaseComponent,
        AdminSettingsCatalogTecdocComponent,
        AdminSettingsCatalogAlcarComponent,
        AdminSettingsCatalogIntertyreComponent,
        AdminSettingsCatalogMpmComponent,
        AdminSettingsCatalogSinatecComponent,
        AdminSettingsCatalogStahlieComponent,
        AdminTypeaheadSupplierSearchComponent,
        AdminSupplierInputComponent,
        AdminRadiobuttonComponent,
        AdminSettingsCatalogLinesReportComponent,
        CatalogLinesTotalsReportComponent,
        WebshopMenuStaticComponent,
        WebshopMenuDynamicComponent,
        WebshopMenuItemComponent,
        AdminLoyaltyShopSettingsComponent,
        AdminLoyaltyShopCategoriesComponent,
        AdminLoyaltyShopItemsComponent,
        AdminLoyaltyShopCategoryEditComponent,
        AdminLoyaltyShopItemEditComponent,
        AdminTypeaheadItemComponent,
        ListDisplayOptionsComponent,
        TyreListItemCompactComponent,
        AdminSettingsAvailabilityAndPriceComponent,
        AdminSubtitleComponent,
        AdminSettingsCatalogSetupComponent,
        MaintenancePartsComponent,
        MaintenanceComponent,
        AdminCatalogPartsTypesComponent,
        AdminPartsTypeBinderComponent,
        FilterPartsTypesPipe,
        FilterCrossedPartsTypesPipe,
        LoyaltyShopItemComponent,
        FilterStringPipe,
        MaintenancePartsBoxedLayoutComponent,
        MaintenancePartsScrollLayoutComponent,
        CatalogPartsSelectionPopupComponent,
        AdminSettingsExceptionsAvailabilityAndPriceComponent,
        AdminPartsTypeInputComponent,
        HomeExclupartsComponent,
        FooterExclupartsComponent,
        CarTypeInfoBlockComponent,
        CarTypeInfoPropertyComponent,
        ToastsComponent,
        ToastComponent,
        PartItemNumberLinkComponent,
        RouteInfoComponent,
        ShowMoreButtonComponent,
        AdminSettingsCustomerInformationComponent,
        AdminCheckboxListComponent,
        AdminSettingsSelectorPopupComponent,
        AdminSettingsSelectorInfoLinkComponent,
        ImageViewerComponent,
        NgVarDirective,
        AdminSaveButtonComponent,
        CatalogPartDocumentButtonsComponent,
        ContainerComponent,
        TyreLabelComponent,
        ThirdPartyFrameComponent,
        HomeRijpmaComponent,
        TyreCalculatorComponent,
        LoginRijpmaComponent,
        SelectOnclickDirective,
        AdminSettingsAvailabilitySuppliersComponent,
        SelectionPopupComponent,
        DebugSortOptionsComponent,
        DebugTypeScriptVarComponent,
        MpmListItemComponent,
        MpmHasCartItemPipe,
        SideSelectListComponent,
        SideSelectListItemComponent,
        MpmPartComponent,
        AdminLoyaltyShopAdminComponent,
        AdminTypeaheadOrderSearchComponent,
        AdminLoyaltyShopSummaryComponent,
        FilterCreditsInfosPipe,
        AdminTextareaComponent,
        AdminSettingsBrightComponent,
        AdminSettingsVrooamComponent,
        AdminSettingsAldocComponent,
        AdminItemGroupInputComponent,
        AdminTypeaheadItemGroupSearchComponent,
        AdminSupplierInterfaceInfoComponent,
        UppercaseInputDirective,
        LowercaseInputDirective,
        SupplierInterfaceResponseComponent,
        AdminItemGroupsInputComponent,
        AdminSettingsNavigationComponent,
        AdminWholesalerOverviewComponent,
        FilterByPropertyPipe,
        AdminBackendInfoComponent,
        FilterByWholesalerInfoPipe,
        PickupRequestComponent,
        AdminItemImagesComponent,
        AdminItemImagesEditComponent,
        AdminItemGroupImagesComponent,
        AdminItemGroupImagesEditComponent,
        AdminSupplierInterfacesComponent,
        AdminItemInfoComponent,
        ItemInfoResponseComponent,
        DefaultSupplierInterfaceSettingsComponent,
        DefaultInterfaceDataSettingsComponent,
        ItemInfoSupplierAvailabilityComponent,
        ItemInfoBranchAvailabilityComponent,
        FilterSupplierInterfacePipe,
        DefaultInterfaceDataEditComponent,
        SwitchComponent,
        ProxySupplierInterfaceSettingsComponent,
        ProxyInterfaceDataSettingsComponent,
        ProxyInterfaceDataEditComponent,
        EasycloudStockinfoSupplierInterfaceSettingsComponent,
        ArticleSourceResponseComponent,
        DefaultInterfaceDataSettingsSingleComponent,
        IntercarsSupplierInterfaceSettingsComponent,
        IntercarsInterfaceDataSettingsComponent,
        IntercarsInterfaceDataEditComponent,
        FavoritesControlComponent,
        PromotionSortOrderPipe,
        FavoritesComponent,
        PasswordForgottenPopupComponent,
        RequestAccountPopupComponent,
        ManageAccountComponent,
        ItemInfoDataComponent,
        AdminRouteInfoComponent,
        AdminCustomerInputComponent,
        AdminRouteInfoItemComponent,
        AdminOrderInfoComponent,
        ArchiveOrderComponent,
        EasytradeOrderComponent,
        EasytradeOrderItemComponent,
        ArchiveJsonPanelComponent,
        EasytradeOrderTextItemComponent,
        AdminOrderSearchComponent,
        AdminItemInputComponent,
        ArchiveOrderKindBadgeComponent,
        AdminSettingsAvailabilityTemplatesComponent,
        AdminAdminSettingComponent,
        AdminSettingsPaintComponent,
        AdminItemsInputComponent,
        ImageViewerModalComponent,
        ShowMoreLessButtonComponent,
        CartTextLinesComponent,
        WebshopSubMenuComponent,
        AdminSettingsExtraMessagesComponent,
        ExtraMessageMainSupplierComponent,
        ExtraMessageItemGroupComponent,
        AdminCheckboxEditorComponent,
        AdminSettingsMenuComponent,
        AdminShopModuleMenuComponent,
        AdminShopModuleMenuItemComponent,
        ItemInfoTemplatePartComponent,
        AvailabilityTemplatesOverviewComponent,
        AdminModalSettingsWrapperComponent,
        AdminSettingsCommonSettingsGenericComponent,
        AdminSettingsLicensePlatesComponent,
        AdminLicensePlateKindsComponent,
        HelperDisplayBreakpointComponent
    ],
    imports: [
        BaseChartDirective,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppBootstrapModule,
        AppRoutingModule,
        HttpClientModule,
        NgxJsonViewerModule,
        AngularEditorModule,
        DragDropModule,
        ClipboardModule
    ],
    providers: [
        LogService,
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: onInit, deps: [LogService], multi: true },
        { provide: APP_BASE_HREF, useFactory: (config: ConfigData) => config.basePath, deps: [ConfigData] },
        provideCharts(withDefaultRegisterables()),
        Title,
        DatePipe,
        ItemInfoFilterPipe,
        AuthGuard,
        AuthGuardAdmin,
        CarTypeGuard,
        // 2019-02-22 Maurice: probeer het even zonder CustomReuseStrategy
        // { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
        MainService,
        SortService,
        AuthService,
        ApiService,
        CartService,
        ShopService,
        CarTypeService,
        MessageService,
        CatalogService,
        PromotionService,
        RimAndTyreService,
        LoyaltyShopService,
        HomeScreenService,
        CustomerInformationService,
        ItemInfoService,
        ThirdpartyShopService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
