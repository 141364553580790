import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { CartComponent } from './cart/cart.component';
import { SettingsComponent } from './settings/settings.component';
import { PortalComponent } from './portal/portal.component';
import { AuthGuard } from './_guards/auth.guard';
import { AuthGuardAdmin } from './_guards/auth.guard.admin';
import { CarTypeGuard } from './_guards/car-type.guard';
import { ShopSoort } from './_models/common/shop.soort';
import { CarTypeSelectionComponent } from './car-type-selection/car-type-selection.component';
import { CatalogComponent } from './portal/catalog/catalog.component';
import { ItemInfoComponent } from './portal/item-info/item-info.component';
import { TyresComponent } from './portal/tyres/tyres.component';
import { ShopRouteConfig } from './_models/common/shop.route.config';
import { CatalogGroupsComponent } from './portal/catalog/catalog-groups/catalog-groups.component';
import { TestShopComponent } from './portal/test-shop/test-shop.component';
import { ThirdpartyShopComponent } from './portal/thirdparty-shop/thirdparty-shop.component';
import { RimsAndTyresComponent } from './portal/rims-and-tyres/rims-and-tyres.component';
import { PromotionsComponent } from './portal/promotions/promotions.component';
import { HomeScreenComponent } from './portal/home-screen/home-screen.component';
import { CatalogPartsComponent } from './portal/catalog/catalog-parts/catalog-parts.component';
import { PromotionsOverviewComponent } from './portal/promotions/promotions-overview/promotions-overview.component';
import { PromotionDetailComponent } from './portal/promotions/promotion-detail/promotion-detail.component';
import { CatalogGraphicPartsComponent } from './portal/catalog/catalog-graphic-parts/catalog-graphic-parts.component';
import { RimsOverviewComponent } from './portal/rims-and-tyres/rims-overview/rims-overview.component';
import { CustomerInformationComponent } from './portal/customer-information/customer-information.component';
// tslint:disable-next-line:max-line-length
import { CustomerInformationMenuComponent } from './portal/customer-information/customer-information-menu/customer-information-menu.component';
import { OrderHistoryComponent } from './portal/customer-information/order-history/order-history.component';
import { OutstandingItemsComponent } from './portal/customer-information/outstanding-items/outstanding-items.component';
import { DeliveryNotesComponent } from './portal/customer-information/delivery-notes/delivery-notes.component';
import { InvoicesComponent } from './portal/customer-information/invoices/invoices.component';
import { OpenOrdersComponent } from './portal/customer-information/open-orders/open-orders.component';
import { ProductReturnsComponent } from './portal/customer-information/product-returns/product-returns.component';
import { ProductWarrantyComponent } from './portal/customer-information/product-warranty/product-warranty.component';
import { TicketLoginComponent } from './login/ticket-login/ticket-login.component';
import { SettingsListComponent } from './settings/settings-list/settings-list.component';
import { SettingsGroupComponent } from './settings/settings-group/settings-group.component';
import { ExternalFrameComponent } from './portal/external-frame/external-frame.component';
import { AdminComponent } from './admin/admin.component';
import { AdminSettingsGeneralComponent } from './admin/admin-settings/admin-settings-general/admin-settings-general.component';
import { AdminStartComponent } from './admin/admin-start/admin-start.component';
// tslint:disable-next-line:max-line-length
import { AdminSettingsCatalogLayoutComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-layout/admin-settings-catalog-layout.component';
// tslint:disable-next-line:max-line-length
import { AdminSettingsCatalogGeneralComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-general/admin-settings-catalog-general.component';
// tslint:disable-next-line:max-line-length
import { AdminSettingsCatalogLinesComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-lines/admin-settings-catalog-lines.component';
import { AdminUsageOnlineComponent } from './admin/admin-usage/admin-usage-online/admin-usage-online.component';
import { AdminUsageTodayComponent } from './admin/admin-usage/admin-usage-today/admin-usage-today.component';
import { AdminUsageLastYearComponent } from './admin/admin-usage/admin-usage-last-year/admin-usage-last-year.component';
import { AdminSettingsCarTypeComponent } from './admin/admin-settings/admin-settings-car-type/admin-settings-car-type.component';
import { AdminSettingsTyresComponent } from './admin/admin-settings/admin-settings-tyres/admin-settings-tyres.component';
// tslint:disable-next-line:max-line-length
import { AdminSettingsRimsAndTyresComponent } from './admin/admin-settings/admin-settings-rims-and-tyres/admin-settings-rims-and-tyres.component';
import { AdminSettingsCartComponent } from './admin/admin-settings/admin-settings-cart/admin-settings-cart.component';
import { AdminSettingsDataResolverService } from './_resolvers/admin/admin-settings-data-resolver.service';
import { LicensePlatesComponent } from './portal/license-plates/license-plates.component';
import { AdminPromotionsComponent } from './admin/admin-promotions/admin-promotions.component';
import { AdminPromotionEditComponent } from './admin/admin-promotions/admin-promotion-edit/admin-promotion-edit.component';
import { TyresDataResolverService } from './_resolvers/rims-and-tyres/tyres-data-resolver.service';
import { ItemInfoDataResolverService } from './_resolvers/catalog/item-info-data-resolver.service';
// tslint:disable-next-line:max-line-length
import { OpenOrdersDetailWebshopComponent } from './portal/customer-information/open-orders-detail-webshop/open-orders-detail-webshop.component';
import { OrderHistoryDetailsComponent } from './portal/customer-information/order-history-details/order-history-details.component';
import { ProductReturnsDetailComponent } from './portal/customer-information/product-returns-detail/product-returns-detail.component';
import { ProductReturnsRequestComponent } from './portal/customer-information/product-returns-request/product-returns-request.component';
import { AdminSettingsPromotionsComponent } from './admin/admin-settings/admin-settings-promotions/admin-settings-promotions.component';
// tslint:disable-next-line: max-line-length
import { ProductReturnsRequestItemComponent } from './portal/customer-information/product-returns-request-item/product-returns-request-item.component';
import { AdminPromotionEditDataResolverService } from './_resolvers/admin/admin-promotion-edit-data-resolver.service';
import { AdminPromotionsResolverService } from './_resolvers/admin/admin-promotions-resolver.service';
import { CustomerInformationGuard } from './_guards/customer-information.guard';
import { GisModuleKind } from './_models/customer-information/gis.module.kind';
import { CatalogGroupsDataResolverService } from './_resolvers/catalog/catalog-groups-data-resolver.service';
import { CatalogGraphicPartsDataResolverService } from './_resolvers/catalog/catalog-graphic-parts-data-resolver.service';
import { CatalogPartsDataResolverService } from './_resolvers/catalog/catalog-parts-data-resolver.service';
import { RimsDataResolverService } from './_resolvers/rims-and-tyres/rims-data-resolver.service';
import { AdminSettingsModulesComponent } from './admin/admin-settings/admin-settings-modules/admin-settings-modules.component';
import { SuperAdminComponent } from './admin/super-admin/super-admin.component';
import { PaintComponent } from './portal/paint/paint.component';
import { AdminSettingsCatalogAldocComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-aldoc/admin-settings-catalog-aldoc.component';
import { AdminSettingsCatalogHbaseComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-hbase/admin-settings-catalog-hbase.component';
import { AdminSettingsCatalogTecdocComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-tecdoc/admin-settings-catalog-tecdoc.component';
import { AdminSettingsCatalogAlcarComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-alcar/admin-settings-catalog-alcar.component';
import { AdminSettingsCatalogIntertyreComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-intertyre/admin-settings-catalog-intertyre.component';
import { AdminSettingsCatalogMpmComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-mpm/admin-settings-catalog-mpm.component';
import { AdminSettingsCatalogSinatecComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-sinatec/admin-settings-catalog-sinatec.component';
import { AdminSettingsCatalogStahlieComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-stahlie/admin-settings-catalog-stahlie.component';
import { AdminSettingsLayoutComponent } from './admin/admin-settings/admin-settings-layout/admin-settings-layout.component';
import { AdminLoyaltyShopSettingsComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-settings/admin-loyalty-shop-settings.component';
import { AdminLoyaltyShopCategoriesComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-categories/admin-loyalty-shop-categories.component';
import { AdminLoyaltyShopCategoryEditComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-categories/admin-loyalty-shop-category-edit/admin-loyalty-shop-category-edit.component';
import { AdminLoyaltyShopItemsComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-items/admin-loyalty-shop-items.component';
import { AdminLoyaltyShopItemEditComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-items/admin-loyalty-shop-item-edit/admin-loyalty-shop-item-edit.component';
import { AdminLoyaltyShopCategoryEditDataResolverService } from './_resolvers/admin/admin-loyalty-shop-category-edit-data-resolver.service';
import { AdminLoyaltyShopItemEditDataResolverService } from './_resolvers/admin/admin-loyalty-shop-item-edit-data-resolver.service';
import { AdminLoyaltyShopDataResolverService } from './_resolvers/admin/admin-loyalty-shop-data-resolver.service';
import { AdminSettingsCatalogSetupComponent } from './admin/admin-settings/admin-settings-catalog/admin-settings-catalog-setup/admin-settings-catalog-setup.component';
import { MaintenancePartsComponent } from './portal/maintenance/maintenance-parts/maintenance-parts.component';
import { AdminCatalogPartsTypesComponent } from './admin/super-admin/admin-catalog-parts-types/admin-catalog-parts-types.component';
import { MaintenancePartsDataResolverService } from './_resolvers/maintenance/maintenance-parts-data-resolver.service';
import { LoyaltyShopComponent } from './portal/loyalty-shop/loyalty-shop.component';
import { LoyaltyShopDataResolverService } from './_resolvers/loyalty-shop/loyalty-shop-data-resolver.service';
import { ContextAdminDataResolverService } from './_resolvers/admin/context-admin-data-resolver.service';
import { PromotionsDataResolverService } from './_resolvers/promotions/promotions-data-resolver.service';
import { AdminSettingsExceptionsAvailabilityAndPriceComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-exceptions-availability-and-price/admin-settings-exceptions-availability-and-price.component';
import { AdminSettingsCustomerInformationComponent } from './admin/admin-settings/admin-settings-customer-information/admin-settings-customer-information.component';
import { ThirdPartyFrameComponent } from './portal/third-party-frame/third-party-frame.component';
import { ThirdPartyFrameContextResolverService } from './_resolvers/third-party-frame/third-party-frame-context-resolver.service';
import { TyreCalculatorComponent } from './portal/tyre-calculator/tyre-calculator.component';
import { TyreStorageComponent } from './portal/tyre-storage/tyre-storage.component';
import { WorkshopComponent } from './portal/workshop/workshop.component';
import { MpmComponent } from './portal/mpm/mpm.component';
import { ExternalFrameDataResolverService } from './_resolvers/external-frame/external-frame-data-resolver.service';
import { AdminSettingsAvailabilitySuppliersComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-availability-suppliers/admin-settings-availability-suppliers.component';
import { MpmDataResolverService } from './_resolvers/mpm/mpm-data-resolver.service';
import { AdminLoyaltyShopAdminComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-admin/admin-loyalty-shop-admin.component';
import { AdminLoyaltyShopSummaryComponent } from './admin/admin-loyalty-shop/admin-loyalty-shop-summary/admin-loyalty-shop-summary.component';
import { AdminSettingsBrightComponent } from './admin/admin-settings/admin-settings-bright/admin-settings-bright.component';
import { AdminSettingsNavigationComponent } from './admin/admin-settings/admin-settings-layout/admin-settings-navigation/admin-settings-navigation.component';
import { AdminWholesalerOverviewComponent } from './admin/super-admin/admin-wholesaler-overview/admin-wholesaler-overview.component';
import { PickupRequestComponent } from './portal/pickup-request/pickup-request.component';
import { AdminItemImagesComponent } from './admin/admin-management/admin-item-images/admin-item-images.component';
import { AdminItemImagesItemNumberResolverService } from './_resolvers/admin/admin-item-images-item-number-resolver.service';
import { AdminItemImagesEditComponent } from './admin/admin-management/admin-item-images/admin-item-images-edit/admin-item-images-edit.component';
import { AdminItemImagesItemNumberEditResolverService } from './_resolvers/admin/admin-item-images-item-number-edit-resolver.service';
import { AdminItemGroupImagesComponent } from './admin/admin-management/admin-item-group-images/admin-item-group-images.component';
import { AdminItemGroupImagesEditComponent } from './admin/admin-management/admin-item-group-images/admin-item-group-images-edit/admin-item-group-images-edit.component';
import { AdminSupplierInterfacesComponent } from './admin/super-admin/admin-supplier-interfaces/admin-supplier-interfaces.component';
import { AdminSupplierInterfaceResolverService } from './_resolvers/admin/admin-supplier-interface-resolver.service';
import { AdminItemImagesItemGroupResolverService } from './_resolvers/admin/admin-item-images-item-group-resolver.service';
import { AdminItemImagesItemGroupEditResolverService } from './_resolvers/admin/admin-item-images-item-group-edit-resolver.service';
import { AdminItemInfoComponent } from './admin/super-admin/admin-item-info/admin-item-info.component';
import { FavoritesComponent } from './portal/favorites/favorites.component';
import { FavoritesDataResolverService } from './_resolvers/favorites/favorites-data-resolver.service';
import { AdminRouteInfoComponent } from './admin/super-admin/admin-route-info/admin-route-info.component';
import { AdminOrderInfoComponent } from './admin/super-admin/admin-order-info/admin-order-info.component';
import { AdminOrderSearchComponent } from './admin/super-admin/admin-order-search/admin-order-search.component';
import { AdminOrderInfoResolverService } from './_resolvers/admin/admin-order-info-resolver.service';
import { AdminSettingsAvailabilityTemplatesComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-availability-templates/admin-settings-availability-templates.component';
import { AdminSettingsPaintComponent } from './admin/admin-settings/admin-settings-paint/admin-settings-paint.component';
import { ContextDataResolverService } from './_resolvers/context-data-resolver.service';
import { AdminSettingsExtraMessagesComponent } from './admin/admin-settings/admin-settings-availability-and-price/admin-settings-extra-messages/admin-settings-extra-messages.component';
import { AdminSettingsMenuComponent } from './admin/admin-settings/admin-settings-menu/admin-settings-menu.component';
import { AdminSettingsCommonSettingsGenericComponent } from './admin/admin-settings/admin-settings-common-settings-generic/admin-settings-common-settings-generic.component';
import { AdminSettingsLicensePlatesComponent } from './admin/admin-settings/admin-settings-license-plates/admin-settings-license-plates.component';
import { AdminLicensePlateKindsComponent } from './admin/super-admin/admin-license-plate-kinds/admin-license-plate-kinds.component';
import { AdminSettingsVrooamComponent } from './admin/admin-settings/admin-settings-vrooam/admin-settings-vrooam.component';
import { AdminSettingsAldocComponent } from './admin/admin-settings/admin-settings-aldoc/admin-settings-aldoc.component';
import { itemInfoWarningsResolver } from './_resolvers/item-info-warnings.resolver';


export const appRoutes: Routes = [
  {
    path:
      '',
    component: PortalComponent,
    children: [
      { path: 'cartype/:shop', component: CarTypeSelectionComponent, data: { title: 'Auto keuze' } },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Settings], component: SettingsComponent,
        children: [
          { path: '', component: SettingsListComponent },
          { path: 'group/:group', component: SettingsGroupComponent }
        ],
        data: { shop: ShopSoort.Settings, title: 'Instellingen' }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ShoppingCart], component: CartComponent,
        data: { shop: ShopSoort.ShoppingCart, title: 'Winkelwagen' }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ThirdPartyTestShop], component: TestShopComponent,
        data: { shop: ShopSoort.ThirdPartyTestShop }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.hbase], component: ThirdpartyShopComponent, canActivate: [CarTypeGuard],
        data: { shop: ShopSoort.hbase }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Toolbase], component: ThirdpartyShopComponent,
        data: { shop: ShopSoort.Toolbase }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.UniversalParts], component: ThirdpartyShopComponent,
        data: { shop: ShopSoort.UniversalParts }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Autotools], component: ThirdpartyShopComponent, canActivate: [CarTypeGuard],
        data: { shop: ShopSoort.Autotools }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.HaynesPro_Doyen],
        component: ThirdPartyFrameComponent,
        resolve: { ctx: ThirdPartyFrameContextResolverService },
        data: { shop: ShopSoort.HaynesPro_Doyen }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.AldocSys], component: ThirdpartyShopComponent,
        data: { shop: ShopSoort.AldocSys }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Banden],
        children: [
          { path: '', component: TyresComponent },
          {
            path: ':search', component: TyresComponent, resolve: { tyreResult: TyresDataResolverService },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
          }
        ],
        data: { shop: ShopSoort.Banden }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.WielenEnBanden], component: RimsAndTyresComponent, canActivate: [CarTypeGuard],
        children: [
          { path: '', component: RimsOverviewComponent },
          { path: ':carTypeId', component: RimsOverviewComponent, resolve: { rims: RimsDataResolverService } },
          { path: ':carTypeId/tyres', component: TyresComponent, resolve: { tyreResult: TyresDataResolverService } }
        ],
        data: { shop: ShopSoort.WielenEnBanden }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.AMT_Reparatiewijzer], component: ThirdpartyShopComponent, canActivate: [CarTypeGuard],
        data: { shop: ShopSoort.AMT_Reparatiewijzer }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Greenline], component: ThirdpartyShopComponent, canActivate: [CarTypeGuard],
        data: { shop: ShopSoort.Greenline }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Modificaties], component: ThirdpartyShopComponent, canActivate: [CarTypeGuard],
        data: { shop: ShopSoort.Modificaties }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Recalls], component: ThirdpartyShopComponent, canActivate: [CarTypeGuard],
        data: { shop: ShopSoort.Recalls }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.BandenCalculator], component: TyreCalculatorComponent,
        data: { shop: ShopSoort.BandenCalculator }, runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.BandenCalculator] + '/:size', component: TyreCalculatorComponent,
        data: { shop: ShopSoort.BandenCalculator }, runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.GarageInformatieSysteem], component: CustomerInformationComponent,
        children: [
          { path: '', redirectTo: 'menu', pathMatch: 'full' },
          { path: 'menu', component: CustomerInformationMenuComponent },
          {
            path: 'history', component: OrderHistoryComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.OrderHistory },
          },
          {
            path: 'history/:id', component: OrderHistoryDetailsComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.OrderHistory },
          },
          {
            path: 'open-orders', component: OpenOrdersComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.OpenOrders },
          },
          {
            path: 'open-orders/:id', component: OpenOrdersDetailWebshopComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.OpenOrders },
          },
          {
            path: 'delivery-notes', component: DeliveryNotesComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.DeliveryNotes },
          },
          {
            path: 'invoices', component: InvoicesComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.Invoices },
          },
          {
            path: 'outstanding-items', component: OutstandingItemsComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.OutstandingItems },
          },
          {
            path: 'returns', component: ProductReturnsComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.ProductReturns }
          },
          {
            path: 'returns/:id', component: ProductReturnsDetailComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.ProductReturns },
          },
          {
            path: 'returns-request', component: ProductReturnsRequestComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.ProductReturns },
          },
          {
            path: 'returns-request-item', component: ProductReturnsRequestItemComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.ProductReturns },
          },
          {
            path: 'warranty', component: ProductWarrantyComponent,
            canActivate: [CustomerInformationGuard], data: { moduleKind: GisModuleKind.ProductWarranty },
          }
        ],
        data: { shop: ShopSoort.GarageInformatieSysteem }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Aanbiedingen], component: PromotionsComponent,
        children: [
          { path: '', redirectTo: 'overview', pathMatch: 'full' },
          { path: 'overview', component: PromotionsOverviewComponent, resolve: { ctx: PromotionsDataResolverService } },
          { path: 'detail/:id', component: PromotionDetailComponent }
        ],
        data: { shop: ShopSoort.Aanbiedingen }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Thule],
        component: ThirdPartyFrameComponent,
        resolve: { ctx: ThirdPartyFrameContextResolverService },
        data: { shop: ShopSoort.Thule }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.BandenOpslag], component: TyreStorageComponent,
        data: { shop: ShopSoort.BandenOpslag }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.KentekenPlaten], component: LicensePlatesComponent,
        data: { shop: ShopSoort.KentekenPlaten }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.WerkplaatsTop10], component: WorkshopComponent,
        data: { shop: ShopSoort.WerkplaatsTop10 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.StorE],
        component: ThirdPartyFrameComponent,
        resolve: { ctx: ThirdPartyFrameContextResolverService },
        data: { shop: ShopSoort.StorE }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Artikelinformatie], component: ItemInfoComponent,
        data: { shop: ShopSoort.Artikelinformatie }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Artikelinformatie] + '/:item', component: ItemInfoComponent,
        resolve: { itemResult: ItemInfoDataResolverService }, runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        data: { shop: ShopSoort.Artikelinformatie }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.StartScherm], component: HomeScreenComponent,
        data: { shop: ShopSoort.StartScherm }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Hartsant], component: ThirdpartyShopComponent, canActivate: [CarTypeGuard],
        data: { shop: ShopSoort.Hartsant }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.MPM], component: MpmComponent, canActivate: [CarTypeGuard], resolve: { ctx: MpmDataResolverService },
        data: { shop: ShopSoort.MPM }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.NhsArtikelZoeken], component: ThirdpartyShopComponent,
        data: { shop: ShopSoort.NhsArtikelZoeken }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.DVSE],
        component: ThirdPartyFrameComponent,
        resolve: { ctx: ThirdPartyFrameContextResolverService },
        data: { shop: ShopSoort.DVSE }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Bonusshop], component: LoyaltyShopComponent,
        resolve: { ctx: LoyaltyShopDataResolverService },
        data: { shop: ShopSoort.Bonusshop }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Catalogus], component: CatalogComponent, canActivate: [CarTypeGuard],
        children: [
          { path: '', component: CatalogGroupsComponent },
          {
            path: 'groups/:carTypeId', component: CatalogGroupsComponent,
            resolve: { catalogGroups: CatalogGroupsDataResolverService }
          },
          {
            path: 'parts/:categoryId', component: CatalogPartsComponent,
            resolve: { parts: CatalogPartsDataResolverService }
          },
          {
            path: 'graphic-parts/:categoryId', component: CatalogGraphicPartsComponent,
            resolve: { graphicParts: CatalogGraphicPartsDataResolverService }
          }
        ],
        data: { shop: ShopSoort.Catalogus }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Sinatec],
        component: ThirdPartyFrameComponent,
        resolve: { ctx: ThirdPartyFrameContextResolverService },
        data: { shop: ShopSoort.Sinatec }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Paint], component: PaintComponent,
        resolve: { ctx: ContextDataResolverService }, data: { shop: ShopSoort.Paint }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Maintenance], component: MaintenancePartsComponent, canActivate: [CarTypeGuard],
        // children: [
        //   { path: '', component: MaintenanceComponent },
        //   {
        //     path: 'parts', component: MaintenancePartsComponent,
        //     resolve: { ctx: MaintenancePartsDataResolverService }
        //   }
        // ],
        resolve: { resolverData: MaintenancePartsDataResolverService },
        data: { shop: ShopSoort.Maintenance }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.PickupRequest], component: PickupRequestComponent,
        data: { shop: ShopSoort.PickupRequest }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.Favorites], component: FavoritesComponent,
        resolve: { ctx: FavoritesDataResolverService },
        data: { shop: ShopSoort.Favorites }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame1], component: ExternalFrameComponent,
        resolve: { ctx: ExternalFrameDataResolverService },
        data: { shop: ShopSoort.ExternalFrame1 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame2], component: ExternalFrameComponent,
        resolve: { ctx: ExternalFrameDataResolverService },
        data: { shop: ShopSoort.ExternalFrame2 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame3], component: ExternalFrameComponent,
        resolve: { ctx: ExternalFrameDataResolverService },
        data: { shop: ShopSoort.ExternalFrame3 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame4], component: ExternalFrameComponent,
        resolve: { ctx: ExternalFrameDataResolverService },
        data: { shop: ShopSoort.ExternalFrame4 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame5], component: ExternalFrameComponent,
        resolve: { ctx: ExternalFrameDataResolverService },
        data: { shop: ShopSoort.ExternalFrame5 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame6], component: ExternalFrameComponent,
        data: { shop: ShopSoort.ExternalFrame6 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame7], component: ExternalFrameComponent,
        resolve: { ctx: ExternalFrameDataResolverService },
        data: { shop: ShopSoort.ExternalFrame7 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame8], component: ExternalFrameComponent,
        resolve: { ctx: ExternalFrameDataResolverService },
        data: { shop: ShopSoort.ExternalFrame8 }
      },
      {
        path: ShopRouteConfig.routePaths[ShopSoort.ExternalFrame9], component: ExternalFrameComponent,
        resolve: { ctx: ExternalFrameDataResolverService },
        data: { shop: ShopSoort.ExternalFrame9 }
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'admin', component: AdminComponent,
    children: [
      { path: '', component: AdminStartComponent, data: { animation: 'HomePage' } },
      { path: 'superadmin', component: SuperAdminComponent, data: { animation: 'HomePage' } },
      {
        path: 'superadmin/catalogpartstypes', component: AdminCatalogPartsTypesComponent,
        resolve: { settings: AdminSettingsDataResolverService }, data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/license-plate-kinds', component: AdminLicensePlateKindsComponent,
        resolve: { settings: AdminSettingsDataResolverService }, data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/wholesaler-overview', component: AdminWholesalerOverviewComponent,
        resolve: { settings: AdminSettingsDataResolverService }, data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/admin-supplier-interfaces', component: AdminSupplierInterfacesComponent,
        resolve: { supplierInterfaceData: AdminSupplierInterfaceResolverService }, data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/admin-item-info', component: AdminItemInfoComponent, resolve: { warnings: itemInfoWarningsResolver },
        data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/admin-route-info', component: AdminRouteInfoComponent,
        data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/admin-order-search', component: AdminOrderSearchComponent,
        data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/admin-order-search/:customer/:internalitemnumberbhn', component: AdminOrderSearchComponent,
        data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/admin-order-info', component: AdminOrderInfoComponent,
        data: { animation: 'HomePage' }
      },
      {
        path: 'superadmin/admin-order-info/:id', component: AdminOrderInfoComponent,
        resolve: { orderInfo: AdminOrderInfoResolverService },
        data: { animation: 'HomePage' }
      },
      { path: 'usage/online', component: AdminUsageOnlineComponent, data: { animation: 'AboutPage' } },
      { path: 'usage/today', component: AdminUsageTodayComponent, data: { animation: 'FilterPage' } },
      { path: 'usage/last-year', component: AdminUsageLastYearComponent },
      {
        path: 'settings/general', component: AdminSettingsGeneralComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/layout', component: AdminSettingsLayoutComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/layout/navigation', component: AdminSettingsNavigationComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/modules', component: AdminSettingsModulesComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/menu', component: AdminSettingsMenuComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/availability-and-price', component: AdminSettingsAvailabilityTemplatesComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/availability-and-price/availability-suppliers', component: AdminSettingsAvailabilitySuppliersComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/availability-and-price/extra-messages', component: AdminSettingsExtraMessagesComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/availability-and-price/template-part/branch-time', component: AdminSettingsCommonSettingsGenericComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/availability-and-price/template-part/delivery-time', component: AdminSettingsCommonSettingsGenericComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/availability-and-price/template-part/item-counts', component: AdminSettingsCommonSettingsGenericComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/availability-and-price/template-part/volume-pricing', component: AdminSettingsCommonSettingsGenericComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/availability-and-price/exceptions', component: AdminSettingsExceptionsAvailabilityAndPriceComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/bright', component: AdminSettingsBrightComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/vrooam', component: AdminSettingsVrooamComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/aldoc', component: AdminSettingsAldocComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/car-type', component: AdminSettingsCarTypeComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog', component: AdminSettingsCatalogGeneralComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/aldoc', component: AdminSettingsCatalogAldocComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/hbase', component: AdminSettingsCatalogHbaseComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/tecdoc', component: AdminSettingsCatalogTecdocComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/alcar', component: AdminSettingsCatalogAlcarComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/intertyre', component: AdminSettingsCatalogIntertyreComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/mpm', component: AdminSettingsCatalogMpmComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/sinatec', component: AdminSettingsCatalogSinatecComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/stahlie', component: AdminSettingsCatalogStahlieComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/setup', component: AdminSettingsCatalogSetupComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/layout', component: AdminSettingsCatalogLayoutComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/catalog/lines', component: AdminSettingsCatalogLinesComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/tyres', component: AdminSettingsTyresComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/rims-and-tyres', component: AdminSettingsRimsAndTyresComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/licenseplates', component: AdminSettingsLicensePlatesComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/paint', component: AdminSettingsPaintComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/customer-information', component: AdminSettingsCustomerInformationComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'settings/cart', component: AdminSettingsCartComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'management/item-images', component: AdminItemImagesComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange', resolve: { itemImagesData: AdminItemImagesItemNumberResolverService }
      },
      {
        path: 'management/item-images/edit/:id', component: AdminItemImagesEditComponent,
        resolve: { item: AdminItemImagesItemNumberEditResolverService }
      },
      {
        path: 'management/item-images/new', component: AdminItemImagesEditComponent,
        resolve: { item: AdminItemImagesItemNumberEditResolverService }
      },
      {
        path: 'management/item-group-images', component: AdminItemGroupImagesComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange', resolve: { itemGroupImagesData: AdminItemImagesItemGroupResolverService }
      },
      {
        path: 'management/item-group-images/edit/:id', component: AdminItemGroupImagesEditComponent,
        resolve: { item: AdminItemImagesItemGroupEditResolverService }
      },
      {
        path: 'management/item-group-images/new', component: AdminItemGroupImagesEditComponent,
        resolve: { item: AdminItemImagesItemGroupEditResolverService }
      },
      {
        path: 'promotions/settings', component: AdminSettingsPromotionsComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'promotions', component: AdminPromotionsComponent,
        resolve: { promotions: AdminPromotionsResolverService }
      },
      {
        path: 'promotions/edit/:id', component: AdminPromotionEditComponent,
        resolve: { promotion: AdminPromotionEditDataResolverService }
      },
      {
        path: 'promotions/new', component: AdminPromotionEditComponent,
        resolve: { promotion: AdminPromotionEditDataResolverService }
      },
      {
        path: 'loyaltyshop/settings', component: AdminLoyaltyShopSettingsComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'loyaltyshop/admin', component: AdminLoyaltyShopAdminComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'loyaltyshop/summary', component: AdminLoyaltyShopSummaryComponent,
        resolve: { settings: AdminSettingsDataResolverService }
      },
      {
        path: 'loyaltyshop/categories', component: AdminLoyaltyShopCategoriesComponent,
        resolve: { loyaltyShopData: AdminLoyaltyShopDataResolverService }
      },
      {
        path: 'loyaltyshop/categories/edit/:id', component: AdminLoyaltyShopCategoryEditComponent,
        resolve: { category: AdminLoyaltyShopCategoryEditDataResolverService }
      },
      {
        path: 'loyaltyshop/categories/new', component: AdminLoyaltyShopCategoryEditComponent,
        resolve: { category: AdminLoyaltyShopCategoryEditDataResolverService }
      },
      {
        path: 'loyaltyshop/items', component: AdminLoyaltyShopItemsComponent,
        resolve: { loyaltyShopData: AdminLoyaltyShopDataResolverService }
      },
      {
        path: 'loyaltyshop/items/edit/:id', component: AdminLoyaltyShopItemEditComponent,
        resolve: { item: AdminLoyaltyShopItemEditDataResolverService }
      },
      {
        path: 'loyaltyshop/items/new', component: AdminLoyaltyShopItemEditComponent,
        resolve: { item: AdminLoyaltyShopItemEditDataResolverService }
      }
    ],
    resolve: { ctx: ContextAdminDataResolverService },
    data: { title: 'SPAD' }, canActivate: [AuthGuardAdmin]
  },
  { path: 'ticket', component: TicketLoginComponent, data: { title: 'Ticket login' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'logout', component: LoginComponent },
  { path: 'reload', component: LoginComponent },
  { path: 'switch-user', component: LoginComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
