<div *ngIf="ctx" class="main text-bg-light">
  <div class="sidebar text-bg-dark">
    <div class="text-center text-white brand-name pointer m-1">
      <span [routerLink]="['/admin']">ShopPortal<br />Admin Dashboard</span>
    </div>
    <div class="user-info noselect m-3">
      <b>{{ctx.CompanyName}}</b><br />
      {{ctx.Name}}<br />
      <span class="text-secondary">{{ctx.Function}}</span>
    </div>
    <ul *ngIf="ctx" class="nav d-block">
      <app-admin-menu-item *ngFor="let menuItem of ctx.Menu" [menuItem]="menuItem" [isMainMenu]="true"
        [active]="activeMenuId==menuItem.ID" [search]="searchRegex" (clickMenu)="clickMenu(menuItem)">
      </app-admin-menu-item>
    </ul>
  </div>

  <nav class="navbar navbar-dark text-bg-white py-0 border-bottom">
    <div class="container-fluid">
      <span *ngIf="!adminService.superAdmin" class="nav-item"></span>
      <span *ngIf="adminService.superAdmin" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
          {{adminService.currentWholesalerName}} <span class="text-low">{{adminService.currentUid.Wholesaler}}</span>
        </a>
        <ul class="dropdown-menu wholesaler-list" aria-labelledby="navbarDropdown">
          <li><a *ngFor="let wholesaler of ctx.Wholesalers" class="dropdown-item"
              (click)="adminService.setCurrentWholesaler(wholesaler)">{{wholesaler.ShortName}} <span
                class="text-low">{{wholesaler.ID}}</span></a></li>
        </ul>
      </span>
      <form class="d-flex">
        <input class="form-control me-3" name="search" [(ngModel)]="search" (ngModelChange)="searchChanged(search)" type="search" placeholder="zoek..." autocomplete="off" aria-label="Search">
        <button class="btn btn-link" (click)="authService.gotoLogout()" (contextmenu)="mainService.showDebugInfo($event, mainService.ctxMain.LoginTiming, ctx)"><i class="fas fa-sign-out pointer"></i></button>
      </form>
    
    </div>
  </nav>

  <!-- <main role="main" class="content" [@routerTransition]="prepareRoute(outlet)"> -->
  <main role="main" class="content">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
  <div class="footer text-end text-bg-white border-top">
    <span class="copyright pe-3">Copyright <b>Autotaal Automatisering B.V. © 2015-{{year}}</b></span>
  </div>
</div>