<li *ngIf="!search || searchHit(menuItem, search) || parentSearchHit || childSearchHit(menuItem, search)" class="nav-item" [ngClass]="{'nav-main-item py-1': isMainMenu, 'active': active, 'border-left border-primary': isMainMenu && active}">
  <a class="nav-link pointer" [ngClass]="{'nav-sub-link': !isMainMenu}" [routerLinkActiveOptions]="{exact: true}"
    [routerLinkActive]="menuItem.Route ? 'text-white' : ''" [routerLink]="getRouterLink()" (click)="clickMe($event)">
    <i [ngClass]="menuItem.Icon"></i>
    <span class="ms-2" [ngClass]="{'text-white font-italic': searchHit(menuItem, search)}" [innerHTML]="menuItem.Title | safeHtml"><span class="sr-only">(current)</span></span>
    <span class="float-end">
      <span *ngIf="menuItem.Badge" class="badge text-bg-primary">{{adminService.getBadge(menuItem.Badge)}}</span>
      <div *ngIf="menuItem.SubMenu" class="collapse-button" [ngClass]="{'is-collapsed': !active}"><i class="fas fa-chevron-up"></i></div>
    </span>
  </a>
  <ul *ngIf="menuItem.SubMenu && (active || searchHit(menuItem, search) || childSearchHit(menuItem, search))" [@slideInOut] class="nav d-block ms-2 collapsable">
    <app-admin-menu-item *ngFor="let sub of menuItem.SubMenu" [menuItem]="sub" [active]="activeSubMenuId==sub.ID"
      [search]="search" [parentSearchHit]="searchHit(menuItem, search) || childSearchHit(sub, search)" (clickMenu)="clickThisMenu(sub)"></app-admin-menu-item>
  </ul>
</li>
