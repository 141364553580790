<div class="alert alert-light">
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-4 fw-bold">Ordernummer</div>
        <div class="col-8">{{order.OrderNumber}}</div>
        <div class="col-4 fw-bold">Orderdatum</div>
        <div class="col-8">{{order.OrderDate | date:"dd-MM-yyyy HH:mm"}}</div>
        <div class="col-4 fw-bold">Referentie</div>
        <div class="col-8">{{order.CustomerReference}}</div>
        <div class="col-4 fw-bold">Herkomst</div>
        <div class="col-8"><app-archive-order-kind-badge [archiveOrderKind]="archiveOrderKind" [orderType]="order.OrderType"></app-archive-order-kind-badge></div>
      </div>
      <div *ngIf="order.DeliveryNoteNumber" class="row mt-2">
        <div class="col-4 fw-bold">Pakbon</div>
        <div class="col-8">{{order.DeliveryNoteNumber}}<a *ngIf="order.DeliveryNotePdfUrl" class="ms-2 text-danger"
            [href]="order.DeliveryNotePdfUrl"><i class="fas fa-file-pdf"></i></a></div>
        <div class="col-4 fw-bold">Pakbondatum</div>
        <div class="col-8">{{order.DeliveryNoteDate | date:"dd-MM-yyyy"}}</div>
      </div>
      <div *ngIf="order.InvoiceNumber" class="row mt-2">
        <div class="col-4 fw-bold">Factuur</div>
        <div class="col-8">{{order.InvoiceNumber}}<a *ngIf="order.InvoicePdfUrl" class="ms-2 text-danger"
            [href]="order.InvoicePdfUrl"><i class="fas fa-file-pdf"></i></a></div>
        <div class="col-4 fw-bold">Factuurdatum</div>
        <div class="col-8">{{order.InvoiceDate | date:"dd-MM-yyyy HH:mm"}}</div>
        <div class="col-4 fw-bold">Factuurbedrag</div>
        <div class="col-8">{{order.TotalInvoiceAmount | price}}</div>
        <div *ngIf="order.InvoicingInSeries" class="col-12 fw-bold">Gefactureerd in serie</div>
      </div>

    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-4 fw-bold">Klantsoort</div>
        <div class="col-8">{{order.CustomerTypeDescription}}<span
            class="ms-2 text-body-tertiary">({{order.CustomerType}})</span></div>
        <div class="col-4 mt-2 fw-bold">Order van</div>
        <div class="col-8 mt-2">
          {{order?.Name}} <span class="ms-2 text-body-tertiary">({{order.CustomerNumber}})</span><br />
          {{order.StreetName}} {{order.HouseNumber}}<br />
          {{order.PostalCode}}&nbsp;&nbsp;{{order.City}}
          <span *ngIf="order.Country">{{order.Country}}</span>
        </div>
        <div *ngIf="order.DeliveryMode===0" class="col-12 mt-2 fw-bold">Order wordt afgehaald</div>
        <div *ngIf="order.DeliveryMode===1" class="col-4 mt-2 fw-bold">Bezorgen op</div>
        <div *ngIf="order.DeliveryMode===1" class="col-8 mt-2">
          {{order.DeliveryDate | date:"dd-MM-yyyy 'om' HH:mm"}}<br />
          {{order.DeliveryStreetName}} {{order.DeliveryHouseNumber}}<br />
          {{order.DeliveryPostalCode}}&nbsp;&nbsp;{{order.DeliveryCity}}
          <span *ngIf="order.DeliveryCountry">{{order.DeliveryCountry}}</span>
        </div>



      </div>
    </div>


  </div>
  <div class="row space"></div>
  <table class="table table-striped order-table">
    <thead>
      <tr>
        <th class="cell"></th>
        <th class="cell"></th>
        <th class="cell">Artikelnummer</th>
        <th class="cell">Omschrijving</th>
        <th class="cell vertical">Aantal</th>
        <th class="cell vertical">Leveren</th>
        <th class="cell vertical">Backorder</th>
        <th class="cell vertical">Adviesprijs</th>
        <th class="cell vertical">Verpakking</th>
        <th class="cell vertical">Prijs</th>
        <th class="cell vertical">Korting</th>
        <th class="cell vertical">Netto</th>
        <th class="cell vertical">Prijs per</th>
        <th class="cell vertical">Totaal</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let orderLine of order.OrderLines">
        <tr *ngIf="orderLine.LineType===1" class="order-row" app-easytrade-order-item [orderLine]="orderLine"></tr>
        <tr *ngIf="orderLine.LineType!==1" class="order-row" app-easytrade-order-text-item [orderLine]="orderLine"></tr>
      </ng-container>
    </tbody>
  </table>
</div>