<div *ngIf="!modal; else modalButtons" class="form-group row">
  <div [ngClass]="'offset-sm-' + labelCols + ' col-sm-' + (12-labelCols)">
    <button type="button" class="btn btn-warning ms-3 me-2" (click)="cancel($event)"><i class="fas fa-undo"></i>
      Annuleren</button>
    <app-admin-save-button [saveAction]="saveAction" (beforeSaveAction)="beforeSaveAction.emit($event)"
      [disabled]="saveDisabled"></app-admin-save-button>
    <ng-content></ng-content>
  </div>
</div>

<ng-template #modalButtons>
  <button type="button" class="btn btn-warning ms-3 me-2" (click)="cancel($event)"><i class="fas fa-undo"></i>
    Annuleren</button>
  <app-admin-save-button [saveAction]="saveAction" (beforeSaveAction)="beforeSaveAction.emit($event)"
    [disabled]="saveDisabled"></app-admin-save-button>
  <ng-content></ng-content>
</ng-template>