import { Injectable } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { OrderInfoResponse } from '../../_models/orders/order.info.response';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { AdminService } from '../../_services/admin/admin.service';

@Injectable({
  providedIn: 'root'
})
export class AdminOrderInfoResolverService  {

  constructor(
    private apiService: ApiService,
    private adminService: AdminService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrderInfoResponse> | Observable<never> {
    const id = route.paramMap.get('id');
    return this.apiService.adminGetOrderInfo(this.adminService.currentUid, id).pipe(
      take(1),
      mergeMap(response => {
        if (response) {
          return of(response);
        } else { // no data?
          return EMPTY;
        }
      })
    );
  }

}
