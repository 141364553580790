import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainService } from '../../../../../_services/main.service';
import { ResponseItem } from '../../../../../_models/item-info/response.item';
import { ItemInfoResponse } from '../../../../../_models/item-info/item.info.response';
import { ResponseItemAvailabilityBranchInfo } from '../../../../../_models/item-info/response.item.availability.branch.info';
import { ResponseItemAvailabilitySupplierInfo } from '../../../../../_models/item-info/response.item.availability.supplier.info';
import { CartService } from '../../../../../_services/cart.service';
import { Animations } from '../../../../../_animations/animations';

@Component({
  selector: '[app-item-info-data]',
  templateUrl: './item-info-data.component.html',
  styleUrls: ['./item-info-data.component.scss'],
  animations: [Animations.slideInOut, Animations.slideAndFadeInOut]
})
export class ItemInfoDataComponent implements OnInit {
  @Input() item: ResponseItem;
  @Input() response: ItemInfoResponse;
  @Output() searchAction: EventEmitter<string> = new EventEmitter();
  alternativesOpen = false;

  constructor(
    public mainService: MainService,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
  }

  getWebGroup(item: ResponseItem): number {
    if (item.Properties['WebGroup']) { return item.Properties['WebGroup']; }
    return 0;
  }

  getFeeKind(feeKind: number): string {
    if (feeKind === 1) return 'Verwijderingsbijdrage artikel';
    if (feeKind === 2) return 'Statiegeld artikel';
    if (feeKind === 3) return 'Loodtoeslag artikel';
    if (feeKind === 4) return 'Milieutoeslag artikel';
    if (feeKind === 5) return 'Tegoed artikel';
    return 'Onbekend';
  }

  getImages(item: ResponseItem): string[] {
    if (item.Properties['Image'] && Array.isArray(item.Properties['Image'])) { return item.Properties['Image']; }
    if (item.Properties['Image'] && typeof item.Properties['Image'] === 'string') { return [item.Properties['Image'].toString()]; }
    return [];
  }

  getBranchInfos(item: ResponseItem): ResponseItemAvailabilityBranchInfo[] {
    const infos = [];
    if (item?.Availability?.BranchInfo) {
      Object.keys(item.Availability.BranchInfo).forEach(branchNumber => {
        if (item.Availability.BranchInfo[branchNumber].StockPhysical != 0) { infos.push(item.Availability.BranchInfo[branchNumber]); }
      });
    }
    return infos;
  }

  getSupplierInfos(item: ResponseItem): ResponseItemAvailabilitySupplierInfo[] {
    const infos = [];
    if (item?.Availability?.SupplierInfo) {
      if (item.Availability.SupplierInfo[item.MainSupplier]) { infos.push(item.Availability.SupplierInfo[item.MainSupplier]); }
      Object.keys(item.Availability.SupplierInfo).forEach(supplierNumber => {
        if (+supplierNumber != item.MainSupplier) { infos.push(item.Availability.SupplierInfo[supplierNumber]); }
      });
    }
    return infos;
  }


  isDateOk(date: Date | null): boolean {
    if (date && date.getFullYear() < 2999) return true;
    return false;
  }

  showAvailabilityLog(itemInfo: ResponseItem) {
    const rits = this.cartService.getResponseItemTemplates(null, null, itemInfo);
    if (rits) {
      const log = rits.Log;
      this.mainService.showAvailabilityLogDialog(log);
    }
  }

  getSupplierNetPrice(item: ResponseItem, supplierInfo: ResponseItemAvailabilitySupplierInfo) {
    if (item?.Price?.NetPricePerSupplier && item.Price.NetPricePerSupplier[supplierInfo.SupplierNumber]) return item.Price.NetPricePerSupplier[supplierInfo.SupplierNumber]
    return null;
  }

}
