import { Component, Input, OnInit } from '@angular/core';
import { SupplierModel } from '../../../../../_models/common/supplier.model';
import { AvailabilityCode } from '../../../../../_models/item-info/availability.code';
import { ResponseItemAvailabilitySupplierInfo } from '../../../../../_models/item-info/response.item.availability.supplier.info';
import { ResponseItemPriceNet } from '../../../../../_models/item-info/response.item.price.net';

@Component({
  selector: 'app-item-info-supplier-availability',
  templateUrl: './item-info-supplier-availability.component.html',
  styleUrls: ['./item-info-supplier-availability.component.scss']
})
export class ItemInfoSupplierAvailabilityComponent implements OnInit {
  availabilityCode = AvailabilityCode;
  @Input() supplierInfo: ResponseItemAvailabilitySupplierInfo;
  @Input() suppliers: { [key: number]: SupplierModel; };
  @Input() isMainSupplier = false;
  @Input() netPrice: ResponseItemPriceNet;

  constructor() { }

  ngOnInit(): void {
  }

  getSupplier(supplierId: number): SupplierModel {
    if (this.suppliers[supplierId]) { return this.suppliers[supplierId]; }
    return null;
  }

  isDateOk(date: Date | null): boolean {
    if (date && date.getFullYear() < 2999) return true;
    return false;
  }

}
