<ng-container [ngSwitch]="archiveOrderKind">
  <span *ngSwitchCase="0" class="badge text-bg-primary">ShopPortal</span>
  <span *ngSwitchCase="1" class="badge text-bg-info">Brightmotive</span>
  <span *ngSwitchCase="2" class="badge text-bg-success">Vrooamportaal</span>
  <span *ngSwitchCase="3" class="badge text-bg-secondary">Wincar</span>
  <span *ngSwitchCase="4" class="badge text-bg-primary">ShopPortal ThirdParty API</span>
  <span *ngSwitchCase="5" class="badge text-bg-dark">EasyCloud Shop API</span>
  <span *ngSwitchCase="6" class="badge text-bg-warning">Mijngrossier</span>
  <ng-container *ngSwitchDefault>
    <ng-container [ngSwitch]="orderType">
      <span *ngSwitchCase="0" class="badge text-bg-light">Balie/Telefoon</span>
      <span *ngSwitchCase="1" class="badge text-bg-dark">Interne Order</span>
      <span *ngSwitchCase="2" class="badge text-bg-warning">Mijngrossier</span>
      <span *ngSwitchCase="3" class="badge text-bg-warning">YellowBox</span>
      <span *ngSwitchCase="4" class="badge text-bg-warning">Partsnet</span>
      <span *ngSwitchCase="5" class="badge text-bg-Info">Rechtstreeks</span>
      <span *ngSwitchCase="6" class="badge text-bg-warning">Partsnetgo</span>
      <span *ngSwitchCase="7" class="badge text-bg-primary">WebShop</span>
      <span *ngSwitchCase="8" class="badge text-bg-Info">Offerte</span>
      <span *ngSwitchCase="9" class="badge text-bg-primary">Internet</span>
      <span *ngSwitchCase="10" class="badge text-bg-primary">Consignatie</span>
      <span *ngSwitchCase="11" class="badge text-bg-danger">Retour</span>
      <span *ngSwitchCase="12" class="badge text-bg-success">Garantie</span>
      <span *ngSwitchDefault class="badge text-bg-light">???</span>
    </ng-container>
  </ng-container>
</ng-container>
